import { BaseProvider, Web3Provider } from '@ethersproject/providers';
import { isAddress } from '@ethersproject/address';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { NetworksService } from './networks.service';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  Networks,
  NetworksByName,
  TokensHolder,
  ERC20,
  MULTICALL,
  GasPriceHolder,
} from '@crowdswap/constant';
import { ETH } from '@crowdswap/sdk';
import { ERC20Service } from './erc20.service';
import { MultiCall } from '@indexed-finance/multicall';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { WalletNetworkChangRejectedException } from '../exception/wallet-network-chang-rejected.exception';
import { BigNumber, ethers } from 'ethers';
import { CurrentNetwork } from '../model';
import { match } from 'ts-pattern';
import {
  Web3Modal,
  createWeb3Modal,
  defaultWagmiConfig,
} from '@web3modal/wagmi';
import {
  arbitrum,
  mainnet,
  polygon,
  avalanche,
  bsc,
  zkSync,
  optimism,
  lineaTestnet,
  base,
  defichainEvm,
} from 'viem/chains';
import {
  getChainId,
  getAccount,
  getBalance,
  Config,
  watchAccount,
  watchChainId,
  switchChain,
  sendTransaction as wagmiSendTransaction,
  signMessage as wagmiSignMessage,
  waitForTransactionReceipt,
  getFeeHistory,
  disconnect,
  getPublicClient,
  CreateConfigParameters,
  prepareTransactionRequest,
  reconnect,
} from '@wagmi/core';
import { NumberType, formatNumber } from '@uniswap/conedison/format.js';
import { parseEther } from 'viem';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable()
export class Web3Service implements OnDestroy {
  private web3Modal: Web3Modal | undefined;
  private provider?: any;
  private address?: string;
  private _walletNetworkChangeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  private _currentNetworkChangeSubject: BehaviorSubject<CurrentNetwork> =
    new BehaviorSubject<CurrentNetwork>(new CurrentNetwork(-2));
  private _wrongNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _mismatchNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _walletConnectionChangeSubject = new Subject<boolean>();
  private _pendingChangeSubject = new Subject();
  private _accountChangeSubject = new Subject<string>();
  private _assetChangeSubject: Subject<boolean> = new Subject<boolean>();
  private providerRequest: any;
  private _isWalletConnected?: boolean;
  private walletType: string | undefined = '';
  private config: Config | undefined;
  private _networkSpec = {
    [Networks.MAINNET]: {
      title: 'Ethereum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Ethereum-icon.png',
      scanUrl: 'etherscan.io',
      scanName: 'Etherscan',
    },
    [Networks.ROPSTEN]: {
      title: 'Ethereum (Ropsten)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Ethereum-icon0.png',
      scanUrl: 'ropsten.etherscan.io',
      scanName: 'Ropstenscan',
    },
    [Networks.RINKEBY]: {
      title: 'Ethereum (Rinkeby)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Ethereum-icon0.png',
      scanUrl: 'rinkeby.etherscan.io',
      scanName: 'Rinkebyscan',
    },
    [Networks.GOERLI]: {
      title: 'Ethereum (Goerli)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Ethereum-icon0.png',
      scanUrl: 'goerli.etherscan.io',
      scanName: 'Goerliscan',
    },
    [Networks.KOVAN]: {
      title: 'Ethereum (Kovan)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Ethereum-icon0.png',
      scanUrl: 'kovan.etherscan.io',
      scanName: 'Kovanscan',
    },
    [Networks.BSCMAIN]: {
      title: 'BSC',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/networks/Binance-icon.png',
      scanUrl: 'bscscan.com',
      scanName: 'Bscscan',
    },
    [Networks.BSCTEST]: {
      title: 'BSC (Testnet)',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/networks/Binance-icon0.png',
      scanUrl: 'testnet.bscscan.com',
      scanName: 'Bsctestscan',
    },
    [Networks.POLYGON_MAINNET]: {
      title: 'Polygon',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/networks/Polygon-icon.png',
      scanUrl: 'polygonscan.com',
      scanName: 'Polygonscan',
    },
    [Networks.POLYGON_MUMBAI]: {
      title: 'Matic Mumbai',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/networks/Polygon-icon0.png',
      scanUrl: 'mumbai.polygonscan.com',
      scanName: 'Mumbaiscan',
    },
    [Networks.AVALANCHE]: {
      title: 'Avalanche',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/networks/Avalanche-icon.svg',
      scanUrl: 'snowtrace.io',
      scanName: 'Snowtrace',
    },
    [Networks.AVALANCHE_FUJI]: {
      title: 'Fuji',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/networks/Avalanche-icon0.png',
      scanUrl: 'testnet.snowtrace.io',
      scanName: 'Snowtrace',
    },
    [Networks.APEX]: {
      title: 'APEX',
      coin: 'OMNIA',
      coinIcon: 'assets/media/icons/networks/Apex-icon.png',
      scanUrl: 'scan.theapexchain.org',
      scanName: 'theapexchain',
    },
    [Networks.ARBITRUM]: {
      title: 'Arbitrum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/Arbitrum-icon.svg',
      scanUrl: 'arbiscan.io',
      scanName: 'Arbiscan',
    },
    [Networks.ZKSYNC]: {
      title: 'zkSync',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/zksync.svg',
      scanUrl: 'explorer.zksync.io',
      scanName: 'zkScan',
    },
    [Networks.OPTIMISM]: {
      title: 'Optimism',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/optimism.png',
      scanUrl: 'optimistic.etherscan.io',
      scanName: 'optiscan',
    },
    [Networks.LINEA]: {
      title: 'Linea',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/linea.png',
      scanUrl: 'lineascan.build',
      scanName: 'lineascan',
    },
    [Networks.BASE]: {
      title: 'Base',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/networks/base.png',
      scanUrl: 'basescan.org',
      scanName: 'basescan',
    },
  };
  private unwatchAccount: (() => void) | undefined;
  private unwatchNetwork: (() => void) | undefined;

  constructor(private zone: NgZone, private networksService: NetworksService) {
    this.initModal().then(() => {});
  }

  async initModal(): Promise<void> {
    try {
      const chains = [
        mainnet,
        bsc,
        polygon,
        avalanche,
        arbitrum,
        zkSync,
        optimism,
        lineaTestnet,
        base,
        defichainEvm,
      ] as const;
      const projectId = environment.walletConnectProjectId;
      const metadata = {
        name: 'CrowdSwap.org',
        description:
          'Decentralized crypto exchange of future. With advanced features and seamless trading',
        url: 'https://app.crowdswap.org', // origin must match your domain & subdomain
        icons: ['https://app.crowdswap.org/assets/media/logo/title-logo.svg'],
      };

      const wagmiOptions: Partial<CreateConfigParameters> = {};

      this.config = defaultWagmiConfig({
        chains,
        projectId,
        metadata,
        enableCoinbase: true,
        enableWalletConnect: true,
        ...wagmiOptions, // Optional - Override createConfig parameters
      });
      await reconnect(this.config);

      this.web3Modal = createWeb3Modal({
        wagmiConfig: this.config,
        projectId,
        enableAnalytics: true, // Optional - to have Analytics on WalletConnect Cloud's dashboard
        enableOnramp: true, // Optional - enable coinbase fiat on ramp
      });

      const account = getAccount(this.config);
      if (account && account.address) {
        this.initProvider();
      }

      this.web3Modal.subscribeEvents(async (event) => {
        console.log('web3Modal.subscribeEvents: ' + JSON.stringify(event));
        if (event.data?.event === 'SELECT_WALLET') {
          this.walletType =
            event.data.properties.name + '-' + event.data.properties.platform;
        }
        if (event.data.event == 'MODAL_CLOSE') {
          if (event.data.properties.connected) {
            this.initProvider();
          }
        }
        if (event.data.event === 'CONNECT_SUCCESS') {
          this.initProvider();
        } else if (event.data.event === 'DISCONNECT_SUCCESS') {
          await this.zone.run(async () => {
            this.setWalletConnected(false);
            await this.clearCachedProvider();
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  async openModal(): Promise<void> {
    try {
      await this.web3Modal?.open();
    } catch (e) {
      console.error(e);
    }
  }

  async clearCachedProvider() {
    this.setWalletConnected(false);
    this.provider = undefined;
    this.unwatchAccount && this.unwatchAccount();
    this.unwatchNetwork && this.unwatchNetwork();
    await disconnect(this.config!);
  }

  isConnected() {
    return this._isWalletConnected;
  }

  getWalletChainId(): number {
    return this._walletNetworkChangeSubject.getValue();
  }

  getCurrentChainId(): number {
    return this._currentNetworkChangeSubject.getValue().chainId;
  }

  getCurrentMainChainId(): number {
    const chainId: number = this.getCurrentChainId();
    return this.getCurrentMainChain(chainId.toString());
  }

  getCurrentMainChain(chainId: string): number {
    if (chainId == '-2') {
      chainId = NetworksByName.POLYGON_MAINNET.toString();
    }

    if (chainId == '56' || chainId == '97') {
      return NetworksByName.BSCMAIN;
    } else if (chainId == '137' || chainId == '80001') {
      return NetworksByName.POLYGON_MAINNET;
    } else if (chainId == '43114' || chainId == '43113') {
      return NetworksByName.AVALANCHE;
    } else if (chainId == '42161') {
      return NetworksByName.ARBITRUM;
    } else if (chainId == '324') {
      return NetworksByName.ZKSYNC;
    } else if (chainId == '10') {
      return NetworksByName.OPTIMISM;
    } else if (chainId == '59144') {
      return NetworksByName.LINEA;
    } else if (chainId == '8453') {
      return NetworksByName.BASE;
    } else if (chainId == '1130') {
      return NetworksByName.DEFI;
    } else {
      return NetworksByName.MAINNET;
    }
  }

  getWalletAddress(): string | undefined {
    return this.address;
  }

  getProviderName() {
    return this.config?.connectors?.[0]?.name;
  }

  async getBalance(
    token?: CrowdToken,
    provider: BaseProvider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!this.address || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(this.address);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        this.address,
        token.address
      );
    }
  }

  async getBalanceByUserAddress(
    token: CrowdToken,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!userAddress || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(userAddress);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        userAddress,
        token.address
      );
    }
  }

  async getAllBalances(tokens: any): Promise<any> {
    let inUseProvider: Web3Provider | undefined = undefined;
    if (!this.address || !this.isConnected()) {
      return undefined;
    }
    if (!tokens || tokens.length === 0) {
      return undefined;
    }
    const chainId: number = (<CrowdToken>tokens[0]).chainId;
    inUseProvider = this.networksService
      .getNetworkProvider(chainId)
      .getProvider();

    if (!inUseProvider) {
      return undefined;
    }

    try {
      const walletAddress = this.getWalletAddress();
      if (!walletAddress) {
        return;
      }
      const chainId = tokens[0].chainId;
      const ethAddress = ETH[chainId].address.toLowerCase();
      let ethBalance;
      let tokenBalances = {};

      if (NetworksByName.ZKSYNC === chainId) {
        const noneEthTokens: any[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
          } else {
            noneEthTokens.push(token);
          }
        }

        const populatedTxList: [string, string | undefined][] = [];
        const networkService = this.networksService.getNetworkProvider(chainId);
        for (const token of noneEthTokens) {
          const erc20Contract = networkService.getContract(
            token.address,
            ERC20
          );
          const populatedTx = await erc20Contract.populateTransaction.balanceOf(
            walletAddress
          );
          populatedTxList.push([token.address, populatedTx.data]);
        }
        const multiCallContract = networkService.getContract(
          '0x47898B2C52C957663aE9AB46922dCec150a2272c',
          MULTICALL
        );
        const result = await multiCallContract.callStatic.aggregate(
          populatedTxList
        );
        const returnData = result.returnData;
        for (let i = 0; i < noneEthTokens.length; i++) {
          tokenBalances[noneEthTokens[i].address.toLowerCase()] = returnData[i];
        }
      } else {
        const addressList: string[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
            continue;
          }
          addressList.push(token.address.toLowerCase());
        }
        const multi = new MultiCall(inUseProvider);
        const [blockNumber, balances] = await multi.getBalances(
          addressList,
          this.getWalletAddress()!
        );
        tokenBalances = balances;
      }
      return { ...tokenBalances, [ethAddress]: ethBalance };
    } catch (e) {
      console.log(e);
    }
  }

  async getAllowance(
    dex: string,
    delegatedDex: string,
    tokenAddress: string,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }

    let spenderAddress = await this.fetchSpenderAddress(
      dex,
      delegatedDex,
      inUseProvider.network.chainId
    );

    return this.getAllowanceBySpender(
      spenderAddress,
      tokenAddress,
      userAddress,
      provider
    );
  }

  async getAllowanceBySpender(
    spenderAddress: string,
    tokenAddress: string,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    return new ERC20Service(inUseProvider).getAllowance(
      tokenAddress,
      spenderAddress,
      userAddress
    );
  }

  async getApprovalTransactionBySpender(
    spenderAddress: string,
    tokenAddress: string,
    amount: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const inUseProvider = provider || this.web3Provider;
    if (!this.address || !inUseProvider) {
      return undefined;
    }
    return new ERC20Service(inUseProvider).getApproveTransaction(
      tokenAddress,
      spenderAddress,
      amount
    );
  }

  private async fetchSpenderAddress(
    dex: string,
    delegatedDex: string,
    chainId: number
  ): Promise<string> {
    let targetDex;
    if (Dexchanges[dex].isCrowdSwapContractEnabled) {
      targetDex = Dexchanges.CrowdswapAggregatorV3.name;
    } else {
      if (dex === Dexchanges.CrowdswapAggregatorV3.name) {
        targetDex = delegatedDex;
      } else {
        targetDex = dex;
      }
    }
    const spenderAddress =
      Dexchanges[targetDex].networks[chainId]?.contractAddress ??
      Dexchanges[targetDex].networks[chainId][0]?.contractAddress;
    if (!spenderAddress) {
      return Promise.reject(
        `Could not find address for spender ${targetDex} on chainId ${chainId}`
      );
    }
    return spenderAddress;
  }

  async getGasPrice(
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const usedProvider = provider || this.web3Provider;
    const originalGasPrice =
      GasPriceHolder.Instance.getGasPrice(usedProvider.network.chainId) ??
      (await usedProvider.getGasPrice());
    let additionalPercentage = 0;
    switch (usedProvider.network.chainId) {
      case Networks.MAINNET:
        additionalPercentage = 18;
        break;
      case Networks.BSCMAIN:
        additionalPercentage = 0;
        break;
      case Networks.POLYGON_MAINNET:
        additionalPercentage = 40;
        break;
      case Networks.ARBITRUM:
        additionalPercentage = 0;
        break;
      case Networks.AVALANCHE:
        additionalPercentage = 5;
        break;
      case Networks.ZKSYNC:
        additionalPercentage = 10;
        break;
      case Networks.OPTIMISM:
        additionalPercentage = 10;
        break;
      case Networks.LINEA:
        additionalPercentage = 10;
        break;
      case Networks.BASE:
        additionalPercentage = 10;
        break;
      case Networks.DEFI:
        additionalPercentage = 10;
        break;
    }
    return originalGasPrice.mul(100 + additionalPercentage).div(100);
  }

  getNetworkName(chainId: number): string {
    return (<any>Networks)[chainId];
  }

  getScanTransactionUrl(hash: string, chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();
    return `https://${this.networkSpec[chainId].scanUrl}/tx/${hash}`;
  }

  async sendTransaction(data: any) {
    return this.provider?.getSigner().sendTransaction(data);
  }

  async signMessage(message: string) {
    return this.provider?.getSigner().signMessage(message);
  }

  async waitForTransaction(
    hash: string,
    confirmation: number,
    provider: Web3Provider | undefined = undefined
  ) {
    return (provider || this.provider)?.waitForTransaction(hash, confirmation);
  }

  get walletNetworkChangeSubject() {
    return this._walletNetworkChangeSubject;
  }

  get wrongNetworkSubject(): Subject<boolean> {
    return this._wrongNetworkSubject;
  }

  get mismatchNetworkSubject(): Subject<boolean> {
    return this._mismatchNetworkSubject;
  }

  get currentNetworkChangeSubject(): BehaviorSubject<CurrentNetwork> {
    return this._currentNetworkChangeSubject;
  }

  get walletConnectionChangeSubject() {
    return this._walletConnectionChangeSubject;
  }

  get pendingChangeSubject() {
    return this._pendingChangeSubject;
  }

  get accountChangeSubject() {
    return this._accountChangeSubject;
  }

  get assetChangeSubject(): Subject<boolean> {
    return this._assetChangeSubject;
  }

  get networkSpec() {
    return this._networkSpec;
  }

  get web3Provider() {
    return this.getNetworkProvider(this.getWalletChainId());
  }

  async changeNetwork(chainId: number) {
    try {
      await switchChain(this.config!, { chainId });

      if (!this.providerRequest) {
        return;
      }
      await this.providerRequest({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3Service.toHex(chainId) }],
      });
    } catch (switchError: any) {
      const errorCode =
        switchError.code ||
        this.transformProviderErrorMessageToErrorCode(switchError.toString());
      switch (errorCode) {
        // This error code indicates that the chain has not been added to MetaMask.
        case 4902: {
          const network = await this.networksService.getNetworkRPCUrlById(
            chainId
          );
          try {
            await this.providerRequest({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3Service.toHex(network.chainId).toString(),
                  chainName: network.chainName,
                  rpcUrls: [network.rpcUrls],
                  blockExplorerUrls: [network.blockExplorerUrls],
                  iconUrls: [network.iconUrls],
                  nativeCurrency: {
                    name: network.nativeCurrencyName,
                    symbol: network.nativeCurrencySymbol,
                    decimals: parseInt(network.nativeCurrencyDecimals),
                  },
                },
              ],
            });
          } catch (switchError: any) {
            const errorCode =
              switchError.code ||
              this.transformProviderErrorMessageToErrorCode(
                switchError.toString()
              );
            switch (errorCode) {
              case 4001:
                // This error code indicates that the user rejected the network adding request
                throw new WalletNetworkChangRejectedException(
                  'User rejects the network changing request.'
                );
            }
          }
          break;
        }
        case 4001:
          // This error code indicates that the user rejected the network changing request
          throw new WalletNetworkChangRejectedException(
            'User rejects the network changing request.'
          );
      }
    }
  }

  async addTokenToWallet(destTokenOnNetwork: any) {
    try {
      await window.ethereum
        .request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: destTokenOnNetwork.address,
              symbol: destTokenOnNetwork.symbol,
              decimals: destTokenOnNetwork.decimals,
            },
          },
        })
        .then((success) => {
          if (!success) {
            throw new Error('Something went wrong.');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  private static toHex(value: number) {
    const HexCharacters = '0123456789abcdef';
    let hex = '';
    while (value) {
      hex = HexCharacters[value & 0xf] + hex;
      value = Math.floor(value / 16);
    }
    if (hex.length) {
      return '0x' + hex;
    }
    return '0x00';
  }

  ngOnDestroy(): void {
    this.clearCachedProvider().catch(console.log);
  }

  setWalletConnected(status: boolean) {
    this._isWalletConnected = status;
    if (!status) {
      this.address = undefined;
    }
    this._walletConnectionChangeSubject.next(status);
  }

  async addBalanceToTokens(tokens: CrowdToken[]): Promise<CrowdToken[]> {
    const balances = await this.getAllBalances(tokens);
    if (!balances) {
      return tokens.map((token) => {
        token.balance = '';
        token.balanceToDisplay = '';
        return token;
      });
    }

    const result = tokens
      .map((token) => {
        if (!balances[token.address.toLowerCase()]) {
          return token;
        }
        token.balance = Conversion.convertStringFromDecimal(
          balances[token.address.toLowerCase()].toString(),
          token.decimals
        );

        token.balanceToDisplay = formatNumber(
          parseFloat(
            Conversion.convertStringFromDecimal(
              balances[token.address.toLowerCase()].toString(),
              token.decimals
            )
          ),
          NumberType.TokenNonTx
        );

        return token;
      })
      .sort(
        (item1: CrowdToken, item2: CrowdToken) =>
          +item2.balance - +item1.balance
      );
    return result;
  }

  isAddress(address: string) {
    return isAddress(address);
  }

  getNetworkProvider(chainId: number) {
    return this.networksService.getNetworkProvider(chainId).getProvider();
  }

  private transformProviderErrorMessageToErrorCode(errorMessage: string) {
    return match(errorMessage)
      .when(
        (value: string) => {
          return value.includes('wallet_addEthereumChain');
        },
        () => 4902
      )
      .when(
        (value: string) => {
          return value.includes('User rejected the request');
        },
        () => 4001
      )
      .run();
  }

  private initProvider() {
    const chainId = getChainId(this.config!);
    if (!chainId) {
      return;
    }

    const account = getAccount(this.config!);
    this.address = account.address;
    this.setWalletConnected(true);
    this._walletNetworkChangeSubject.next(chainId);
    this._accountChangeSubject.next(this.address!);

    this.unwatchAccount = watchAccount(this.config!, {
      onChange: async (account, preAccount) => {
        await this.zone.run(async () => {
          this.address = account.address;
          this._accountChangeSubject.next(this.address!);
        });
      },
    });

    this.unwatchNetwork = watchChainId(this.config!, {
      onChange: async (chainId, preChainId) => {
        await this.zone.run(async () => {
          if (!chainId) {
            return;
          }
          this.provider && (this.provider.network.chainId = chainId);
          this._walletNetworkChangeSubject.next(chainId);
        });
      },
    });

    this.provider = {
      getBalance: async (address) => {
        const result = await getBalance(this.config!, {
          address: address,
        });
        return BigNumber.from(result.value);
      },
      getTokenBalance: async (address, tokenAddress) => {
        const result = await getBalance(this.config!, {
          address,
          token: tokenAddress,
        });
        return BigNumber.from(result.value);
      },
      getSigner: () => {
        return {
          signMessage: (message) => wagmiSignMessage(this.config!, { message }),
          sendTransaction: (txData) => {
            txData.gas = txData.gasLimit ?? txData.gas;
            return wagmiSendTransaction(this.config!, txData);
          },
          getTransactionCount: async () => {
            if (!this.address) {
              return undefined;
            }
            return getPublicClient(this.config!)?.getTransactionCount({
              address: this.address as '0x${string}',
            });
          },
        };
      },
      waitForTransaction: async (hash, confirmations) => {
        const result = await waitForTransactionReceipt(this.config!, {
          hash,
          confirmations,
        });
        return { ...result, status: result.status == 'success' ? 1 : 0 };
      },
      getGasPrice: async () => {
        const gases = (
          await getFeeHistory(this.config!, {
            blockCount: 5,
            rewardPercentiles: [],
          })
        ).baseFeePerGas;
        return BigNumber.from(gases[0]);
      },
      network: { chainId: chainId },
    };
  }
}
