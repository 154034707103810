<div
  *ngIf="privateSaleService.activePresale"
  class="pb-4 justify-center bg-transparent"
>
  <div *ngIf="pageStatus === PageStatus.WALLET_NOT_CONNECTED">
    <app-banner-private-sale></app-banner-private-sale>
  </div>
  <div *ngIf="pageStatus === PageStatus.INVEST">
    <app-invest-private-sale
      [userDetail]="userDetail"
      (changePage)="changePages($event)"
    ></app-invest-private-sale>
  </div>
  <div
    *ngIf="
      pageStatus === PageStatus.DETAILS ||
      pageStatus === PageStatus.SUCCESSFUL_INVESTED
    "
  >
    <app-details-private-sale
      [userDetail]="userDetail"
      (changePage)="changePages($event)"
    ></app-details-private-sale>
  </div>
</div>
