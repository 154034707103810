<div class="container" style="background: transparent">
  <div class="container" style="background: transparent">
    <div class="flex-column justify-content-center">
      <div
        *ngIf="pageStatus === PageStatus.SUCCESSFUL_INVESTED"
        class="flex justify-content-center"
      >
        <img src="assets/media/congrats.png" />
      </div>

      <!--      Successful invest-->
      <div class="flex justify-content-center">
        <p class="font-allroundgothic-semi font-48 text-center">
          You've invested
          <span class="blue-text-00B5D0">${{ userDetail.balance }} </span>
          successfully at Amates Private Sale
        </p>
      </div>

      <div class="flex justify-content-center">
        <p class="font-mundial-thin">
          You've invested ${{ userDetail.balance }}
          successfully at Amates Private Sale
        </p>
      </div>
    </div>
  </div>

  <div class="main-box p-3 rounded-xl">
    <div class="details space-y-4">
      <div class="d-flex justify-content-between">
        <div class="flex flex-shrink-0 items-center">
          <img
            src="assets/media/icons/logo/amates-icon.png"
            class="flex-shrink-0 h-8"
          />
          <div>
            <div class="highlight font-allroundgothic-semi">
              {{ privateSaleService.activePresale?.presaleDisplayName }}
            </div>
            <div class="text-xs">Private Sale</div>
          </div>
        </div>
        <div>
          <div class="text-xs">Private sale price</div>
          <div class="highlight">
            ${{ privateSaleService.poolDetail?.priceStages[0][1] }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <div class="text-xs">All your investment</div>
          <div class="highlight">${{ userDetail.balance }}</div>
          <div class="text-xs">{{ userDetail.investorShare }} AMATES</div>
        </div>

        <div>
          <button
            *ngIf="privateSaleStatus === PrivateSaleStatus.running"
            (click)="investMore()"
            class="font-allroundgothic-medium btn cursor-pointer flex text-sm text-center rounded-full w-full"
          >
            INVEST MORE
          </button>
          <button
            (click)="release()"
            *ngIf="
              privateSaleStatus === PrivateSaleStatus.finished && !buttonLoading
            "
            class="font-allroundgothic-medium btn cursor-pointer flex text-sm text-center rounded-full w-full"
          >
            CLAIM {{ releasable }}&nbsp;{{
              privateSaleService.activePresale.presaleTokenName
            }}
          </button>
          <button
            *ngIf="buttonLoading"
            class="font-allroundgothic-medium flex items-center btn-disabled pointer-events-none cursor-default text-sm text-center rounded-full w-full"
          >
            <div class="flex justify-content-center items-center">
              <div class="mr-2">PLEASE WAIT</div>
              <div class="flex justify-center items-center">
                <div class="loader-container">
                  <div
                    class="loader ease-linear rounded-full border-4 border-t-4 h-4 w-4 border-gray-200"
                  ></div>
                </div>
              </div>
            </div>
          </button>

          <button
            *ngIf="
              isOwner &&
              finishTime < currentTime &&
              poolState !== PoolState.FAILURE &&
              poolState !== PoolState.PREOPEN
            "
            (click)="withdrawBeneficiaryFunds()"
            class="font-allroundgothic-medium btn cursor-pointer flex text-sm text-center rounded-full w-full"
          >
            <span> Withdraw Funds</span>
          </button>

          <button
            *ngIf="
              finishTime < currentTime &&
              poolState === PoolState.FAILURE &&
              userDetail &&
              userDetail.balance > 0 &&
              !isWrongNetwork
            "
            (click)="withdraw()"
            class="font-allroundgothic-medium btn cursor-pointer flex text-sm text-center rounded-full w-full"
          >
            <span> Withdraw ${{ userDetail.balance }} </span>
          </button>
        </div>
      </div>
      <!--      <hr />-->

      <!--      <div class="bg-white p-2 rounded-lg">-->
      <!--        <div class="d-flex justify-content-between">-->
      <!--          <div>-->
      <!--            <div class="text-xs">Logs</div>-->
      <!--            <div class="highlight">-->
      <!--              ${{ userDetail.balance }}-->
      <!--            </div>-->
      <!--            <div class="text-xs">Today 15:07</div>-->
      <!--          </div>-->

      <!--          <div>-->
      <!--            <svg-->
      <!--              class="-mr-1 h-5 w-5"-->
      <!--              fill="currentColor"-->
      <!--              viewBox="0 0 20 20"-->
      <!--              xmlns="http://www.w3.org/2000/svg"-->
      <!--            >-->
      <!--              <path-->
      <!--                clip-rule="evenodd"-->
      <!--                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"-->
      <!--                fill-rule="evenodd"-->
      <!--              />-->
      <!--            </svg>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="main-box p-3 rounded-xl mt-3">
    <div class="position-relative d-flex flex-column width-set">
      <div class="details mt-4 space-y-4">
        <div class="d-flex justify-content-between">
          <div class="flex">
            <div>
              <div class="text-xs green-text-03CA9A">Invested Amount</div>
              <div class="highlight green-text-03CA9A font-allroundgothic-semi">
                {{ privateSaleService.poolDetail?.investedAmountToDisplay }}
              </div>
            </div>
          </div>
          <div>
            <div class="text-xs">Round Target</div>
            <div class="highlight font-allroundgothic-semi">
              {{ privateSaleService.poolDetail?.hardCapToDisplay }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <div class="text-xs">Accepted Tokens:</div>
            <span class="highlight"> Any token, any chain</span>
          </div>
          <div>
            <div class="text-xs">Round Ends:</div>
            <div class="highlight gradient-text">
              {{ finishTimeRemain.daysLeft }}D {{ finishTimeRemain.hoursLeft }}H
              {{ finishTimeRemain.minutesLeft }}M
              {{ finishTimeRemain.secondsLeft }}S
            </div>
            <div class="text-xs">
              {{ finishTime * 1000 | date }}
            </div>
          </div>
        </div>

        <div class="bg-white flex text-white bg-opacity-25">
          <div
            id="stage"
            class="percent-gradient px-1 rounded-md"
            [style.width.%]="privateSaleService.poolDetail?.remainPercent"
          >
            {{
              privateSaleService.poolDetail?.remainPercent > 25
                ? privateSaleService.poolDetail?.remainPercent + "% Remains"
                : ""
            }}
          </div>
          <span class="text-black ml-3">{{
            privateSaleService.poolDetail?.remainPercent > 25
              ? ""
              : privateSaleService.poolDetail?.remainPercent + "% Remains"
          }}</span>
        </div>
      </div>
      <div
        class="links bg-white rounded-xl"
        *ngFor="
          let item of privateSaleService.activePresale.links;
          let i = index
        "
      >
        <div class="flex-column items-center p-3">
          <div class="font-14">
            Want to know more about
            {{ privateSaleService.activePresale.presaleDisplayName }}?
          </div>
          <div
            class="flex blue-bg-D5EEF0 mt-2 rounded-lg p-3 font-allroundgothic-medium items-center"
          >
            <span class="col-2">
              <div [hidden]="item.split('$')[0] !== 'website'">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.59961 9H20.3996"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.59961 15H20.3996"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4997 3C8.06261 8.50778 8.06261 15.4922 11.4997 21"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 3C15.9371 8.50778 15.9371 15.4922 12.5 21"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'instagram'">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    r="3"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 7.4995V7.5005"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'reddit'">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0002 8C14.6482 8 17.0282 8.826 18.6752 10.14C19.8394 9.73191 21.1273 10.2341 21.7079 11.3225C22.2886 12.411 21.9885 13.7603 21.0012 14.5C21.0012 18.09 16.9712 21 12.0012 21C7.12619 21 3.15619 18.2 3.00119 14.706L2.0012 14.5C1.0139 13.7603 0.71378 12.411 1.29446 11.3225C1.87513 10.2341 3.16298 9.73191 4.32719 10.14C5.97319 8.827 8.35319 8 11.0012 8H12.0002Z"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8L13 3L19 4"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="19"
                    cy="4"
                    r="1"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="9"
                    cy="13"
                    r="0.5"
                    fill="#00B5D0"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="15"
                    cy="13"
                    r="0.5"
                    fill="#00B5D0"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 17C10.667 17.333 11.333 17.5 12 17.5C12.667 17.5 13.333 17.333 14 17"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'twitter'">
                <svg
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 2.01001C20 2.50001 19.02 2.69901 18 3.00001C16.879 1.73501 15.217 1.66501 13.62 2.26301C12.023 2.86101 10.977 4.32301 11 6.00001V7.00001C7.755 7.08301 4.865 5.60501 3 3.00001C3 3.00001 -1.182 10.433 7 14C5.128 15.247 3.261 16.088 1 16C4.308 17.803 7.913 18.423 11.034 17.517C14.614 16.477 17.556 13.794 18.685 9.77501C19.0218 8.55268 19.189 7.28987 19.182 6.02201C19.18 5.77301 20.692 3.25001 21 2.00901V2.01001Z"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'pitchDeck'">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10L15 6V17C15 18.1046 14.1046 19 13 19Z"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'telegram'">
                <svg
                  fill="#00B5D0"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>telegram</title>
                  <path
                    d="M22.26465,2.42773a2.04837,2.04837,0,0,0-2.07813-.32421L2.26562,9.33887a2.043,2.043,0,0,0,.1045,3.81836l3.625,1.26074,2.0205,6.68164A.998.998,0,0,0,8.134,21.352c.00775.012.01868.02093.02692.03259a.98844.98844,0,0,0,.21143.21576c.02307.01758.04516.03406.06982.04968a.98592.98592,0,0,0,.31073.13611l.01184.001.00671.00287a1.02183,1.02183,0,0,0,.20215.02051c.00653,0,.01233-.00312.0188-.00324a.99255.99255,0,0,0,.30109-.05231c.02258-.00769.04193-.02056.06384-.02984a.9931.9931,0,0,0,.20429-.11456,250.75993,250.75993,0,0,1,.15222-.12818L12.416,18.499l4.03027,3.12207a2.02322,2.02322,0,0,0,1.24121.42676A2.05413,2.05413,0,0,0,19.69531,20.415L22.958,4.39844A2.02966,2.02966,0,0,0,22.26465,2.42773ZM9.37012,14.73633a.99357.99357,0,0,0-.27246.50586l-.30951,1.504-.78406-2.59307,4.06525-2.11695ZM17.67188,20.04l-4.7627-3.68945a1.00134,1.00134,0,0,0-1.35352.11914l-.86541.9552.30584-1.48645,7.083-7.083a.99975.99975,0,0,0-1.16894-1.59375L6.74487,12.55432,3.02051,11.19141,20.999,3.999Z"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'youtube'">
                <svg
                  fill="#00B5D0"
                  height="24px"
                  width="24px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 49 49"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512
			c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744
			C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"
                      />
                      <path
                        d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229
			c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113
			c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'linkedIn'">
                <svg
                  fill="#00B5D0"
                  width="24px"
                  height="24px"
                  viewBox="-2 -2 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMinYMin"
                  class="jam jam-linkedin-square"
                >
                  <path
                    d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z"
                  />
                  <path
                    d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'medium'">
                <svg
                  fill="#00B5D0"
                  width="24px"
                  height="24px"
                  viewBox="0 0 256 256"
                  id="Flat"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M70,56C33.60742,56,4,88.29885,4,128s29.60742,72,66,72,66-32.29883,66-72S106.39258,56,70,56Zm0,120c-23.15918,0-42-21.5332-42-48S46.84082,80,70,80s42,21.5332,42,48S93.15918,176,70,176ZM182,60c-23.36035,0-34,35.249-34,68s10.63965,68,34,68,34-35.249,34-68S205.36035,60,182,60Zm0,111.26367c-3.65674-4.06347-10-18.89746-10-43.26367s6.34326-39.20019,10-43.26367c3.65674,4.06348,10,18.89746,10,43.26367S185.65674,167.20022,182,171.26369ZM252,72V184a12,12,0,0,1-24,0V72a12,12,0,1,1,24,0Z"
                  />
                </svg>
              </div>
              <div [hidden]="item.split('$')[0] !== 'terms & conditions'">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10L15 6V17C15 18.1046 14.1046 19 13 19Z"
                    stroke="#00B5D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </span>
            <a
              class="blue-text-00B5D0 text-xs"
              href="{{ item.split('$')[1] }}"
              target="_blank"
            >
              {{ item.split("$")[0] }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
