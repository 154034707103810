import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DebridgeService {
  constructor(private http: HttpClient) {}

  public getForSubmission(submissionId) {
    try {
      const url = `https://api.debridge.finance/api/SubmissionConfirmations/getForSubmission?submissionId=${submissionId}`;
      return this.http
        .get<Array<Object>>(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body?.length);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getForSubmission of debridge');
  }

  public getFullSubmissionInfo(sourceTxHash) {
    try {
      const url = `https://api.debridge.finance/api/Transactions/GetFullSubmissionInfo?filter=${sourceTxHash}&filterType=1`;
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getFullSubmissionInfo of debridge');
  }
}
