const e = Symbol("@ts-pattern/matcher"),
  t = "@ts-pattern/anonymous-select-key",
  n = e => Boolean(e && "object" == typeof e),
  r = t => t && !!t[e],
  o = (t, c, a) => {
    if (n(t)) {
      if (r(t)) {
        const n = t[e](),
          {
            matched: r,
            selections: o
          } = n.match(c);
        return r && o && Object.keys(o).forEach(e => a(e, o[e])), r;
      }
      if (!n(c)) return !1;
      if (Array.isArray(t)) return !!Array.isArray(c) && t.length === c.length && t.every((e, t) => o(e, c[t], a));
      if (t instanceof Map) return c instanceof Map && Array.from(t.keys()).every(e => o(t.get(e), c.get(e), a));
      if (t instanceof Set) {
        if (!(c instanceof Set)) return !1;
        if (0 === t.size) return 0 === c.size;
        if (1 === t.size) {
          const [e] = Array.from(t.values());
          return r(e) ? Array.from(c.values()).every(t => o(e, t, a)) : c.has(e);
        }
        return Array.from(t.values()).every(e => c.has(e));
      }
      return Object.keys(t).every(n => {
        const s = t[n];
        return (n in c || r(i = s) && "optional" === i[e]().matcherType) && o(s, c[n], a);
        var i;
      });
    }
    return Object.is(c, t);
  },
  c = t => {
    var o, s, i;
    return n(t) ? r(t) ? null != (o = null == (s = (i = t[e]()).getSelectionKeys) ? void 0 : s.call(i)) ? o : [] : Array.isArray(t) ? a(t, c) : a(Object.values(t), c) : [];
  },
  a = (e, t) => e.reduce((e, n) => e.concat(t(n)), []);
function s(t) {
  return {
    [e]: () => ({
      match: e => {
        let n = {};
        const r = (e, t) => {
          n[e] = t;
        };
        return void 0 === e ? (c(t).forEach(e => r(e, void 0)), {
          matched: !0,
          selections: n
        }) : {
          matched: o(t, e, r),
          selections: n
        };
      },
      getSelectionKeys: () => c(t),
      matcherType: "optional"
    })
  };
}
function i(t) {
  return {
    [e]: () => ({
      match: e => {
        if (!Array.isArray(e)) return {
          matched: !1
        };
        let n = {};
        if (0 === e.length) return c(t).forEach(e => {
          n[e] = [];
        }), {
          matched: !0,
          selections: n
        };
        const r = (e, t) => {
          n[e] = (n[e] || []).concat([t]);
        };
        return {
          matched: e.every(e => o(t, e, r)),
          selections: n
        };
      },
      getSelectionKeys: () => c(t)
    })
  };
}
function u(...t) {
  return {
    [e]: () => ({
      match: e => {
        let n = {};
        const r = (e, t) => {
          n[e] = t;
        };
        return {
          matched: t.every(t => o(t, e, r)),
          selections: n
        };
      },
      getSelectionKeys: () => a(t, c),
      matcherType: "and"
    })
  };
}
function l(...t) {
  return {
    [e]: () => ({
      match: e => {
        let n = {};
        const r = (e, t) => {
          n[e] = t;
        };
        return a(t, c).forEach(e => r(e, void 0)), {
          matched: t.some(t => o(t, e, r)),
          selections: n
        };
      },
      getSelectionKeys: () => a(t, c),
      matcherType: "or"
    })
  };
}
function h(t) {
  return {
    [e]: () => ({
      match: e => ({
        matched: !o(t, e, () => {})
      }),
      getSelectionKeys: () => [],
      matcherType: "not"
    })
  };
}
function f(t) {
  return {
    [e]: () => ({
      match: e => ({
        matched: Boolean(t(e))
      })
    })
  };
}
function y(...n) {
  const r = "string" == typeof n[0] ? n[0] : void 0,
    a = 2 === n.length ? n[1] : "string" == typeof n[0] ? void 0 : n[0];
  return {
    [e]: () => ({
      match: e => {
        let n = {
          [null != r ? r : t]: e
        };
        return {
          matched: void 0 === a || o(a, e, (e, t) => {
            n[e] = t;
          }),
          selections: n
        };
      },
      getSelectionKeys: () => [null != r ? r : t].concat(void 0 === a ? [] : c(a))
    })
  };
}
const m = f(function (e) {
    return !0;
  }),
  v = m,
  d = f(function (e) {
    return "string" == typeof e;
  }),
  g = f(function (e) {
    return "number" == typeof e;
  }),
  p = f(function (e) {
    return "boolean" == typeof e;
  }),
  b = f(function (e) {
    return "bigint" == typeof e;
  }),
  w = f(function (e) {
    return "symbol" == typeof e;
  }),
  A = f(function (e) {
    return null == e;
  });
var S = {
  __proto__: null,
  optional: s,
  array: i,
  intersection: u,
  union: l,
  not: h,
  when: f,
  select: y,
  any: m,
  _: v,
  string: d,
  number: g,
  boolean: p,
  bigint: b,
  symbol: w,
  nullish: A,
  instanceOf: function (e) {
    return f(function (e) {
      return t => t instanceof e;
    }(e));
  },
  typed: function () {
    return {
      array: i,
      optional: s,
      intersection: u,
      union: l,
      not: h,
      select: y,
      when: f
    };
  }
};
const K = e => new O(e, []);
class O {
  constructor(e, t) {
    this.value = void 0, this.cases = void 0, this.value = e, this.cases = t;
  }
  with(...e) {
    const n = e[e.length - 1],
      r = [e[0]],
      c = [];
    return 3 === e.length && "function" == typeof e[1] ? (r.push(e[0]), c.push(e[1])) : e.length > 2 && r.push(...e.slice(1, e.length - 1)), new O(this.value, this.cases.concat([{
      match: e => {
        let n = {};
        const a = Boolean(r.some(t => o(t, e, (e, t) => {
          n[e] = t;
        })) && c.every(t => t(e)));
        return {
          matched: a,
          value: a && Object.keys(n).length ? t in n ? n[t] : n : e
        };
      },
      handler: n
    }]));
  }
  when(e, t) {
    return new O(this.value, this.cases.concat([{
      match: t => ({
        matched: Boolean(e(t)),
        value: t
      }),
      handler: t
    }]));
  }
  otherwise(e) {
    return new O(this.value, this.cases.concat([{
      match: e => ({
        matched: !0,
        value: e
      }),
      handler: e
    }])).run();
  }
  exhaustive() {
    return this.run();
  }
  run() {
    let e,
      t = this.value;
    for (let n = 0; n < this.cases.length; n++) {
      const r = this.cases[n],
        o = r.match(this.value);
      if (o.matched) {
        t = o.value, e = r.handler;
        break;
      }
    }
    if (!e) {
      let e;
      try {
        e = JSON.stringify(this.value);
      } catch (t) {
        e = this.value;
      }
      throw new Error(`Pattern matching error: no pattern matches value ${e}`);
    }
    return e(t, this.value);
  }
}
function j(...e) {
  if (1 === e.length) {
    const [t] = e;
    return e => o(t, e, () => {});
  }
  if (2 === e.length) {
    const [t, n] = e;
    return o(t, n, () => {});
  }
  throw new Error(`isMatching wasn't given the right number of arguments: expected 1 or 2, received ${e.length}.`);
}
export { S as P, S as Pattern, j as isMatching, K as match };
