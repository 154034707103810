<div
  class="container flex justify-content-center"
  style="background: transparent"
>
  <div class="flex-column">
    <div class="flex justify-content-center">
      <div class="font-allroundgothic-semi font-24 font-md-48 text-center">
        Become an Early Contributor in
        <span class="blue-text-00B5D0">Amates </span>
      </div>
    </div>
    <div class="flex justify-content-center">
      <div class="text-center font-mundial-thin font-16">
        A short description contain of benefits and easy process of buying
      </div>
    </div>
  </div>
</div>
