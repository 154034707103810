var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, MathUtil } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { RouterController, ConvertController } from '@web3modal/core';
import { state } from 'lit/decorators.js';
let W3mConvertSelectTokenView = class W3mConvertSelectTokenView extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.targetToken = RouterController.state.data?.target;
    this.sourceToken = ConvertController.state.sourceToken;
    this.toToken = ConvertController.state.toToken;
    this.searchValue = '';
    this.unsubscribe.push(...[ConvertController.subscribe(newState => {
      this.sourceToken = newState.sourceToken;
      this.toToken = newState.toToken;
    })]);
    this.watchTokens();
  }
  updated() {
    const suggestedTokensContainer = this.renderRoot?.querySelector('.suggested-tokens-container');
    suggestedTokensContainer?.addEventListener('scroll', this.handleSuggestedTokensScroll.bind(this));
    const tokensList = this.renderRoot?.querySelector('.tokens');
    tokensList?.addEventListener('scroll', this.handleTokenListScroll.bind(this));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    const suggestedTokensContainer = this.renderRoot?.querySelector('.suggested-tokens-container');
    const tokensList = this.renderRoot?.querySelector('.tokens');
    suggestedTokensContainer?.removeEventListener('scroll', this.handleSuggestedTokensScroll.bind(this));
    tokensList?.removeEventListener('scroll', this.handleTokenListScroll.bind(this));
    clearInterval(this.interval);
  }
  render() {
    return html`
      <wui-flex flexDirection="column" gap="s">
        ${this.templateSearchInput()} ${this.templateSuggestedTokens()} ${this.templateTokens()}
      </wui-flex>
    `;
  }
  watchTokens() {
    this.interval = setInterval(() => {
      ConvertController.getNetworkTokenPrice();
      ConvertController.getMyTokensWithBalance();
    }, 5000);
  }
  onSelectToken(token) {
    if (this.targetToken === 'sourceToken') {
      ConvertController.setSourceToken(token);
    } else {
      ConvertController.setToToken(token);
    }
    RouterController.goBack();
  }
  templateSearchInput() {
    return html`
      <wui-flex class="search-input-container" gap="xs">
        <wui-input-text
          class="network-search-input"
          size="sm"
          placeholder="Search token"
          icon="search"
          .value=${this.searchValue}
          @inputChange=${this.onSearchInputChange.bind(this)}
        ></wui-input-text>
      </wui-flex>
    `;
  }
  templateTokens() {
    const yourTokens = ConvertController.state.myTokensWithBalance ? Object.values(ConvertController.state.myTokensWithBalance) : [];
    const tokens = ConvertController.state.popularTokens ? ConvertController.state.popularTokens : [];
    const filteredYourTokens = this.filterTokensWithText(yourTokens, this.searchValue);
    const filteredTokens = this.filterTokensWithText(tokens, this.searchValue);
    return html`
      <wui-flex class="tokens-container">
        <wui-flex class="tokens" flexDirection="column">
          ${filteredYourTokens?.length > 0 ? html`
                <wui-flex justifyContent="flex-start" padding="s">
                  <wui-text variant="paragraph-500" color="fg-200">Your tokens</wui-text>
                </wui-flex>
                ${filteredYourTokens.map(token => {
      const selected = token.symbol === this.sourceToken?.symbol || token.symbol === this.toToken?.symbol;
      return html`
                    <wui-token-list-item
                      name=${token.name}
                      ?disabled=${selected}
                      symbol=${token.symbol}
                      price=${token?.price}
                      amount=${token?.quantity?.numeric}
                      imageSrc=${token.logoUri}
                      @click=${() => {
        if (!selected) {
          this.onSelectToken(token);
        }
      }}
                    >
                    </wui-token-list-item>
                  `;
    })}
              ` : null}

          <wui-flex justifyContent="flex-start" padding="s">
            <wui-text variant="paragraph-500" color="fg-200">Popular tokens</wui-text>
          </wui-flex>

          ${filteredTokens?.length > 0 ? filteredTokens.map(token => html`
                  <wui-token-list-item
                    name=${token.name}
                    symbol=${token.symbol}
                    imageSrc=${token.logoUri}
                    @click=${() => this.onSelectToken(token)}
                  >
                  </wui-token-list-item>
                `) : null}
        </wui-flex>
      </wui-flex>
    `;
  }
  templateSuggestedTokens() {
    const tokens = ConvertController.state.suggestedTokens ? ConvertController.state.suggestedTokens.slice(0, 8) : null;
    if (!tokens) {
      return null;
    }
    return html`
      <wui-flex class="suggested-tokens-container" gap="xs">
        ${tokens.map(token => html`
            <wui-token-button
              text=${token.symbol}
              imageSrc=${token.logoUri}
              @click=${() => this.onSelectToken(token)}
            >
            </wui-token-button>
          `)}
      </wui-flex>
    `;
  }
  onSearchInputChange(event) {
    this.searchValue = event.detail;
  }
  handleSuggestedTokensScroll() {
    const container = this.renderRoot?.querySelector('.suggested-tokens-container');
    if (!container) {
      return;
    }
    container.style.setProperty('--suggested-tokens-scroll--left-opacity', MathUtil.interpolate([0, 100], [0, 1], container.scrollLeft).toString());
    container.style.setProperty('--suggested-tokens-scroll--right-opacity', MathUtil.interpolate([0, 100], [0, 1], container.scrollWidth - container.scrollLeft - container.offsetWidth).toString());
  }
  handleTokenListScroll() {
    const container = this.renderRoot?.querySelector('.tokens');
    if (!container) {
      return;
    }
    container.style.setProperty('--tokens-scroll--top-opacity', MathUtil.interpolate([0, 100], [0, 1], container.scrollTop).toString());
    container.style.setProperty('--tokens-scroll--bottom-opacity', MathUtil.interpolate([0, 100], [0, 1], container.scrollHeight - container.scrollTop - container.offsetHeight).toString());
  }
  filterTokensWithText(tokens, text) {
    return tokens.filter(token => `${token.symbol} ${token.name} ${token.address}`.toLowerCase().includes(text.toLowerCase()));
  }
};
W3mConvertSelectTokenView.styles = styles;
__decorate([state()], W3mConvertSelectTokenView.prototype, "interval", void 0);
__decorate([state()], W3mConvertSelectTokenView.prototype, "targetToken", void 0);
__decorate([state()], W3mConvertSelectTokenView.prototype, "sourceToken", void 0);
__decorate([state()], W3mConvertSelectTokenView.prototype, "toToken", void 0);
__decorate([state()], W3mConvertSelectTokenView.prototype, "searchValue", void 0);
W3mConvertSelectTokenView = __decorate([customElement('w3m-convert-select-token-view')], W3mConvertSelectTokenView);
export { W3mConvertSelectTokenView };
