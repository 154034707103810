<div class="container" style="background: transparent">
  <app-header-private-sale></app-header-private-sale>
  <div
    class="flex text-sm font-mundial-demibold justify-content-center items-center"
  >
    <div class="flex text-xs space-x-4 mb-3 mr-4">
      <div class="blue-text-00B5D0">Connect Wallet</div>
      <div class="blue-text-ACDCE1">Buy Token</div>
      <div class="blue-text-ACDCE1">Well Done</div>
    </div>
  </div>
  <div class="main-box p-3 rounded-xl">
    <div class="details space-y-4">
      <div class="d-flex justify-content-between">
        <div class="flex flex-shrink-0 items-center">
          <img
            src="assets/media/icons/logo/amates-icon.png"
            class="flex-shrink-0 h-8 mr-2"
          />
          <div>
            <div class="highlight font-allroundgothic-semi mb--7">
              {{ privateSaleService.activePresale?.presaleDisplayName }}
            </div>
            <div class="text-xs">Private Sale</div>
          </div>
        </div>
        <div>
          <div class="text-xs mb--7">Private sale price</div>
          <div class="highlight">
            ${{ privateSaleService.poolDetail?.priceStages[0][1] }}
          </div>
        </div>
      </div>
      <div class="d-flex mt-4 justify-content-between">
        <div>
          <div class="text-xs">Minimum Invest:</div>
          <div class="highlight">
            ${{ privateSaleService.poolDetail?.minInvest }}
          </div>
        </div>
        <div>
          <div class="text-xs">Vesting:</div>
          <div class="highlight">6 Months</div>
        </div>
        <div class="d-none d-md-block">
          <div class="text-xs">Accepted Tokens:</div>
          <span class="highlight"> Any token, any chain</span>
        </div>
      </div>
      <div class="d-block d-md-none mt-4 justify-content-center text-center">
        <div>
          <div class="text-xs">Accepted Tokens:</div>
          <span class="highlight"> Any token, any chain</span>
        </div>
      </div>
      <div class="bg-white mt-4 p-2 rounded-lg">
        <div
          class="w-full text-align align-items-center justify-content-between d-flex mt-3 flex-md-row flex-column"
        >
          <div class="">
            <div class="text-xs">Invested Amount:</div>
            <div class="highlight">
              {{ privateSaleService.poolDetail?.investedAmountToDisplay }}
            </div>
          </div>
          <div>
            <div class="text-xs">Round Target:</div>
            <div class="highlight">
              {{ privateSaleService.poolDetail?.hardCapToDisplay }}
            </div>
          </div>
          <div>
            <div class="text-xs">Round Ends:</div>
            <div class="highlight gradient-text">
              {{ finishTimeRemain.daysLeft }}D {{ finishTimeRemain.hoursLeft }}H
              {{ finishTimeRemain.minutesLeft }}M
              {{ finishTimeRemain.secondsLeft }}S
            </div>
            <div class="text-xs">
              {{ finishTime * 1000 | date }}
            </div>
          </div>
        </div>

        <div class="bg-gray-50 mt-2 rounded-md flex text-white">
          <div
            id="stage"
            class="percent-gradient px-1 rounded-md"
            [style.width.%]="privateSaleService.poolDetail?.remainPercent"
          >
            {{
              privateSaleService.poolDetail?.remainPercent > 25
                ? privateSaleService.poolDetail?.remainPercent + "% Remains"
                : ""
            }}
          </div>
          <span class="text-black ml-3">{{
            privateSaleService.poolDetail?.remainPercent > 25
              ? ""
              : privateSaleService.poolDetail?.remainPercent + "% Remains"
          }}</span>
        </div>
      </div>
    </div>
    <div class="connect-wallet">
      <input [(ngModel)]="isAcceptMarked" type="checkbox" id="terms" />
      <label for="terms"
        >I confirm that I’m not taking part in the Terms of services from one of
        the restricted regions, and
        <a class="underline blue-text-00B5D0" href="#">
          accept the terms of services.</a
        ></label
      >
    </div>
    <button
      (click)="connectWallet()"
      class="font-allroundgothic-medium flex text-sm text-center rounded-full w-full"
      [ngClass]="
        isAcceptMarked && !isUserWalletConnected
          ? 'btn cursor-pointer'
          : 'btn-disabled pointer-events-none cursor-default'
      "
    >
      CONNECT WALLET TO INVEST
    </button>
    <div class="flex font-12 font-md-16 mt-2 justify-content-center">
      <div>Don’t you want to connect your wallet?</div>
      <a class="underline blue-text-00B5D0" href="mailto:amates@support.com"
        >Email us</a
      >
    </div>
  </div>
</div>
