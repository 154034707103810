var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { UiHelperUtil, customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import { state } from 'lit/decorators.js';
import styles from './styles.js';
import { ConvertController, RouterController, CoreHelperUtil, NetworkController, ModalController, ConstantsUtil } from '@web3modal/core';
import { NumberUtil } from '@web3modal/common';
let W3mConvertView = class W3mConvertView extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.detailsOpen = false;
    this.caipNetworkId = NetworkController.state.caipNetwork?.id;
    this.initialized = ConvertController.state.initialized;
    this.loading = ConvertController.state.loading;
    this.loadingPrices = ConvertController.state.loadingPrices;
    this.sourceToken = ConvertController.state.sourceToken;
    this.sourceTokenAmount = ConvertController.state.sourceTokenAmount;
    this.sourceTokenPriceInUSD = ConvertController.state.sourceTokenPriceInUSD;
    this.toToken = ConvertController.state.toToken;
    this.toTokenAmount = ConvertController.state.toTokenAmount;
    this.toTokenPriceInUSD = ConvertController.state.toTokenPriceInUSD;
    this.inputError = ConvertController.state.inputError;
    this.gasPriceInUSD = ConvertController.state.gasPriceInUSD;
    this.priceImpact = ConvertController.state.priceImpact;
    this.maxSlippage = ConvertController.state.maxSlippage;
    this.transactionLoading = ConvertController.state.transactionLoading;
    this.onDebouncedGetSwapCalldata = CoreHelperUtil.debounce(async () => {
      await ConvertController.convertTokens();
    }, 500);
    NetworkController.subscribeKey('caipNetwork', newCaipNetwork => {
      if (this.caipNetworkId !== newCaipNetwork?.id) {
        this.caipNetworkId = newCaipNetwork?.id;
        ConvertController.resetTokens();
        ConvertController.resetValues();
        ConvertController.initializeState();
      }
    });
    this.unsubscribe.push(...[ModalController.subscribeKey('open', isOpen => {
      if (!isOpen) {
        ConvertController.resetValues();
      }
    }), RouterController.subscribeKey('view', newRoute => {
      if (!newRoute.includes('Convert')) {
        ConvertController.resetValues();
      }
    }), ConvertController.subscribe(newState => {
      this.initialized = newState.initialized;
      this.loading = newState.loading;
      this.loadingPrices = newState.loadingPrices;
      this.transactionLoading = newState.transactionLoading;
      this.sourceToken = newState.sourceToken;
      this.sourceTokenAmount = newState.sourceTokenAmount;
      this.sourceTokenPriceInUSD = newState.sourceTokenPriceInUSD;
      this.toToken = newState.toToken;
      this.toTokenAmount = newState.toTokenAmount;
      this.toTokenPriceInUSD = newState.toTokenPriceInUSD;
      this.inputError = newState.inputError;
      this.gasPriceInUSD = newState.gasPriceInUSD;
      this.priceImpact = newState.priceImpact;
      this.maxSlippage = newState.maxSlippage;
    })]);
    this.watchTokensAndValues();
  }
  firstUpdated() {
    if (!this.initialized) {
      ConvertController.initializeState();
    }
  }
  disconnectedCallback() {
    ConvertController.setLoading(false);
    this.unsubscribe.forEach(unsubscribe => unsubscribe?.());
    clearInterval(this.interval);
  }
  render() {
    return html`
      <wui-flex flexDirection="column" padding="l" gap="s">
        ${this.initialized ? this.templateSwap() : this.templateLoading()}
      </wui-flex>
    `;
  }
  watchTokensAndValues() {
    this.interval = setInterval(() => {
      ConvertController.getNetworkTokenPrice();
      ConvertController.getMyTokensWithBalance();
      ConvertController.refreshConvertValues();
    }, 20000);
  }
  templateSwap() {
    return html`
      <wui-flex flexDirection="column" gap="l">
        <wui-flex
          flexDirection="column"
          alignItems="center"
          gap="xs"
          class="convert-inputs-container"
        >
          ${this.templateTokenInput('sourceToken', this.sourceToken)}
          ${this.templateTokenInput('toToken', this.toToken)} ${this.templateReplaceTokensButton()}
        </wui-flex>
        ${this.templateDetails()} ${this.templateActionButton()}
      </wui-flex>
    `;
  }
  actionButtonLabel() {
    if (this.inputError) {
      return this.inputError;
    }
    return 'Review convert';
  }
  templateReplaceTokensButton() {
    return html`
      <button class="replace-tokens-button" @click=${this.onSwitchTokens.bind(this)}>
        <wui-icon name="recycleHorizontal" color="fg-250" size="lg"></wui-icon>
      </button>
    `;
  }
  templateLoading() {
    return html`<wui-flex
      flexGrow="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      .padding=${['3xl', 'xl', '3xl', 'xl']}
      gap="xl"
    >
      <wui-icon-box
        backgroundColor="glass-005"
        background="gray"
        iconColor="fg-200"
        icon="swapHorizontalRoundedBold"
        size="lg"
        ?border=${true}
        borderColor="wui-color-bg-125"
      ></wui-icon-box>

      <wui-loading-hexagon></wui-loading-hexagon>
    </wui-flex>`;
  }
  templateTokenInput(target, token) {
    const myToken = ConvertController.state.myTokensWithBalance?.find(ct => ct?.address === token?.address);
    const amount = target === 'toToken' ? this.toTokenAmount : this.sourceTokenAmount;
    const price = target === 'toToken' ? this.toTokenPriceInUSD : this.sourceTokenPriceInUSD;
    let value = parseFloat(amount) * price;
    if (target === 'toToken') {
      value -= this.gasPriceInUSD || 0;
    }
    return html`<w3m-convert-input
      .value=${target === 'toToken' ? this.toTokenAmount : this.sourceTokenAmount}
      ?disabled=${this.loading && target === 'toToken'}
      .onSetAmount=${this.handleChangeAmount.bind(this)}
      target=${target}
      .token=${token}
      .balance=${myToken?.quantity?.numeric}
      .price=${this.sourceTokenPriceInUSD}
      .marketValue=${isNaN(value) ? '' : UiHelperUtil.formatNumberToLocalString(value)}
      .onSetMaxValue=${this.onSetMaxValue.bind(this)}
    ></w3m-convert-input>`;
  }
  onSetMaxValue(target, balance) {
    const token = target === 'sourceToken' ? this.sourceToken : this.toToken;
    const isNetworkToken = token?.address === ConstantsUtil.NATIVE_TOKEN_ADDRESS;
    let value = '0';
    if (!balance) {
      value = '0';
      this.handleChangeAmount(target, value);
      return;
    }
    if (!this.gasPriceInUSD) {
      value = balance;
      this.handleChangeAmount(target, value);
      return;
    }
    const amountOfTokenGasRequires = NumberUtil.bigNumber(this.gasPriceInUSD.toFixed(5)).dividedBy(this.sourceTokenPriceInUSD);
    const maxValue = isNetworkToken ? NumberUtil.bigNumber(balance).minus(amountOfTokenGasRequires) : NumberUtil.bigNumber(balance);
    this.handleChangeAmount(target, maxValue.isGreaterThan(0) ? maxValue.toFixed(20) : '0');
  }
  templateDetails() {
    if (this.loading || this.inputError) {
      return null;
    }
    if (!this.sourceToken || !this.toToken || !this.sourceTokenAmount || !this.toTokenAmount) {
      return null;
    }
    const toTokenConvertedAmount = this.sourceTokenPriceInUSD && this.toTokenPriceInUSD ? 1 / this.toTokenPriceInUSD * this.sourceTokenPriceInUSD : 0;
    return html`
      <w3m-convert-details
        .detailsOpen=${this.detailsOpen}
        sourceTokenSymbol=${this.sourceToken?.symbol}
        sourceTokenPrice=${this.sourceTokenPriceInUSD}
        toTokenSymbol=${this.toToken?.symbol}
        toTokenConvertedAmount=${toTokenConvertedAmount}
        gasPriceInUSD=${this.gasPriceInUSD}
        .priceImpact=${this.priceImpact}
        slippageRate=${ConstantsUtil.CONVERT_SLIPPAGE_TOLERANCE}
        .maxSlippage=${this.maxSlippage}
      ></w3m-convert-details>
    `;
  }
  handleChangeAmount(target, value) {
    ConvertController.clearError();
    if (target === 'sourceToken') {
      ConvertController.setSourceTokenAmount(value);
    } else {
      ConvertController.setToTokenAmount(value);
    }
    this.onDebouncedGetSwapCalldata();
  }
  templateActionButton() {
    const haveNoTokenSelected = !this.toToken || !this.sourceToken;
    const loading = this.loading || this.loadingPrices || this.transactionLoading;
    return html` <wui-flex gap="xs">
      <wui-button
        class="action-button"
        ?fullWidth=${true}
        size="lg"
        borderRadius="xs"
        variant=${haveNoTokenSelected ? 'shade' : 'fill'}
        .loading=${loading}
        .disabled=${loading || haveNoTokenSelected || this.inputError}
        @click=${this.onConvertPreview}
      >
        ${this.actionButtonLabel()}
      </wui-button>
    </wui-flex>`;
  }
  onSwitchTokens() {
    ConvertController.switchTokens();
  }
  onConvertPreview() {
    RouterController.push('ConvertPreview');
  }
};
W3mConvertView.styles = styles;
__decorate([state()], W3mConvertView.prototype, "interval", void 0);
__decorate([state()], W3mConvertView.prototype, "detailsOpen", void 0);
__decorate([state()], W3mConvertView.prototype, "caipNetworkId", void 0);
__decorate([state()], W3mConvertView.prototype, "initialized", void 0);
__decorate([state()], W3mConvertView.prototype, "loading", void 0);
__decorate([state()], W3mConvertView.prototype, "loadingPrices", void 0);
__decorate([state()], W3mConvertView.prototype, "sourceToken", void 0);
__decorate([state()], W3mConvertView.prototype, "sourceTokenAmount", void 0);
__decorate([state()], W3mConvertView.prototype, "sourceTokenPriceInUSD", void 0);
__decorate([state()], W3mConvertView.prototype, "toToken", void 0);
__decorate([state()], W3mConvertView.prototype, "toTokenAmount", void 0);
__decorate([state()], W3mConvertView.prototype, "toTokenPriceInUSD", void 0);
__decorate([state()], W3mConvertView.prototype, "inputError", void 0);
__decorate([state()], W3mConvertView.prototype, "gasPriceInUSD", void 0);
__decorate([state()], W3mConvertView.prototype, "priceImpact", void 0);
__decorate([state()], W3mConvertView.prototype, "maxSlippage", void 0);
__decorate([state()], W3mConvertView.prototype, "transactionLoading", void 0);
W3mConvertView = __decorate([customElement('w3m-convert-view')], W3mConvertView);
export { W3mConvertView };
