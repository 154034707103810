"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const sdk_1 = require("@crowdswap/sdk");
const address_1 = require("@ethersproject/address");
const dexchanges_1 = require("../dexchanges");
const crowd_token_1 = require("./crowd-token");
const networks_1 = require("../utils/networks");
const token_type_1 = require("../type/token-type");
// TokenListArray
class TokensHolder {
  constructor() {
    this._ETH_ONLY = {};
    this._WETH_ONLY = {};
    this._CROWD = {};
    this._CUSTOM_BASES = {};
    this._BASES_TO_CHECK_TRADES_AGAINST = {};
    this._ObservableTokenListBySymbol = {};
    this._TokenListBySymbol = {};
    this._TokenListByAddress = {};
    this._TokenListArray = {};
  }
  get ETH_ONLY() {
    return this._ETH_ONLY;
  }
  get WETH_ONLY() {
    return this._WETH_ONLY;
  }
  get CROWD() {
    return this._CROWD;
  }
  get CUSTOM_BASES() {
    return this._CUSTOM_BASES;
  }
  get BASES_TO_CHECK_TRADES_AGAINST() {
    return this._BASES_TO_CHECK_TRADES_AGAINST;
  }
  get ObservableTokenListBySymbol() {
    return this._ObservableTokenListBySymbol;
  }
  get TokenListBySymbol() {
    return this._TokenListBySymbol;
  }
  get TokenListByAddress() {
    return this._TokenListByAddress;
  }
  get TokenListArray() {
    return this._TokenListArray;
  }
  EnsureIsDefined(input, prop, defaultValue) {
    if (!input[prop]) {
      input[prop] = defaultValue;
    }
  }
  FillAllTokens(tokens) {
    this._TokenListArray = {};
    tokens.forEach(_token => {
      const token = new crowd_token_1.CrowdToken(_token.chainId, (0, address_1.getAddress)(_token.address), _token.decimals, _token.symbol, _token.name);
      token.hasCustomPrice = _token.hasCustomPrice;
      token.type = this.tokenType(_token);
      this.EnsureIsDefined(this._TokenListArray, networks_1.Networks[token.chainId], []);
      this._TokenListArray[networks_1.Networks[token.chainId]].push(token);
      if (_token.observable) {
        this.EnsureIsDefined(this._ObservableTokenListBySymbol, networks_1.Networks[token.chainId], {});
        this._ObservableTokenListBySymbol[networks_1.Networks[token.chainId]][token.symbol] = token;
      }
      this.EnsureIsDefined(this._TokenListBySymbol, networks_1.Networks[token.chainId], {});
      this.EnsureIsDefined(this._TokenListByAddress, networks_1.Networks[token.chainId], {});
      this._TokenListBySymbol[networks_1.Networks[token.chainId]][token.symbol] = token;
      this._TokenListByAddress[networks_1.Networks[token.chainId]][token.address] = token;
    });
    this.FillAfterAll();
  }
  FillAfterAll() {
    const TokenListBySymbol = this._TokenListBySymbol;
    this._CUSTOM_BASES = {
      [sdk_1.ChainId.MAINNET]: {
        [TokenListBySymbol.MAINNET['AMPL'].address]: [TokenListBySymbol.MAINNET['DAI'], sdk_1.WETH[sdk_1.ChainId.MAINNET]]
      }
    };
    this._WETH_ONLY = {
      [sdk_1.ChainId.MAINNET]: [sdk_1.WETH[sdk_1.ChainId.MAINNET]],
      [sdk_1.ChainId.ROPSTEN]: [sdk_1.WETH[sdk_1.ChainId.ROPSTEN]],
      [sdk_1.ChainId.RINKEBY]: [sdk_1.WETH[sdk_1.ChainId.RINKEBY]],
      [sdk_1.ChainId.GOERLI]: [sdk_1.WETH[sdk_1.ChainId.GOERLI]],
      [sdk_1.ChainId.KOVAN]: [sdk_1.WETH[sdk_1.ChainId.KOVAN]],
      [sdk_1.ChainId.BSCMAIN]: [TokenListBySymbol.BSCMAIN['WBNB']],
      [sdk_1.ChainId.BSCTEST]: [TokenListBySymbol.BSCTEST['WBNB']],
      [sdk_1.ChainId.POLYGON_MAINNET]: [TokenListBySymbol.POLYGON_MAINNET['WMATIC']],
      [sdk_1.ChainId.POLYGON_MUMBAI]: [TokenListBySymbol.POLYGON_MUMBAI['WMATIC']],
      [sdk_1.ChainId.AVALANCHE]: [TokenListBySymbol.AVALANCHE['WAVAX']],
      [sdk_1.ChainId.AVALANCHE_FUJI]: [TokenListBySymbol.AVALANCHE_FUJI['WAVAX']],
      [sdk_1.ChainId.APEX]: [TokenListBySymbol.APEX['WOMNIA']],
      [sdk_1.ChainId.ARBITRUM]: [TokenListBySymbol.ARBITRUM['WETH']],
      [sdk_1.ChainId.ZKSYNC]: [TokenListBySymbol.ZKSYNC['WETH']],
      [sdk_1.ChainId.ZKSYNCTEST]: [TokenListBySymbol.ZKSYNCTEST['WETH']],
      [sdk_1.ChainId.OPTIMISM]: [TokenListBySymbol.OPTIMISM['WETH']],
      [sdk_1.ChainId.LINEA]: [TokenListBySymbol.LINEA['WETH']],
      [sdk_1.ChainId.BASE]: [TokenListBySymbol.BASE['WETH']],
      [sdk_1.ChainId.DEFI]: [TokenListBySymbol.DEFI['WDFI']]
    };
    this._ETH_ONLY = {
      [sdk_1.ChainId.MAINNET]: [TokenListBySymbol.MAINNET['ETH']],
      [sdk_1.ChainId.ROPSTEN]: [sdk_1.ETH[sdk_1.ChainId.ROPSTEN]],
      [sdk_1.ChainId.RINKEBY]: [sdk_1.ETH[sdk_1.ChainId.RINKEBY]],
      [sdk_1.ChainId.GOERLI]: [sdk_1.ETH[sdk_1.ChainId.GOERLI]],
      [sdk_1.ChainId.KOVAN]: [sdk_1.ETH[sdk_1.ChainId.KOVAN]],
      [sdk_1.ChainId.BSCMAIN]: [TokenListBySymbol.BSCMAIN['BNB']],
      [sdk_1.ChainId.BSCTEST]: [TokenListBySymbol.BSCTEST['BNB']],
      [sdk_1.ChainId.POLYGON_MAINNET]: [TokenListBySymbol.POLYGON_MAINNET['MATIC']],
      [sdk_1.ChainId.POLYGON_MUMBAI]: [TokenListBySymbol.POLYGON_MUMBAI['MATIC']],
      [sdk_1.ChainId.AVALANCHE]: [TokenListBySymbol.AVALANCHE['AVAX']],
      [sdk_1.ChainId.AVALANCHE_FUJI]: [TokenListBySymbol.AVALANCHE_FUJI['AVAX']],
      [sdk_1.ChainId.APEX]: [TokenListBySymbol.APEX['OMNIA']],
      [sdk_1.ChainId.ARBITRUM]: [TokenListBySymbol.ARBITRUM['ETH']],
      [sdk_1.ChainId.ZKSYNC]: [TokenListBySymbol.ZKSYNC['ETH']],
      [sdk_1.ChainId.ZKSYNCTEST]: [TokenListBySymbol.ZKSYNCTEST['ETH']],
      [sdk_1.ChainId.OPTIMISM]: [TokenListBySymbol.OPTIMISM['ETH']],
      [sdk_1.ChainId.LINEA]: [TokenListBySymbol.LINEA['ETH']],
      [sdk_1.ChainId.BASE]: [TokenListBySymbol.BASE['ETH']],
      [sdk_1.ChainId.DEFI]: [TokenListBySymbol.DEFI['DFI']]
    };
    this._CROWD = {
      [sdk_1.ChainId.MAINNET]: TokenListBySymbol.MAINNET['CROWD'],
      [sdk_1.ChainId.BSCMAIN]: TokenListBySymbol.BSCMAIN['CROWD'],
      [sdk_1.ChainId.POLYGON_MAINNET]: TokenListBySymbol.POLYGON_MAINNET['CROWD'],
      [sdk_1.ChainId.AVALANCHE]: TokenListBySymbol.AVALANCHE['CROWD'],
      [sdk_1.ChainId.ARBITRUM]: TokenListBySymbol.ARBITRUM['CROWD'],
      [sdk_1.ChainId.ZKSYNC]: TokenListBySymbol.ZKSYNC['CROWD'],
      [sdk_1.ChainId.DEFI]: TokenListBySymbol.DEFI['CROWD']
    };
    const WETH_ONLY = this._WETH_ONLY;
    this._BASES_TO_CHECK_TRADES_AGAINST = {
      [sdk_1.ChainId.MAINNET]: {
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.MAINNET], TokenListBySymbol.MAINNET['DAI'], TokenListBySymbol.MAINNET['USDC'], TokenListBySymbol.MAINNET['USDT'], TokenListBySymbol.MAINNET['WBTC']],
        [dexchanges_1.Dexchanges.Radioshack.name]: [...WETH_ONLY[sdk_1.ChainId.MAINNET], TokenListBySymbol.MAINNET['RADIO'], TokenListBySymbol.MAINNET['SHACK']]
      },
      [sdk_1.ChainId.ROPSTEN]: {
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.MAINNET], TokenListBySymbol.MAINNET['DAI'], TokenListBySymbol.MAINNET['USDC'], TokenListBySymbol.MAINNET['USDT'], TokenListBySymbol.MAINNET['WBTC']]
      },
      [sdk_1.ChainId.POLYGON_MAINNET]: {
        [dexchanges_1.Dexchanges.Quickswap.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH'], TokenListBySymbol.POLYGON_MAINNET['QUICK'], TokenListBySymbol.POLYGON_MAINNET['CXETH'], TokenListBySymbol.POLYGON_MAINNET['MIMATIC'], TokenListBySymbol.POLYGON_MAINNET['WBTC']],
        [dexchanges_1.Dexchanges.Apeswap.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH'], TokenListBySymbol.POLYGON_MAINNET['WBTC']],
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH']],
        [dexchanges_1.Dexchanges.Radioshack.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['RADIO'], TokenListBySymbol.POLYGON_MAINNET['SHACK']],
        [dexchanges_1.Dexchanges.Crowdswap.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['CROWD'], TokenListBySymbol.POLYGON_MAINNET['USDC']]
      },
      [sdk_1.ChainId.POLYGON_MUMBAI]: {
        [dexchanges_1.Dexchanges.Quickswap.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH'], TokenListBySymbol.POLYGON_MAINNET['QUICK'], TokenListBySymbol.POLYGON_MAINNET['CXETH'], TokenListBySymbol.POLYGON_MAINNET['MIMATIC'], TokenListBySymbol.POLYGON_MAINNET['WBTC']],
        [dexchanges_1.Dexchanges.Apeswap.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH'], TokenListBySymbol.POLYGON_MAINNET['WBTC']],
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['DAI'], TokenListBySymbol.POLYGON_MAINNET['USDC'], TokenListBySymbol.POLYGON_MAINNET['USDT'], TokenListBySymbol.POLYGON_MAINNET['WETH']],
        [dexchanges_1.Dexchanges.Radioshack.name]: [...WETH_ONLY[sdk_1.ChainId.POLYGON_MAINNET], TokenListBySymbol.POLYGON_MAINNET['RADIO'], TokenListBySymbol.POLYGON_MAINNET['SHACK']]
      },
      [sdk_1.ChainId.BSCMAIN]: {
        [dexchanges_1.Dexchanges.Apeswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['USDC'], TokenListBySymbol.BSCMAIN['USDT'], TokenListBySymbol.BSCMAIN['BANANA'], TokenListBySymbol.BSCMAIN['FRM'], TokenListBySymbol.BSCMAIN['FRMX']],
        [dexchanges_1.Dexchanges.Biswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['USDT'], TokenListBySymbol.BSCMAIN['ETH'], TokenListBySymbol.BSCMAIN['BSW']],
        [dexchanges_1.Dexchanges.Babyswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['BABY'], TokenListBySymbol.BSCMAIN['USDT'], TokenListBySymbol.BSCMAIN['USDC'], TokenListBySymbol.BSCMAIN['BUSD']],
        [dexchanges_1.Dexchanges.Pancake.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['CAKE'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['USDT'], TokenListBySymbol.BSCMAIN['BTCB'], TokenListBySymbol.BSCMAIN['ETH'], TokenListBySymbol.BSCMAIN['USDC']],
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['USDT'], TokenListBySymbol.BSCMAIN['ETH']],
        [dexchanges_1.Dexchanges.Radioshack.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['RADIO'], TokenListBySymbol.BSCMAIN['SHACK'], TokenListBySymbol.BSCMAIN['BUSD']],
        [dexchanges_1.Dexchanges.Crowdswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN]]
      },
      [sdk_1.ChainId.BSCTEST]: {
        [dexchanges_1.Dexchanges.Apeswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD']],
        [dexchanges_1.Dexchanges.Biswap.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['ETH']],
        [dexchanges_1.Dexchanges.Pancake.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['BTCB'], TokenListBySymbol.BSCMAIN['ETH']],
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.BSCMAIN], TokenListBySymbol.BSCMAIN['DAI'], TokenListBySymbol.BSCMAIN['BUSD'], TokenListBySymbol.BSCMAIN['ETH']]
      },
      [sdk_1.ChainId.AVALANCHE]: {
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE], TokenListBySymbol.AVALANCHE['DAI.e'], TokenListBySymbol.AVALANCHE['USDC.e'], TokenListBySymbol.AVALANCHE['USDT.e'], TokenListBySymbol.AVALANCHE['WBTC.e']],
        [dexchanges_1.Dexchanges.Sushiswap.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE], TokenListBySymbol.AVALANCHE['DAI.e'], TokenListBySymbol.AVALANCHE['USDC.e'], TokenListBySymbol.AVALANCHE['USDT.e'], TokenListBySymbol.AVALANCHE['MIM'], TokenListBySymbol.AVALANCHE['WETH.e'], TokenListBySymbol.AVALANCHE['WBTC.e'], TokenListBySymbol.AVALANCHE['TIME'], TokenListBySymbol.AVALANCHE['FRAX'], TokenListBySymbol.AVALANCHE['SUSHI.e'], TokenListBySymbol.AVALANCHE['STG']],
        [dexchanges_1.Dexchanges.Radioshack.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE], TokenListBySymbol.AVALANCHE['RADIO'], TokenListBySymbol.AVALANCHE['SHACK']],
        [dexchanges_1.Dexchanges.Pangolin.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE], TokenListBySymbol.AVALANCHE['PNG'], TokenListBySymbol.AVALANCHE['USDT.e'], TokenListBySymbol.AVALANCHE['DAI.e'], TokenListBySymbol.AVALANCHE['USDC.e'], TokenListBySymbol.AVALANCHE['USDC']],
        [dexchanges_1.Dexchanges.Lydia.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE]],
        [dexchanges_1.Dexchanges.ELK.name]: [...WETH_ONLY[sdk_1.ChainId.AVALANCHE], TokenListBySymbol.AVALANCHE['ELK']]
      },
      [sdk_1.ChainId.APEX]: {
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.APEX], TokenListBySymbol.APEX['OMNIA'], TokenListBySymbol.APEX['USDT']]
      },
      [sdk_1.ChainId.ARBITRUM]: {
        [dexchanges_1.Dexchanges.UniswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.ARBITRUM], TokenListBySymbol.ARBITRUM['WETH'], TokenListBySymbol.ARBITRUM['USDT'], TokenListBySymbol.ARBITRUM['USDC'], TokenListBySymbol.ARBITRUM['DAI']],
        [dexchanges_1.Dexchanges.UniswapV3.name]: [...WETH_ONLY[sdk_1.ChainId.ARBITRUM], TokenListBySymbol.ARBITRUM['WETH'], TokenListBySymbol.ARBITRUM['USDT'], TokenListBySymbol.ARBITRUM['USDC'], TokenListBySymbol.ARBITRUM['DAI']],
        [dexchanges_1.Dexchanges.Sushiswap.name]: [...WETH_ONLY[sdk_1.ChainId.ARBITRUM], TokenListBySymbol.ARBITRUM['WETH'], TokenListBySymbol.ARBITRUM['USDT'], TokenListBySymbol.ARBITRUM['USDC'], TokenListBySymbol.ARBITRUM['DAI']],
        [dexchanges_1.Dexchanges.Crowdswap.name]: [...WETH_ONLY[sdk_1.ChainId.ARBITRUM]],
        [dexchanges_1.Dexchanges.CrowdswapV2.name]: [...WETH_ONLY[sdk_1.ChainId.ARBITRUM], TokenListBySymbol.ARBITRUM['CROWD'], TokenListBySymbol.ARBITRUM['ARB']]
      },
      [sdk_1.ChainId.ZKSYNC]: {
        [dexchanges_1.Dexchanges.SyncSwap.name]: [...WETH_ONLY[sdk_1.ChainId.ZKSYNC], TokenListBySymbol.ZKSYNC['USDC']],
        [dexchanges_1.Dexchanges.Crowdswap.name]: [...WETH_ONLY[sdk_1.ChainId.ZKSYNC], TokenListBySymbol.ZKSYNC['USDC']]
      },
      [sdk_1.ChainId.OPTIMISM]: {
        [dexchanges_1.Dexchanges.Kyber.name]: [...WETH_ONLY[sdk_1.ChainId.OPTIMISM], TokenListBySymbol.OPTIMISM['USDC']],
        [dexchanges_1.Dexchanges.CrowdswapV2.name]: [TokenListBySymbol.OPTIMISM['CROWD'], TokenListBySymbol.OPTIMISM['OP']],
        [dexchanges_1.Dexchanges.UniswapV3.name]: [...WETH_ONLY[sdk_1.ChainId.OPTIMISM], TokenListBySymbol.OPTIMISM['WETH'], TokenListBySymbol.OPTIMISM['USDT'], TokenListBySymbol.OPTIMISM['USDC'], TokenListBySymbol.OPTIMISM['DAI']]
      },
      [sdk_1.ChainId.LINEA]: {
        [dexchanges_1.Dexchanges.Kyber.name]: [...WETH_ONLY[sdk_1.ChainId.LINEA], TokenListBySymbol.LINEA['USDC']]
      },
      [sdk_1.ChainId.BASE]: {
        [dexchanges_1.Dexchanges.Kyber.name]: [...WETH_ONLY[sdk_1.ChainId.BASE], TokenListBySymbol.BASE['USDC']]
      },
      [sdk_1.ChainId.DEFI]: {
        [dexchanges_1.Dexchanges.Vanilla.name]: [...WETH_ONLY[sdk_1.ChainId.DEFI], TokenListBySymbol.DEFI['dUSDC']],
        [dexchanges_1.Dexchanges.CrowdswapV2.name]: []
      }
    };
  }
  getWrappedToken(token) {
    if (sdk_1.ETH[token.chainId].address === token.address) {
      const _token = sdk_1.WETH[token.chainId];
      return new crowd_token_1.CrowdToken(_token.chainId, (0, address_1.getAddress)(_token.address), _token.decimals, _token.symbol, _token.name);
    }
    return token;
  }
  getUnwrappedToken(token) {
    if (sdk_1.WETH[token.chainId].address === token.address) {
      const _token = sdk_1.ETH[token.chainId];
      return new crowd_token_1.CrowdToken(_token.chainId, (0, address_1.getAddress)(_token.address), _token.decimals, _token.symbol, _token.name);
    }
    return token;
  }
  convertSymbolToToken(chainId, symbol) {
    return this.TokenListBySymbol[networks_1.Networks[chainId]][symbol];
  }
  convertAddressToToken(chainId, address) {
    return this.TokenListByAddress[networks_1.Networks[chainId]][address];
  }
  isWrapperToken(chainId, address) {
    return sdk_1.WETH[chainId].address?.toLowerCase() === address?.toLowerCase();
  }
  isBaseToken(chainId, address) {
    return sdk_1.ETH[chainId].address?.toLowerCase() === address?.toLowerCase();
  }
  tokenType(token) {
    if (token.trending) {
      return token_type_1.TokenType.Trending;
    }
    return token_type_1.TokenType.None;
  }
}
exports.default = new TokensHolder();
