import { Injectable } from '@angular/core';
import {
  CrowdToken,
  TokensHolder,
  MainNetworksById,
  NetworksById,
} from '@crowdswap/constant';
import { environment } from '../../environments/environment';
import { BigNumber } from 'ethers';
import { Web3Service } from './web3.service';
import { TokensService } from './tokens.service';

@Injectable()
export class UtilsService {
  constructor(
    public web3Service: Web3Service,
    private tokensService: TokensService
  ) {}

  public static nextRewardTime(stakingTime) {
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    let timeDifference =
      3600 - (Math.floor(Math.floor(Date.now() / 1000) - stakingTime) % 3600);

    const minutesToNextReward = Math.floor(
      (timeDifference / minutesInAnHour) % secondsInAMinute
    );
    const secondsToNextReward = Math.floor(timeDifference) % secondsInAMinute;

    return { secondsToNextReward, minutesToNextReward };
  }

  public static getRemainingTime(futureTime, currentTime) {
    const hoursInDay = 24;
    const minutesInHour = 60;
    const secondsInMinute = 60;
    let diff = futureTime - currentTime;

    const daysLeft = Math.floor(
      diff / secondsInMinute / minutesInHour / hoursInDay
    );
    diff -= daysLeft * secondsInMinute * minutesInHour * hoursInDay;

    const hoursLeft = Math.floor(diff / secondsInMinute / minutesInHour);
    diff -= hoursLeft * secondsInMinute * minutesInHour;

    const minutesLeft = Math.floor(diff / secondsInMinute);
    diff -= minutesLeft * secondsInMinute;

    const secondsLeft = Math.floor(diff);

    return { daysLeft, hoursLeft, minutesLeft, secondsLeft };
  }

  public static getURLCategory(): string | undefined {
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    if (queries.length > 2) {
      return queries[1].toLowerCase();
    }
    return undefined;
  }

  public static getOpportunityURL(): string | undefined {
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    if (queries.length > 2) {
      return queries[2].toLowerCase();
    }
    return undefined;
  }

  public static getQueryVariable(field: string): string | undefined {
    field = field.toLowerCase();
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    for (let i = 0; i < queries.length; i++) {
      if (queries[i].toLowerCase() == field) {
        return queries[i + 1];
      }
    }
    return undefined;
  }

  static getCurrentPage(): string {
    return window.location.pathname.substring(1);
  }

  static isValidChainId(chainId: number): boolean {
    return environment.production
      ? MainNetworksById.hasOwnProperty(chainId)
      : NetworksById.hasOwnProperty(chainId);
  }

  /*
   * Here swap refers to both normal swap and crossSwap.
   * This function returns tokens info from url if exists.
   * */
  static getSwapInfoFromUrl(): (CrowdToken | undefined)[] {
    let fromToken: CrowdToken | undefined = undefined;
    let toToken: CrowdToken | undefined = undefined;
    const fromChainIdInString: string | undefined =
      UtilsService.getQueryVariable('from_chain_id');
    const fromChainId: number = Number(fromChainIdInString);
    const fromTokenSymbol: string =
      UtilsService.getQueryVariable('from_token_symbol') ?? '-';
    if (
      !Number.isNaN(fromChainId) &&
      this.isValidChainId(fromChainId) &&
      NetworksById[fromChainId]
    ) {
      const token =
        TokensHolder.TokenListBySymbol[NetworksById[fromChainId]][
          fromTokenSymbol
        ];
      if (token) {
        fromToken = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      }
    }

    const toChainIdInString: string | undefined =
      UtilsService.getQueryVariable('to_chain_id');
    const toChainId: number = Number(toChainIdInString);
    const toTokenSymbol: string =
      UtilsService.getQueryVariable('to_token_symbol') ?? '-';

    if (
      !Number.isNaN(toChainId) &&
      this.isValidChainId(toChainId) &&
      NetworksById[toChainId]
    ) {
      const token =
        TokensHolder.TokenListBySymbol[NetworksById[toChainId]][toTokenSymbol];
      if (token) {
        toToken = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      }
    }
    return [fromToken, toToken];
  }

  static _clearQueryString() {
    const yourCurrentUrl = window.location.href.split('?')[0];
    window.history.replaceState({}, '', yourCurrentUrl);
  }

  public static deductLimitMargin(
    value: string,
    limitMarginPercent: number
  ): BigNumber {
    if (limitMarginPercent > 100) {
      limitMarginPercent = 100;
    }

    const marginAmount = BigNumber.from(value)
      .mul(BigNumber.from(limitMarginPercent * 100))
      .div(BigNumber.from(10000));

    return BigNumber.from(value).sub(marginAmount);
  }
}

export enum OpportunityState {
  Init,
  WalletNotConnected,
  ApprovalConfirmed,
  InsufficientTokenBBalance,
  InsufficientTokenABalance,
  AmountInIsTooLow,
  WrongNetwork,
  Confirmed,
  Rejected,
  ApprovalNeeded,
  ApprovalRejected,
  Failed,
  Successful,
}

export const OpportunityStateName = {
  0: 'Init',
  1: 'WalletNotConnected',
  2: 'ApprovalConfirmed',
  3: 'InsufficientSrcBalance',
  4: 'InsufficientDestBalance',
  5: 'AmountInIsTooLow',
  6: 'WrongNetwork',
  7: 'Confirmed',
  8: 'Rejected',
  9: 'ApprovalNeeded',
  10: 'ApprovalRejected',
  11: 'Failed',
  12: 'Successful',
};

export enum CrossChainState {
  SrcInit,
  SrcFailed,
  SrcSuccessful,
  DstInit,
  DstWarning,
  DstWarningCatch,
  DstFailed,
  DstSuccessful,
}

export const CrossChainStateName = {
  0: 'SrcInit',
  1: 'SrcFailed',
  2: 'SrcSuccessful',
  3: 'DstInit',
  4: 'DstWarning',
  5: 'DstFailed',
  6: 'DstSuccessful',
};
