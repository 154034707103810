import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { BannerPrivateSaleComponent } from './views/pages/private-sale/banner-private-sale/banner-private-sale.component';
import { DetailsPrivateSaleComponent } from './views/pages/private-sale/details-private-sale/details-private-sale.component';
import { InvestPrivateSaleComponent } from './views/pages/private-sale/invest-private-sale/invest-private-sale.component';

import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DebridgeService,
  NetworksService,
  PrivateSaleService,
  TokensService,
  UtilsService,
  Web3Service,
} from './services';
import { FormPrivateSaleComponent } from './views/pages/private-sale/form-private-sale/form-private-sale.component';
import { RegExpDirective } from './directives/reg-exp.directive';
import { MulticallService, PriceService } from '@crowdswap/constant';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { FocusAndSelectValueDirective } from './directives/FocusAndSelectValue.directive';
import { MainComponent } from './views/pages/main/main.component';
import { SwiperModule } from 'swiper/angular';
import { HeaderPrivateSaleComponent } from './views/pages/private-sale/header-private-sale/header-private-sale.component';
import { environment } from '../environments/environment';
import { InitializeService } from './services/initialize.service';
import { OpportunitiesService } from './services/opportunities.service';

function initializeAppFactory(
  initializeService: InitializeService
): () => Promise<void> {
  return async () => {
    await initializeService.Initialize();
  };
}

PriceService.PRICE_URL = environment.PriceUrl;

export const declarations = [
  AppComponent,
  HeaderComponent,
  BannerPrivateSaleComponent,
  DetailsPrivateSaleComponent,
  FormPrivateSaleComponent,
  InvestPrivateSaleComponent,
  RegExpDirective,
  FocusAndSelectValueDirective,
  MainComponent,
  HeaderPrivateSaleComponent,
];

export const imports = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  FormsModule,
  CommonModule,
  NgxMaskDirective,
  NgxMaskPipe,
  BrowserAnimationsModule,
  ToastrModule.forRoot(),
  SwiperModule,
];

export const providers = [
  Web3Service,
  NetworksService,
  { provide: PriceService, useValue: new PriceService() },
  MulticallService,
  UtilsService,
  TokensService,
  PrivateSaleService,
  DebridgeService,
  InitializeService,
  OpportunitiesService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [InitializeService],
    multi: true,
  },
];

@NgModule({
  declarations: [declarations],
  imports: [imports],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
