<div class="container" style="background: transparent">
  <app-header-private-sale></app-header-private-sale>
  <div *ngIf="!showWaiting">
    <div
      class="flex text-sm font-mundial-demibold justify-content-center items-center"
    >
      <div class="flex text-xs space-x-4 mb-3 mr-4">
        <div class="blue-text-ACDCE1">Connect Wallet</div>
        <div class="blue-text-00B5D0">Buy Token</div>
        <div class="blue-text-ACDCE1">Well Done</div>
      </div>
    </div>
    <div class="flex justify-content-center">
      <div class="position-relative d-flex flex-column width-set">
        <div class="main-box rounded-xl">
          <div class="details space-y-4">
            <div class="left-panel">
              <h2>Choose a token to pay</h2>
              <div
                class="flex align-items-center rounded-full border-2 border-C2E9F1 py-2 pr-3 mt-3 pl-2 w-full"
              >
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 18 18"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>BB6A6DA6-4619-4FF0-AB89-86075C001114</title>
                  <g
                    id="Select-Token"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Select-a-token"
                      transform="translate(-474.000000, -179.000000)"
                      fill="#000000"
                    >
                      <g
                        id="Group-4"
                        transform="translate(441.000000, 98.000000)"
                      >
                        <g
                          id="Text-Field/_Resource/Box"
                          transform="translate(20.000000, 64.000000)"
                        >
                          <g
                            id="Icon/Search/Oultine"
                            transform="translate(13.666667, 17.666667)"
                          >
                            <path
                              d="M7.08333333,0 C10.9953503,0 14.1666667,3.17131636 14.1666667,7.08333333 C14.1666667,8.74182968 13.5966768,10.2671978 12.6419041,11.4742305 L16.7079314,15.5344799 C16.9684971,15.7946131 16.9688478,16.2167229 16.7087146,16.4772887 L16.4732083,16.7131867 C16.2130751,16.9737524 15.7909653,16.9741031 15.5303996,16.7139699 L11.4616951,12.6517969 C10.2566449,13.6006127 8.73609359,14.1666667 7.08333333,14.1666667 C3.17131636,14.1666667 0,10.9953503 0,7.08333333 C0,3.17131636 3.17131636,0 7.08333333,0 Z M7.08333333,1.66666667 C4.09179094,1.66666667 1.66666667,4.09179094 1.66666667,7.08333333 C1.66666667,10.0748757 4.09179094,12.5 7.08333333,12.5 C10.0748757,12.5 12.5,10.0748757 12.5,7.08333333 C12.5,4.09179094 10.0748757,1.66666667 7.08333333,1.66666667 Z"
                              id="Combined-Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <input
                  id="txtSearchTokenInput"
                  class="w-full focus:outline-none pl-1 dark:bg-1E1E1E dark:text-textWhite"
                  (click)="$event.stopPropagation()"
                  (ngModelChange)="filterDataSourceValue($event)"
                  [(ngModel)]="filterValue"
                  (keydown.escape)="clearSearch()"
                  placeholder="Search name or paste address"
                  appAutofocus
                />
                <div class="dark:text-textWhite">
                  <svg
                    *ngIf="filterValue"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    (click)="clearSearch()"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </div>

              <div class="select-option w-full">
                <div
                  *ngFor="let item of tokensTemp"
                  [ngClass]="{ 'animate-pulse': loading }"
                  class="cursor-pointer rounded-md py-2"
                >
                  <div
                    (click)="selectToken(item)"
                    class="select-list token-box border hover:border-blue-400 flex"
                    ngClass="{{
                      item === investToken && isTokenSelected
                        ? 'border-blue-500 border-2'
                        : 'border-1 border-C2E9F1'
                    }}"
                  >
                    <div
                      class="p-2 d-flex col-4 col-md-3 align-items-center cursor-pointer dark:bg-1E1E1E dark:border-1E1E1E dark:border-0"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center ml-10px me-sm-3"
                      >
                        <div *ngIf="!loading" class="token-symbol">
                          <img
                            alt="fromToken"
                            class="rounded-full"
                            width="20"
                            [src]="
                              baseLogoIconsUrl +
                              item.symbol?.toLowerCase() +
                              '.png'
                            "
                            (error)="updateUrl($event)"
                          />
                        </div>
                        <div class="d-flex flex-column">
                          <span
                            class="font-12 font-xs-16 font-openSans-bold text-000 dark:text-fff"
                            [ngClass]="{
                              'bg-gray-300 text-gray-300 rounded-sm': loading
                            }"
                          >
                            {{ item.symbol }}
                          </span>
                          <div
                            *ngIf="!loading"
                            class="d-flex align-items-center"
                          >
                            <div
                              class="rounded-full flex-shrink-0 p-1px bg-fff overflow-hidden"
                            >
                              <img
                                alt="fromChain"
                                [src]="
                                  web3Service.networkSpec[item.chainId].coinIcon
                                "
                                (error)="updateUrl($event)"
                                width="14"
                              />
                            </div>

                            <span
                              class="font-10 font-sm-12 text-000 dark:text-fff ml-1 flex-shrink-0"
                              [ngClass]="{
                                'bg-gray-300 text-gray-300 rounded-sm': loading
                              }"
                            >
                              {{ web3Service.networkSpec[item.chainId].title }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vl flex itemx-center"></div>

                    <div
                      class="balance col-8 col-md-9 pl-1 pr-3 text-sm text-gray-500"
                    >
                      <div class="flex">
                        <div class="d-none d-md-block">
                          {{ investToken === item ? "Min:" : "Required:" }}
                          <span
                            class="font-allroundgothic-medium blue-text-00B5D0"
                            *ngIf="investToken === item"
                            (click)="setRequiredAmount(item)"
                            >{{ item.requiredAmount }}</span
                          >
                        </div>
                        <div *ngIf="investToken === item" class="right-align">
                          Balance:&nbsp;<span
                            [ngClass]="{
                              'bg-gray-300 text-gray-300 rounded-sm': loading
                            }"
                            >{{ item?.balanceToDisplay }}</span
                          >
                          <span
                            class="font-allroundgothic-medium blue-text-00B5D0"
                            (click)="setMax(item)"
                            >&nbsp;Max</span
                          >
                        </div>
                      </div>
                      <input
                        [ngClass]="{
                          'bg-gray-300 text-gray-300 rounded-sm': loading
                        }"
                        [(ngModel)]="item.value"
                        class="font-30 font-allroundgothic-medium focus:border-none focus:outline-none text-black"
                        placeholder="123456"
                      />
                      <div
                        [ngClass]="{
                          'bg-gray-300 text-gray-300 rounded-sm': loading
                        }"
                      >
                        ~${{ item.value * item.price }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3">
            <button
              *ngIf="
                !buttonLoading &&
                privateSaleStatus === PrivateSaleStatus.running
              "
              (click)="
                !didSign
                  ? sign()
                  : isAllowanceNeeded
                  ? getApprove(investToken)
                  : confirm()
              "
              class="font-allroundgothic-medium flex mt-3 text-sm text-center rounded-full w-full"
              [ngClass]="
                !didSign ||
                (isTokenSelected &&
                  +investToken.value <= +investToken.balance &&
                  +investToken.value >= +investToken.requiredAmount)
                  ? 'btn cursor-pointer'
                  : 'btn-disabled pointer-events-none cursor-default'
              "
            >
              {{
                !didSign
                  ? "SIGN"
                  : isAllowanceNeeded
                  ? "APPROVE"
                  : isTokenSelected
                  ? +investToken.value > +investToken.balance
                    ? "INSUFFICIENT BALANCE"
                    : +investToken.value < +investToken.requiredAmount
                    ? "LESS THAN REQUIRED AMOUNT"
                    : "INVEST"
                  : "SELECT A TOKEN TO PAY BY"
              }}
            </button>
            <button
              *ngIf="
                buttonLoading || privateSaleStatus !== PrivateSaleStatus.running
              "
              class="font-allroundgothic-medium flex items-center btn-disabled pointer-events-none cursor-default mt-3 text-sm text-center rounded-full w-full"
            >
              <div class="flex justify-content-center items-center">
                <div class="mr-2">
                  {{
                    buttonLoading
                      ? "PLEASE WAIT"
                      : privateSaleStatus === PrivateSaleStatus.finished
                      ? "FINISHED"
                      : "NOT STARTED"
                  }}
                </div>
                <div
                  *ngIf="buttonLoading"
                  class="flex justify-center items-center"
                >
                  <div class="loader-container">
                    <div
                      class="loader ease-linear rounded-full border-4 border-t-4 h-4 w-4 border-gray-200"
                    ></div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="right">
          <div class="container">
            <div class="font-mundial-demibold">
              You are going to invest
              {{
                investToken && investToken.value && investToken.price
                  ? "~$" + +investToken.value * +investToken.price
                  : ""
              }}
              in Amates private sale token for 6 months
            </div>
            <div>
              <div class="text-sm">Minimum Investment</div>
              <div class="highlight">
                ${{ privateSaleService.poolDetail?.minInvest }}
              </div>
            </div>
            <div>
              <div class="text-sm">Accepted Tokens</div>
              <div class="highlight">Any token, any chain</div>
            </div>
            <div class="bg-gray-50">
              <div>
                <div class="text-sm">Private sale token price</div>
                <div class="highlight">
                  ${{ privateSaleService.poolDetail?.priceStages[0][1] }}
                </div>
              </div>
              <div>
                <div class="text-sm">Invested Amount</div>
                <div class="highlight">
                  {{ privateSaleService.poolDetail?.investedAmountToDisplay }}
                </div>
              </div>
              <div>
                <div class="text-sm">Round Ends</div>
                <div class="highlight gradient-text">
                  {{ finishTimeRemain.daysLeft }}D
                  {{ finishTimeRemain.hoursLeft }}H
                  {{ finishTimeRemain.minutesLeft }}M
                  {{ finishTimeRemain.secondsLeft }}S
                </div>
                <div class="text-sm">
                  {{ finishTime * 1000 | date }}
                </div>
              </div>
            </div>

            <div class="bg-gray-50 mt-2 rounded-md flex text-white">
              <div
                id="stage"
                class="percent-gradient rounded-md"
                [style.width.%]="privateSaleService.poolDetail?.remainPercent"
              >
                {{
                  privateSaleService.poolDetail?.remainPercent > 50
                    ? privateSaleService.poolDetail?.remainPercent + "% Remains"
                    : ""
                }}
              </div>
              <span class="text-black ml-3">{{
                privateSaleService.poolDetail?.remainPercent > 50
                  ? ""
                  : privateSaleService.poolDetail?.remainPercent + "% Remains"
              }}</span>
            </div>
          </div>
          <div *ngIf="+userDetail.balance" class="mt-3">
            <button
              (click)="showDetails(PageStatus.DETAILS)"
              class="font-allroundgothic-medium btn-secondary cursor-pointer flex text-sm text-center rounded-full w-full"
            >
              DASHBOARD
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showWaiting">
    <div
      class="flex text-sm font-mundial-demibold justify-content-center items-center"
    >
      <div class="flex text-xs space-x-4 mb-3 mr-4">
        <div class="blue-text-ACDCE1">Connect Wallet</div>
        <div class="blue-text-00B5D0">Buy Token</div>
        <div class="blue-text-ACDCE1">Well Done</div>
      </div>
    </div>
    <div class="main-box p-3 rounded-xl">
      <div class="space-y-4">
        <div class="d-flex justify-content-center">
          <div class="flex justify-content-center flex-shrink-0">
            <div class="flex justify-center">
              <div class="mb-2 loader-container">
                <div
                  class="loader ease-linear rounded-full border-4 border-t-4 h-24 w-24 border-gray-200"
                ></div>
                <div class="loading-text flex justify-center">
                  <img src="assets/media/icons/logo/amates-icon.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <div class="text-lg font-allroundgothic-semi">
              Authorize payment in your wallet.
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <div class="text-xs">
              Paying {{ investToken.value }} {{ investToken.symbol }} for
            </div>
          </div>
        </div>
        <div class="d-flex font-openSans-bold justify-content-center">
          <div>
            <div class="text-xs">Amates Private Sale</div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div>
            <div class="font-allroundgothic-medium">
              We will notify you when your payment is finished
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
