<div class="w-full flex h-68 bg-white justify-content-center fixed">
  <div
    class="container-fluid p-3 fixed px-lt-24px bg-white items-center top-0 header-zIndex"
  >
    <div class="d-flex w-full justify-content-between items-center">
      <div class="flex text-sm items-center space-x-2">
        <div class="d-block d-sm-none dropdown">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="6" width="16" height="2" rx="1" fill="#00B5D0" />
            <rect x="4" y="11" width="16" height="2" rx="1" fill="#00B5D0" />
            <rect x="4" y="16" width="16" height="2" rx="1" fill="#00B5D0" />
          </svg>
          <div class="dropdown-content z-1">
            <a href="https://amates.ai/#vision" target="_blank">VISION</a>
            <a href="https://amates.ai/#usecases" target="_blank">USE CASES</a>
            <a href="https://amates.ai/#faq" target="_blank">FAQ</a>
            <a href="https://amates.ai/#roadmap" target="_blank">ROADMAP</a>
          </div>
        </div>
        <div>
          <img
            style="height: 29px"
            id="logo-image"
            class="d-none d-md-block"
            alt="CrowdSwap"
            src="assets/media/icons/logo/automates-header-logo.png"
          />
          <img
            style="height: 29px"
            id="logo-image-2"
            class="d-block d-md-none"
            alt="CrowdSwap"
            src="assets/media/icons/logo/amates-icon.png"
          />
        </div>
      </div>

      <div
        class="d-none d-sm-block pointer-md-none flex text-sm font-mundial-demibold items-center"
      >
        <div class="flex space-x-3 mr-4">
          <a href="https://amates.ai/#vision" target="_blank">VISION</a>
          <a href="https://amates.ai/#usecases" target="_blank">USE CASES</a>
          <a href="https://amates.ai/#faq" target="_blank">FAQ</a>
          <a href="https://amates.ai/#roadmap" target="_blank">ROADMAP</a>
        </div>
      </div>

      <div class="flex text-sm items-center">
        <div *ngIf="isUserWalletConnected" class="mr-2">
          <a (click)="disconnect()" class="btn py-2 px-2 rounded-full">
            DISCONNECT
          </a>
        </div>
        <div>
          <a
            href="https://amates.ai/"
            target="_blank"
            class="btn py-2 px-2 rounded-full"
            >REGISTER</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
