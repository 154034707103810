import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  commonConfig,
  Conversion,
  MainNetworksById,
  Networks,
  NetworksById,
  OpportunitiesHolder,
  OpportunityInvestmentType,
  PriceService,
  TokensHolder,
  UNUSUAL_MODE_PERCENTAGE,
} from '@crowdswap/constant';
import { Constants } from '../../../../constants';
import { BigNumber } from 'ethers';
import { PendingTransactionModel } from '../../../../model';
import {
  CrossChainState,
  CrossChainStateName,
  DebridgeService,
  ERC20Service,
  LoggingService,
  NDDClientInfoServiceImpl,
  OpportunityState,
  OpportunityStateName,
  PrivateSaleService,
  TokensService,
  UtilsService,
  Web3Service,
} from '../../../../services';
import { TransactionResponse } from '@ethersproject/providers';
import { Router } from '@angular/router';
import { AddressZero } from '@ethersproject/constants';
import { BaseComponent, PageStatus } from '../../base.component';
import { environment } from '../../../../../environments/environment';
import { isAddress } from '@ethersproject/address';
import { AmatesToken } from '../../../../services/tokens/amates-token';

export enum LPState {
  srcToken,
  destToken,
  globalToken,
}

export enum ApproveState {
  Init,
  token,
  srcToken,
}

@Component({
  selector: 'app-invest-private-sale',
  templateUrl: './invest-private-sale.component.html',
  styleUrls: ['./invest-private-sale.component.scss'],
})
export class InvestPrivateSaleComponent
  extends BaseComponent
  implements OnInit
{
  @Output()
  changePage = new EventEmitter();

  public _userDetail: any;

  @Input()
  set userDetail(opportunity: any) {
    this._userDetail = opportunity;
  }

  get userDetail() {
    return this._userDetail;
  }

  public dataSource = [
    {
      state: 'Invest',
      totalInvest: 0,
      totalIn: 0,
      totalOut: 0,
    },
  ];
  public didRegistry: boolean = false;
  public didSign: boolean = false;
  public fractalId = 123;
  public showWaiting: boolean = false;
  public investmentAmount = '111111111111';
  public investTx: any;
  public loading: boolean = true;
  public buttonLoading: boolean = false;

  public disableConfirm: boolean = true;

  public InvestmentType = OpportunityInvestmentType;
  public PageStatus = PageStatus;
  public investmentType: OpportunityInvestmentType =
    OpportunityInvestmentType.ByToken;
  public opportunityState: OpportunityState = OpportunityState.Init;
  public crossChainState: CrossChainState = CrossChainState.SrcInit;
  public approveState: ApproveState = ApproveState.Init;

  public pendingTxList: PendingTransactionModel[] = [];
  public blockCrossChain: boolean = false;
  public fallbackToken!: AmatesToken;
  public investToken!: AmatesToken;
  public investAmountInUSDT: number = 0;

  private opportunities;
  private confirmationInterval: ReturnType<typeof setInterval> | undefined =
    undefined;
  private defaultGas = {
    MAINNET: {
      protocolFee: 0.001,
      price: 101000000000,
      cost: 710000,
    },
    BSCMAIN: {
      protocolFee: 0.005,
      price: 5000000000,
      cost: 1600000,
    },
    POLYGON_MAINNET: {
      protocolFee: 0.5,
      price: 56000000000,
      cost: 2400000,
    },
    AVALANCHE: {
      protocolFee: 0.01,
      price: 100000000000,
      cost: 2600000,
    },
  };

  static PRICE_FACTOR: number = 1000000;
  public isTokenSelected: boolean = false;
  public tokensTemp: any[] = [
    {
      decimals: 18,
      symbol: 'BNB',
      name: 'Binance Coin',
      chainId: 56,
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      price: '111111',
      balance: '0.009696850150929235',
      value: 0,
      hasCustomPrice: false,
      type: 0,
      balanceToDisplay: '0.00',
    },
    {
      decimals: 18,
      symbol: 'BNB',
      name: 'Binance Coin',
      chainId: 56,
      address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      price: '111111',
      balance: '0.009696850150929235',
      value: 0,
      hasCustomPrice: false,
      type: 0,
      balanceToDisplay: '0.00',
    },
  ];

  public filterValue: string = '';
  private allTokens;
  private isNewToken: boolean = false;
  private newToken;
  public isAllowanceNeeded: boolean = false;

  constructor(
    public web3Service: Web3Service,
    public privateSaleService: PrivateSaleService,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private priceService: PriceService,
    private debridgeService: DebridgeService,
    private utilsService: UtilsService,
    private logger: LoggingService,
    private router: Router,
    private tokensService: TokensService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, privateSaleService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    await super.ngOnInit();
    await this.initialize();

    this.opportunities = OpportunitiesHolder.OpportunitiesData;

    this.web3Service.wrongNetworkSubject.subscribe(
      async (isWrongNetwork: boolean) => {
        if (isWrongNetwork) {
          this.opportunityState = OpportunityState.WrongNetwork;
          this.isWrongNetwork = true;
          this.ref.detectChanges();
        }
      }
    );

    this.web3Service.accountChangeSubject.subscribe(async () => {
      await this.initialize();
      await this.clearSearch();
    });
    this.ref.detectChanges();
  }

  private async initialize() {
    this.loading = true;
    this.isTokenSelected = false;
    await this.checkKyc();
    this.tokensTemp = await this.getTokens();
    await this.normalizeTokenList(this.tokensTemp);

    this.allTokens = this.tokensTemp;
  }

  private async getTokens() {
    const tokens = this.tokensService.getAllTokens();
    this.tokensTemp = await this.addBalanceToTokens(tokens);
    await this.priceService.CachePricesForANetwork();
    return await this.addPriceToTokens(this.tokensTemp);
  }

  async checkKyc() {
    // this.didRegistry = await this.privateSaleService.didRegistry(
    //   this.privateSaleService.activePresale,
    //   await this.web3Service.getWalletAddress()
    // );
    this.didSign = false;
    this.didSign = await this.privateSaleService.didSign(
      this.privateSaleService.activePresale.presaleName
    );
  }

  public async addBalanceToTokens(tokens: any) {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: AmatesToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        result.push(...item.value);
      }
    });
    return result;
  }

  public async normalizeTokenList(tokenList: any[] | undefined) {
    if (!tokenList || tokenList.length === 0) {
      this.tokensTemp = [];
      this.loading = false;
      return;
    }

    this.tokensTemp.map((token) => {
      token.requiredAmount = this.calculateRequiredAmount(token);
      token.value = token.requiredAmount;
    });
    this.tokensTemp = tokenList.filter(
      (token) =>
        parseFloat(token.balance) !== 0 &&
        token.value &&
        parseFloat(token.value) < parseFloat(token.balance)
    );
    this.loading = false;
  }

  async getApprove(token: AmatesToken) {
    if (this.web3Service.getCurrentChainId() !== this.investToken.chainId) {
      await this.changeNetworkTo(this.investToken.chainId);
    }
    this.buttonLoading = true;
    try {
      this.logger.info(
        `Start getting approve for token [${token.symbol}], opportunity name = [${this.privateSaleService.activePresale.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }

      // Get approval tx
      let contractAddress = Constants.DEBRIDGE_ADDRESS;
      if (
        this.investToken.chainId ===
        this.privateSaleService.activePresale.chainId
      ) {
        contractAddress =
          this.opportunities.PRESALE.networks[
            this.web3Service.getCurrentChainId()
          ].contractAddress;
      }
      let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
        contractAddress,
        token.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      );
      // Send approval tx
      approvalTx = await this.web3Service
        .sendTransaction(approvalTx)
        .catch((error) => {
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          this.showErrorToaster('Error', 'Approval rejected!');
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            OpportunityState.ApprovalNeeded,
            'Approval transaction is not submitted',
            error
          );
        });
      if (!approvalTx) {
        this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Something went wrong!');
        this.setConsoleLog(
          this.privateSaleService.activePresale,
          OpportunityState.ApprovalNeeded,
          'Approval transaction is empty'
        );
        return;
      }
      this.setConsoleLog(
        this.privateSaleService.activePresale,
        OpportunityState.ApprovalNeeded,
        'Approval transaction is submitted'
      );
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(approvalTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            this.isAllowanceNeeded = false;
            this.changeOpportunityState(OpportunityState.ApprovalConfirmed);

            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.ApprovalConfirmed,
              'Approval transaction is confirmed'
            );
          } else {
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeOpportunityState(OpportunityState.ApprovalNeeded);

            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.ApprovalNeeded,
              'Approval transaction is not confirmed'
            );
          }
        })
        .catch((error) => {
          this.showErrorToaster('Error', 'Something went wrong!');
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            OpportunityState.ApprovalNeeded,
            'Approval transaction is not confirmed',
            error
          );
        });
      this.logger.info(
        `Finish getting approve for token [${token.symbol}], opportunity name = [${this.privateSaleService.activePresale.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.changeOpportunityState(OpportunityState.ApprovalNeeded);

      this.setConsoleLog(
        this.privateSaleService.activePresale,
        OpportunityState.ApprovalNeeded,
        'Approval transaction has error',
        error
      );
    } finally {
      this.buttonLoading = false;
    }
  }

  public setConsoleLog(
    opp: any,
    state: OpportunityState,
    msg: string = '',
    error: any = undefined,
    CCState: CrossChainState = CrossChainState.SrcInit
  ) {
    if (error) {
      this.logger.error(
        `Active opportunity= ${opp?.name} OpportunityState= ${
          OpportunityStateName[state]
        } CrossChainState= ${CrossChainStateName[CCState]} ${msg} ${
          'error is ' + JSON.stringify(error)
        }`
      );
    } else {
      this.logger.debug(
        `Active opportunity= ${opp?.name} OpportunityState= ${OpportunityStateName[state]} CrossChainState= ${CrossChainStateName[CCState]} ${msg}`
      );
    }
  }

  public async checkIfAllowanceIsNeeded(): Promise<any> {
    this.isAllowanceNeeded = true;
    try {
      if (this.isCoin(this.investToken)) {
        this.isAllowanceNeeded = false;
        return;
      }
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        let contractAddress = Constants.DEBRIDGE_ADDRESS;
        if (
          this.investToken.chainId ===
          this.privateSaleService.activePresale.chainId
        ) {
          contractAddress =
            this.opportunities.PRESALE.networks[this.investToken.chainId]
              .contractAddress;
        }
        let allowance: BigNumber = await this.web3Service
          .getAllowanceBySpender(
            contractAddress,
            this.investToken.address,
            userAddress,
            this.web3Service.getNetworkProvider(this.investToken.chainId)
          )
          .catch((e) => {
            console.log(e);
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeOpportunityState(OpportunityState.ApprovalRejected);
          });
        let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
          allowance.toString()
        );
        let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
          Conversion.convertStringToDecimal(
            this.investmentAmount,
            this.investToken.decimals
          ).toString()
        );
        if (allowanceValue.lt(amountInValue)) {
          this.isAllowanceNeeded = true;
          this.approveState = ApproveState.token;

          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        } else this.isAllowanceNeeded = false;
      }
    } catch (e) {
      console.log(e);
      this.changeOpportunityState(OpportunityState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  public async confirm() {
    if (this.web3Service.getCurrentChainId() !== this.investToken.chainId) {
      await this.changeNetworkTo(this.investToken.chainId);
    }
    try {
      this.buttonLoading = true;
      this.investTx = undefined;
      this.changeOpportunityState(OpportunityState.Init);
      this.logger.info(
        `Start getting confirm opportunity name = [${this.privateSaleService.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
      let totalPending = 0;
      const pendingList = this.pendingTxList.filter(
        (pending) => pending.sourceTokenSymbol === this.investToken.symbol
      );
      pendingList.forEach((pending) => (totalPending += +pending.amountIn));
      this.investToken.price = await this.priceService.getPrice(
        this.investToken,
        this.web3Service.getNetworkProvider(this.investToken.chainId)
      );

      const wToken =
        this.opportunities.PRESALE.networks[
          this.privateSaleService.activePresale.chainId
        ].wToken;
      if (
        this.investToken.chainId !==
        this.privateSaleService.activePresale.chainId
      ) {
        this.fallbackToken = this.opportunities.PRESALE.networks[
          this.privateSaleService.activePresale.chainId
        ].middleToken
          ? this.opportunities.PRESALE.networks[
              this.privateSaleService.activePresale.chainId
            ].middleToken
          : this.opportunities.PRESALE.networks[
              this.privateSaleService.activePresale.chainId
            ].wToken;

        wToken.price = await this.priceService.getPrice(
          wToken,
          this.web3Service.getNetworkProvider(wToken.chainId)
        );
      }

      this.investTx = await this.privateSaleService.getPresaleTX(
        this.privateSaleService.activePresale,
        this.opportunities.PRESALE.name,
        this.web3Service.getWalletAddress(),
        this.investToken,
        Conversion.convertStringToDecimal(
          this.investToken.value,
          this.investToken.decimals
        ).toString(),
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? PrivateSaleService.OPPORTUNITY_SLIPPAGE
          : PrivateSaleService.SLIPPAGE,
        PrivateSaleService.DEADLINE,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? wToken.price
          : this.investToken.price,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? OpportunityInvestmentType.ByToken
          : this.investmentType
      );
      if (!this.investTx) {
        this.showErrorToaster('Error', 'Something went wrong!');
        this.setConsoleLog(
          this.privateSaleService.activePresale,
          this.opportunityState,
          'Confirmed transaction is empty'
        );
        return;
      }
      this.setConsoleLog(
        this.privateSaleService.activePresale,
        this.opportunityState,
        'Confirmed transaction is received from backend'
      );

      const stage = await this.privateSaleService.getStage(
        this.privateSaleService.activePresale
      );
      const amount = +Conversion.convertStringFromDecimal(
        this.investTx.amount,
        TokensHolder.TokenListByAddress[
          NetworksById[this.privateSaleService.activePresale.chainId]
        ][this.privateSaleService.activePresale.investToken].decimals
      );
      const amountOut = (
        (await this.isMinimumPrice())
          ? amount / this.privateSaleService.poolDetail.priceStages[0][1]
          : amount /
            +Conversion.convertStringFromDecimal(stage[1].toString(), 6)
      )
        .toFixed(4)
        .toString();
      let minAmountOut;
      if (
        this.investToken.chainId !==
        this.privateSaleService.activePresale.chainId
      ) {
        minAmountOut = UtilsService.deductLimitMargin(
          this.investTx.amount.toString(),
          +PrivateSaleService.OPPORTUNITY_SLIPPAGE
        );
      } else {
        if (
          this.investToken.address.toLowerCase() ===
          this.privateSaleService.activePresale.investToken.toLowerCase()
        ) {
          minAmountOut = this.investTx.amount;
        } else {
          minAmountOut = UtilsService.deductLimitMargin(
            this.investTx.amount.toString(),
            +PrivateSaleService.SLIPPAGE
          );
        }
      }
      minAmountOut = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          minAmountOut.toString(),
          TokensHolder.TokenListByAddress[
            NetworksById[this.privateSaleService.activePresale.chainId]
          ][this.privateSaleService.activePresale.investToken].decimals
        )
      );

      const presaleToken = new AmatesToken(
        this.privateSaleService.activePresale.chainId,
        AddressZero,
        this.privateSaleService.activePresale.presaleTokenDecimal,
        this.privateSaleService.activePresale.presaleTokenName,
        this.privateSaleService.activePresale.presaleTokenName,
        (await this.isMinimumPrice())
          ? this.privateSaleService.poolDetail.priceStages[0][1]
          : +Conversion.convertStringFromDecimal(stage[1].toString(), 6)
      );
      const [unusualMode, differenceInPercent, sourceValue, destValue] =
        this.checkUnusualSituation(
          this.investToken,
          presaleToken,
          Conversion.convertStringToDecimal(
            this.investmentAmount,
            this.investToken.decimals
          ),
          Conversion.convertStringToDecimal(
            amountOut,
            this.privateSaleService.activePresale.presaleTokenDecimal
          )
        );
      if (unusualMode) {
        let message =
          `Invest in CrowdSale faced unusualMode,` +
          ` CrowdSaleName: ${this.privateSaleService.activePresale.presaleDisplayName},` +
          ` CrowdSaleToken: ${this.privateSaleService.activePresale.presaleTokenName}(${this.privateSaleService.activePresale.chainId}),` +
          ` CrowdSaleInvestToken: ${
            TokensHolder.TokenListByAddress[
              NetworksById[this.privateSaleService.activePresale.chainId]
            ][this.privateSaleService.activePresale.investToken].symbol
          }(${this.privateSaleService.activePresale.chainId})(${
            this.privateSaleService.activePresale.investToken
          }),` +
          ` token: ${this.investToken.symbol}(${this.investToken.chainId})(${this.investToken.address}),` +
          ` amountIn: ${this.investmentAmount}, amountInInUSD: ${sourceValue},` +
          ` amountOut: ${amountOut}, amountOutInUSD: ${destValue}, minAmountOut: ${minAmountOut},` +
          ` delegatedDex: ${this.investTx.delegatedDex}, route: ${this.investTx.route}`;
        if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
          message =
            message + `, deUSDCAmountOut: ${this.investTx.deUSDCAmountOut}`;
        }
        console.info(message);
      }

      await this.confirmInvest(this.investTx);
      this.logger.info(
        `Finish getting confirm opportunity name = [${this.privateSaleService.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.setConsoleLog(
        this.privateSaleService.activePresale,
        OpportunityState.Init,
        'Confirming transaction is failed',
        error
      );
    } finally {
      this.buttonLoading = false;
    }
  }

  private async isMinimumPrice() {
    if (
      this.currentTime - +this.startTime <
      +this.privateSaleService.poolDetail.firstHoursBestPrice.toString() *
        60 *
        60
    ) {
      return true;
    }
    const userDetail = this.userDetail;
    return userDetail.state === 1;
  }

  async confirmInvest(investTx: any) {
    this.investAmountInUSDT = +this.investToken.price * +this.investToken.value;
    try {
      this.logger.info(
        `Start getting final confirm opportunity name = [${this.privateSaleService.activePresale.presaleName}], opportunity state = [${OpportunityState.Init}], transaction = [${investTx}] `
      );
      if (investTx.value) {
        investTx.value = BigNumber.from(investTx.value);
      }
      const presaleInvestTx = {
        from: investTx.from,
        to: investTx.to,
        data: investTx.data,
        value: investTx.value,
        gas: investTx.gasLimit.hex,
        gasLimit: investTx.gasLimit.hex,
      };

      let currentTransaction: any;
      currentTransaction = await this.web3Service
        .sendTransaction(presaleInvestTx)
        .then(async (data) => {
          this.changeOpportunityState(OpportunityState.Confirmed);
          this.showWaiting = true;
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            this.opportunityState,
            `invest in presale ${this.privateSaleService.activePresale.displayName} submitted`
          );
          const pendingTx: PendingTransactionModel = {
            sourceTokenSymbol: this.investToken.symbol!,
            destTokenSymbol: this.investToken.symbol!,
            amountIn: this.investmentAmount,
            amountOut: this.investmentAmount,
            chainId: +this.privateSaleService.activePresale.chainId,
            hash: data?.hash,
          };
          this.addToPendingTxList(pendingTx);
          this.ref.detectChanges();
          return data;
        })
        .catch(async (error) => {
          this.showErrorToaster('Error', 'Investment rejected!');
          this.showWaiting = false;
          this.changeOpportunityState(OpportunityState.Rejected);
          this.crossChainState = CrossChainState.SrcFailed;
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            OpportunityState.Rejected,
            'Investment rejected!',
            error
          );
        });

      if (currentTransaction) {
        // Wait for swap tx confirmation
        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                this.crossChainState = CrossChainState.SrcSuccessful;
                await this.observeCrossChainSwapStatus(currentTransaction);
              } else {
                this.changeOpportunityState(OpportunityState.Successful);
                this.setConsoleLog(
                  this.privateSaleService.activePresale,
                  this.opportunityState,
                  'Opportunity successful.'
                );
              }
              this.showDetails(PageStatus.SUCCESSFUL_INVESTED);

              await this.privateSaleService.updatePoolDetails(
                this.privateSaleService.activePresale
              );
              this.ref.detectChanges();
            } else {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.SrcFailed;
              this.setConsoleLog(
                this.privateSaleService.activePresale,
                this.opportunityState,
                'Opportunity failed.'
              );
            }
            try {
              let pendingTx = this.findFromPendingList(currentTransaction);
              if (!pendingTx) {
                return;
              }
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                const interval = setInterval(async () => {
                  if (this.confirmationInterval === undefined) {
                    this.removeFromPendingTxList(currentTransaction);
                    clearInterval(interval);
                  }
                }, 1000);
              } else {
                this.removeFromPendingTxList(currentTransaction);
              }
              await this.showToastr(currentTransaction);
            } catch (error) {
              this.setConsoleLog(
                this.privateSaleService.activePresale,
                this.opportunityState,
                'Failed to show toaster or push tag',
                error
              );
            }
            return data;
          })
          .catch((error) => {
            this.changeOpportunityState(OpportunityState.Failed);
            this.removeFromPendingTxList(currentTransaction);
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Failed,
              'Opportunity failed.',
              error
            );
          });
      }
      this.logger.info(
        `Finish getting final confirm opportunity name = [${this.privateSaleService.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      this.setConsoleLog(
        this.privateSaleService.activePresale,
        OpportunityState.Failed,
        'Opportunity failed in external catch.',
        error
      );
    }
  }

  private async observeCrossChainSwapStatus(
    currentTransaction: TransactionResponse
  ) {
    let fallbackTokenInUSDT;
    let retryCount = 0;
    let validatorsCount = 0;
    this.confirmationInterval = setInterval(async () => {
      retryCount++;

      // The submissionInfo is used to get the swap status
      let submissionInfo;
      try {
        submissionInfo = <any>(
          await this.debridgeService.getFullSubmissionInfo(currentTransaction)
        );
      } catch (error) {
        this.setConsoleLog(
          this.privateSaleService.activePresale,
          OpportunityState.Init,
          ' FullSubmissionInfo catch. ',
          error,
          CrossChainState.SrcSuccessful
        );
      }
      if (retryCount >= commonConfig.CROSS_CHAIN.CCLP_TIMEOUT) {
        this.clearConfirmationInterval();
        this.changeOpportunityState(OpportunityState.Failed);
        this.crossChainState = CrossChainState.DstFailed;
        this.setConsoleLog(
          this.privateSaleService.activePresale,
          OpportunityState.Failed,
          ' Timeout after ' + retryCount + ' retry count',
          undefined,
          CrossChainState.DstFailed
        );
        return;
      }
      if (validatorsCount < 12) {
        try {
          if (!submissionInfo?.send?.submissionId) {
            return;
          }

          // Get validation count of the transaction
          validatorsCount = <any>(
            await this.debridgeService.getForSubmission(
              submissionInfo.send.submissionId
            )
          );

          // Get destination token balance while CCLP is in progress
          fallbackTokenInUSDT =
            fallbackTokenInUSDT ||
            Conversion.convertStringToDecimal(
              (
                +this.fallbackToken.balance * +this.fallbackToken.price
              ).toString(),
              this.fallbackToken.decimals
            );

          // There is enough validation or the transaction is sent to the destination network
          if (validatorsCount >= 11 || submissionInfo.send.isExecuted) {
            validatorsCount = 12;
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Init,
              ' Last step with validatorsCount or isExecuted. ',
              undefined,
              CrossChainState.SrcSuccessful
            );
            retryCount = 0;
          }
        } catch (error) {
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            OpportunityState.Failed,
            ' Failed CCLP transaction. ',
            error,
            CrossChainState.DstFailed
          );
        }
      } else {
        if (!submissionInfo.claim) {
          return;
        }
        this.setConsoleLog(
          this.privateSaleService.activePresale,
          OpportunityState.Confirmed,
          ' Got claim and clear interval. ',
          undefined,
          CrossChainState.SrcSuccessful
        );
        this.clearConfirmationInterval();

        try {
          // Wait for claim tx confirmation
          const claimTxResult = await this.web3Service.waitForTransaction(
            submissionInfo.claim.transactionHash,
            1,
            this.web3Service.getNetworkProvider(
              this.privateSaleService.activePresale.chainId
            )
          );
          if (!claimTxResult) {
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Failed,
              ' Failed claimTxResult. ',
              undefined,
              CrossChainState.DstFailed
            );
          }
          if (claimTxResult?.status !== 1) {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstFailed;
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Failed,
              ' Failed last transaction.',
              undefined,
              CrossChainState.DstFailed
            );
            await this.showToastr(currentTransaction);
            return;
          } else {
            this.crossChainState = CrossChainState.DstSuccessful;
          }

          let lastBalanceInUSDT;
          try {
            const wTokenBalance = await this.web3Service.getBalance(
              this.fallbackToken,
              this.web3Service.getNetworkProvider(
                this.privateSaleService.activePresale.chainId
              )
            );
            lastBalanceInUSDT = wTokenBalance!
              .mul((+this.fallbackToken.price * 10000).toFixed())
              .div(10000);
          } catch (error) {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstWarningCatch;
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Failed,
              ' Failed last balance.',
              error,
              CrossChainState.DstFailed
            );
            await this.showToastr(currentTransaction);
            return;
          }
          if (lastBalanceInUSDT) {
            if (
              !lastBalanceInUSDT.lt(
                fallbackTokenInUSDT.add(
                  Conversion.convertStringToDecimal(
                    (+this.investmentAmount / 2).toString(),
                    this.opportunities.PRESALE.networks[
                      this.privateSaleService.activePresale.chainId
                    ].wToken.decimals
                  )
                )
              )
            ) {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.DstWarning;
              this.setConsoleLog(
                this.privateSaleService.activePresale,
                OpportunityState.Failed,
                ' deUSDC received by fallback address.',
                undefined,
                CrossChainState.DstFailed
              );
            } else {
              this.changeOpportunityState(OpportunityState.Successful);
              this.setConsoleLog(
                this.privateSaleService.activePresale,
                OpportunityState.Successful,
                ' Success last transaction. ',
                undefined,
                CrossChainState.DstSuccessful
              );
            }
          } else {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstWarning;
            this.setConsoleLog(
              this.privateSaleService.activePresale,
              OpportunityState.Failed,
              ' Failed last balance.',
              CrossChainState.DstFailed
            );
          }
          await this.showToastr(currentTransaction);
        } catch (error) {
          this.changeOpportunityState(OpportunityState.Failed);
          this.crossChainState = CrossChainState.DstFailed;
          this.setConsoleLog(
            this.privateSaleService.activePresale,
            OpportunityState.Failed,
            ' Failed last transaction in catch.',
            error,
            CrossChainState.DstFailed
          );
        }
      }
    }, 10 * 1000);
  }

  private clearConfirmationInterval() {
    if (this.confirmationInterval) {
      clearInterval(this.confirmationInterval);
      this.confirmationInterval = undefined;
    }
  }

  public async clearSearch() {
    this.filterValue = '';
    await this.filterDataSourceValue('');
  }

  /**
   * Filter data source value
   */
  public async filterDataSourceValue(filterValue) {
    filterValue = filterValue.toLowerCase();
    this.tokensTemp = [];
    if (isAddress(filterValue)) {
      const tokenByAddress = this.getTokenByAddress(filterValue);
      if (!tokenByAddress) {
        this.isNewToken = true;
        this.newToken = await this.fetchNewTokenDetailsByAddress(
          filterValue
        ).catch(() => {
          return {};
        });
      }
    } else {
      this.tokensTemp = this.allTokens.filter((item: any) => {
        return item.symbol.toLowerCase().indexOf(filterValue) >= 0;
      });
      if (this.tokensTemp.length === 0) {
        this.tokensTemp = [];
      }
    }
  }

  async fetchNewTokenDetailsByAddress(address: string) {
    const erc20 = new ERC20Service(this.web3Service.getNetworkProvider(1));
    return await erc20.getToken(address);
  }

  private getTokenByAddress(address: any) {
    for (const token of this.allTokens) {
      if (token.address.toLowerCase() === address) {
        return token;
      }
    }
    return null;
  }

  private async showToastr(currentTransaction: any) {
    const scanTransactionUrl =
      this.web3Service.getScanTransactionUrl(currentTransaction);
    switch (this.opportunityState) {
      case OpportunityState.Successful: {
        this.toastr.success(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          'Transaction successful',
          // `Deposit of ${this.tokenB.symbol} and ${this.tokenA.symbol} ($${this.investmentAmount}) successfully executed!`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'successClass',
          }
        );
        break;
      }
      case OpportunityState.Failed: {
        this.toastr.error(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Transaction failed`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass',
          }
        );
        return;
      }
    }
  }

  private changeOpportunityState(opportunityState: OpportunityState) {
    this.opportunityState = opportunityState;
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass',
    });
  }

  public async getAllFeesForMaxAmountIn(chainId: number) {
    let gasPrice = +Conversion.convertStringFromDecimal(
      (await this.web3Service.getGasPrice()) ||
        this.defaultGas[NetworksById[chainId]].price,
      9
    );
    switch (chainId) {
      case Networks.MAINNET:
        gasPrice = gasPrice * 1.1;
        break;
      case Networks.BSCMAIN:
        gasPrice = gasPrice + 2;
        break;
      case Networks.POLYGON_MAINNET:
        gasPrice = gasPrice * 2;
        break;
      case Networks.AVALANCHE:
        gasPrice = gasPrice * 1.4;
        break;
    }
    return (
      +Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          Math.floor(
            gasPrice * (this.defaultGas[NetworksById[chainId]].cost + 21000)
          ).toString(),
          9
        ),
        6
      ) + this.defaultGas[NetworksById[chainId]].protocolFee
    );
  }

  private addToPendingTxList(pendingTx: PendingTransactionModel) {
    this.pendingTxList.push(pendingTx);
    this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
  }

  private removeFromPendingTxList(hash: string) {
    let pendingTx = this.findFromPendingList(hash);
    if (pendingTx) {
      this.pendingTxList.splice(this.pendingTxList.indexOf(pendingTx), 1);
      this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
    }
  }

  private findFromPendingList(
    hash: string
  ): PendingTransactionModel | undefined {
    return this.pendingTxList.find((pending) => pending.hash === hash);
  }

  public async sign() {
    const result = await this.privateSaleService.signMessage(
      this.privateSaleService.activePresale.signMessage,
      this.privateSaleService.activePresale.presaleName
    );

    if (result) {
      this.didSign = true;
    }
  }

  private checkUnusualSituation(tokenIn, tokenOut, amountIn, amountOut) {
    let sourceValue: number;
    let destinationValue: number;

    let valueFrom = this.priceService.getTokenValueByAmount(tokenIn, amountIn);
    sourceValue = valueFrom === undefined ? 0 : +valueFrom;

    let valueTo = this.priceService.getTokenValueByAmount(tokenOut, amountOut);
    destinationValue = valueTo === undefined ? 0 : +valueTo;

    return [
      destinationValue <
        sourceValue - (sourceValue * UNUSUAL_MODE_PERCENTAGE) / 100,
      ((sourceValue - destinationValue) * 100) / sourceValue,
      sourceValue,
      destinationValue,
    ];
  }

  public async selectToken(item: AmatesToken) {
    if (item === this.investToken) {
      return;
    }
    if (this.investToken) {
      this.investToken.value = this.investToken.requiredAmount;
    }
    this.isTokenSelected = true;
    this.investToken = item;
    await this.checkIfAllowanceIsNeeded();
  }

  private calculateRequiredAmount(token) {
    try {
      const required =
        this.privateSaleService.poolDetail.minInvest / parseFloat(token.price);
      if (token.chainId === this.privateSaleService.activePresale.chainId) {
        return required + required * 0.05; //TODO must be calculated
      } else {
        return required + required * 0.3; //TODO must be calculated
      }
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  private async addPriceToTokens(tokensTemp) {
    tokensTemp.map(async (token) => {
      token.price = await this.priceService.getPrice(
        token,
        this.web3Service.getNetworkProvider(token.chainId)
      );
    });
    return tokensTemp;
  }

  public setMax(item: AmatesToken) {
    item.value = item.balance;
  }

  showDetails(event: PageStatus) {
    this.changePage.emit(event);
  }

  setRequiredAmount(item: AmatesToken) {
    item.value = item.requiredAmount;
  }
}
