import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Web3Service } from './web3.service';
import { NumberType, formatNumber } from '@uniswap/conedison/format.js';
import {
  PriceService,
  OpportunitiesHolder,
  Dexchanges,
  CrowdToken,
  Conversion,
  MainNetworksById,
  MulticallService,
} from '@crowdswap/constant';
import { UtilsService } from '../services';
import { BehaviorSubject } from 'rxjs';

const baseUrl = environment.Opportunity_BASEURL || '';
const ELIGIBLE_BEFORE_START_TIME = 1;

@Injectable()
export class OpportunitiesService {
  public currentTime;
  private loaded: boolean = false;
  public _investmentInfoSubject: BehaviorSubject<{
    loading: boolean;
    totalInvestment: number;
    opportunities: any[];
  }>;
  constructor(
    private http: HttpClient,
    private priceService: PriceService,
    private utilsService: UtilsService,
    private web3Service: Web3Service,
    private multicallService: MulticallService
  ) {
    this._investmentInfoSubject = new BehaviorSubject<{
      loading: boolean;
      totalInvestment: number;
      opportunities: any[];
    }>({
      loading: true,
      totalInvestment: NaN,
      opportunities: [],
    });
  }

  public async getOpportunities(): Promise<void> {
    const url = `${baseUrl}/api/v1/opportunity/getOpportunities`;
    const data = <any[]>await this.http.get(url).toPromise();

    const that = this;
    let mappedOpportunities = Object.keys(data).map(function (index) {
      data[index]['isUserEligible'] = false;
      data[index]['stopOppLoading'] = false;
      data[index]['stopLoading'] = false;
      data[index]['eligibleStartTime'] =
        data[index]['startTime'] - ELIGIBLE_BEFORE_START_TIME;
      data[index]['dexchange'] = Dexchanges[data[index].dexchange];
      data[index]['tokenA'] = that.getToken(data[index].tokenA);
      data[index]['tokenB'] = that.getToken(data[index].tokenB);
      data[index]['wToken'] = that.getToken(data[index].wToken);
      data[index]['middleToken'] = that.getToken(data[index].middleToken);
      data[index]['rewardToken'] = that.getToken(data[index].rewardToken);
      const tvl = parseFloat(data[index].tvl);
      const apy = parseFloat(data[index].apy);
      const dailyInterest = parseFloat(data[index].dailyInterest);
      const apr = parseFloat(data[index].apr);
      data[index]['TVL'] = tvl;
      data[index]['displayTvl'] = formatNumber(
        !tvl ? 0 : tvl,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['APY'] = apy;
      data[index]['displayApy'] = formatNumber(
        !apy ? 0 : apy,
        NumberType.TokenNonTx
      );
      data[index]['displayDailyInterest'] = Conversion.adjustFraction(
        !dailyInterest ? 0 : dailyInterest,
        4
      );
      data[index]['Daily Interest'] = dailyInterest;
      data[index]['Network'] =
        that.web3Service.networkSpec[data[index].chainId].title;
      data[index]['apr'] = Conversion.adjustFraction(apr, 2);
      data[index]['duration'] = data[index].duration
        ? Conversion.replaceSeparator(
            Conversion.adjustFraction(data[index].duration)
          ).replace('.00', '')
        : '0';
      data[index]['endDateUI'] = data[index].endDate
        ? new Date(data[index].endDate).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : '-';
      data[index]['remainingRewardsUI'] = data[index].remainingRewards
        ? formatNumber(
            data[index].remainingRewards,
            NumberType.NFTTokenFloorPriceTrailingZeros
          ).replace('.00', '')
        : '0';
      data[index]['totalRewardUI'] = data[index].totalReward
        ? formatNumber(
            data[index].totalReward,
            NumberType.NFTTokenFloorPriceTrailingZeros
          ).replace('.00', '')
        : '0';
      data[index]['remainingPercent'] =
        data[index].remainingRewards && data[index].totalReward
          ? Conversion.adjustFraction(
              (data[index].remainingRewards * 100) / data[index].totalReward,
              2
            )
          : 0;
      data[index]['reserveAUI'] = formatNumber(
        data[index].reserveA === '-' ? 0 : data[index].reserveA,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['reserveBUI'] = formatNumber(
        data[index].reserveB === '-' ? 0 : data[index].reserveB,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['stopOppLoading'] = 'true';

      return data[index];
    });

    mappedOpportunities = [...mappedOpportunities];

    OpportunitiesHolder.FillAllOpportunities(mappedOpportunities);
    this.loaded = true;
  }

  public async ensureOpportunitiesLoaded() {
    if (
      !this.loaded ||
      Object.keys(OpportunitiesHolder.Opportunities).length < 4
    ) {
      await this.getOpportunities();
    }
  }

  public getToken(token: CrowdToken) {
    if (token) {
      return new CrowdToken(
        token.chainId,
        token.address,
        token.decimals,
        token.symbol,
        token.name
      );
    }
    return undefined;
  }

  getMulticallResultIndex(
    data: any[],
    chainId: number,
    opportunityName: string,
    methodName: string
  ) {
    const index = data
      .filter((item) => {
        return item.chainId === chainId;
      })
      .findIndex((item) => {
        return item.oppName === opportunityName && item.method === methodName;
      });
    return index;
  }

  getNetworkIndex(_chainId) {
    for (const [index, chainId] of Object.keys(MainNetworksById)
      .filter((chainId) => environment.ACTIVE_NETWORK.includes(chainId))
      .entries()) {
      if (_chainId === +chainId) {
        return index;
      }
    }
    return -1;
  }
}
