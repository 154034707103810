import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  NDDClientInfoServiceImpl,
  PrivateSaleService,
  Web3Service,
} from '../../../services';
import { BaseComponent, PageStatus } from '../base.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.css',
})
export class MainComponent extends BaseComponent implements OnInit {
  public PageStatus = PageStatus;
  public userDetail: {
    investorShare: string;
    released: string;
    balance: string;
    state: number;
  } = {
    investorShare: '0',
    released: '0',
    balance: '0',
    state: 0,
  };

  constructor(
    public web3Service: Web3Service,
    public privateSaleService: PrivateSaleService,
    public ref: ChangeDetectorRef,
    public clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, privateSaleService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    await super.ngOnInit();

    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          if (!connection) {
            this.pageStatus = this.getPageStatus();
          } else {
            await this.onWalletConnectionChange(connection);
            this.ref.detectChanges();
          }
          this.ref.detectChanges();
        }
      )
    );

    await this.onWalletConnectionChange(this.web3Service.isConnected());

    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.isWrongNetwork = isWrongNetwork;
          this.ref.detectChanges();
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        this.userDetail = await this.privateSaleService.getUserDetails();
        this.pageStatus = this.getPageStatus();
        this.ref.detectChanges();
      })
    );

    this.pageStatus = this.getPageStatus();
  }

  public getPageStatus(): PageStatus {
    if (!this.isUserWalletConnected) {
      return PageStatus.WALLET_NOT_CONNECTED;
    } else if (+this.userDetail.balance === 0) {
      return PageStatus.INVEST;
    }
    return PageStatus.DETAILS;
  }

  private async onWalletConnectionChange<T>(connection: T) {
    try {
      if (connection) {
        this.privateSaleService.activePresale =
          await this.privateSaleService.getPresaleList();
        this.userDetail = await this.privateSaleService.getUserDetails();
        this.pageStatus = this.getPageStatus();
      }
      this.ref.detectChanges();
    } catch (e) {
      console.error(e);
    }
  }

  public async changePages(page: PageStatus) {
    this.pageStatus = page;
  }
}
