import { Injectable } from '@angular/core';
import { GasPriceHolder, PriceService } from '@crowdswap/constant';
import { OpportunitiesService } from './opportunities.service';
import { TokensService } from './tokens.service';
import { environment } from '../../environments/environment';

export enum LoadState {
  Pending = 1,
  Success = 2,
}

@Injectable()
export class InitializeService {
  public LoadState = LoadState.Pending;

  constructor(
    private readonly priceService: PriceService,
    private readonly tokensService: TokensService,
    private readonly opportunitiesService: OpportunitiesService // private readonly timeService: TimeService
  ) {
    PriceService.PRICE_URL = environment.PriceUrl;
    GasPriceHolder.NetworkServiceURL = environment.NETWORK_SERVICE_BASEURL;
  }

  public async Initialize(): Promise<void> {
    try {
      await this.InitializeInternal();
    } catch (err) {
      console.error(err);
    }
  }

  private async InitializeInternal(): Promise<void> {
    await this.opportunitiesService.getOpportunities();
    this.tokensService.FillTokensWithStaticData();

    setInterval(async () => {
      try {
        await this.priceService.CachePricesForANetwork();
      } catch {}
    }, 30000);

    setInterval(async () => {
      try {
        await this.opportunitiesService.getOpportunities();
      } catch {}
    }, 30 * 60 * 1000);

    setInterval(async () => {
      try {
        await GasPriceHolder.Instance.CacheGasPricesForAllNetworks();
      } catch {}
    }, 45 * 1000);

    await this.opportunitiesService.getOpportunities();
    await this.tokensService.GetTokensForANetwork();
    await this.priceService.CachePricesForANetwork();
    await GasPriceHolder.Instance.CacheGasPricesForAllNetworks();

    this.LoadState = LoadState.Success;
  }
}
