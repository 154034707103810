import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { connect, disconnect, signMessage, getBalance, getEnsAvatar, getEnsName, switchChain, watchAccount, watchConnectors, waitForTransactionReceipt, estimateGas as wagmiEstimateGas, getAccount } from '@wagmi/core';
import { mainnet } from 'viem/chains';
import { prepareTransactionRequest, sendTransaction as wagmiSendTransaction } from '@wagmi/core';
import { formatUnits, parseUnits } from 'viem';
import { Web3ModalScaffold } from '@web3modal/scaffold';
import { ConstantsUtil, PresetsUtil, HelpersUtil } from '@web3modal/scaffold-utils';
import { getCaipDefaultChain, getEmailCaipNetworks, getWalletConnectCaipNetworks } from './utils/helpers.js';
import { W3mFrameConstants, W3mFrameHelpers, W3mFrameRpcConstants } from '@web3modal/wallet';
import { NetworkUtil } from '@web3modal/common';
export class Web3Modal extends Web3ModalScaffold {
  constructor(options) {
    const {
      wagmiConfig,
      siweConfig,
      defaultChain,
      tokens,
      _sdkVersion,
      ...w3mOptions
    } = options;
    if (!wagmiConfig) {
      throw new Error('web3modal:constructor - wagmiConfig is undefined');
    }
    if (!w3mOptions.projectId) {
      throw new Error('web3modal:constructor - projectId is undefined');
    }
    const networkControllerClient = {
      switchCaipNetwork: async caipNetwork => {
        const chainId = NetworkUtil.caipNetworkIdToNumber(caipNetwork?.id);
        if (chainId) {
          await switchChain(this.wagmiConfig, {
            chainId
          });
        }
      },
      getApprovedCaipNetworksData: async () => new Promise(resolve => {
        const connections = new Map(wagmiConfig.state.connections);
        const connection = connections.get(wagmiConfig.state.current || '');
        if (connection?.connector?.id === ConstantsUtil.EMAIL_CONNECTOR_ID) {
          resolve(getEmailCaipNetworks());
        } else if (connection?.connector?.id === ConstantsUtil.WALLET_CONNECT_CONNECTOR_ID) {
          const connector = wagmiConfig.connectors.find(c => c.id === ConstantsUtil.WALLET_CONNECT_CONNECTOR_ID);
          resolve(getWalletConnectCaipNetworks(connector));
        }
        resolve({
          approvedCaipNetworkIds: undefined,
          supportsAllNetworks: true
        });
      })
    };
    const connectionControllerClient = {
      connectWalletConnect: async onUri => {
        const connector = wagmiConfig.connectors.find(c => c.id === ConstantsUtil.WALLET_CONNECT_CONNECTOR_ID);
        if (!connector) {
          throw new Error('connectionControllerClient:getWalletConnectUri - connector is undefined');
        }
        const provider = await connector.getProvider();
        provider.on('display_uri', data => {
          onUri(data);
        });
        const chainId = NetworkUtil.caipNetworkIdToNumber(this.getCaipNetwork()?.id);
        await connect(this.wagmiConfig, {
          connector,
          chainId
        });
      },
      connectExternal: async ({
        id,
        provider,
        info
      }) => {
        const connector = wagmiConfig.connectors.find(c => c.id === id);
        if (!connector) {
          throw new Error('connectionControllerClient:connectExternal - connector is undefined');
        }
        if (provider && info && connector.id === ConstantsUtil.EIP6963_CONNECTOR_ID) {
          connector.setEip6963Wallet?.({
            provider,
            info
          });
        }
        const chainId = NetworkUtil.caipNetworkIdToNumber(this.getCaipNetwork()?.id);
        await connect(this.wagmiConfig, {
          connector,
          chainId
        });
      },
      checkInstalled: ids => {
        const injectedConnector = this.getConnectors().find(c => c.type === 'INJECTED');
        if (!ids) {
          return Boolean(window.ethereum);
        }
        if (injectedConnector) {
          if (!window?.ethereum) {
            return false;
          }
          return ids.some(id => Boolean(window.ethereum?.[String(id)]));
        }
        return false;
      },
      disconnect: async () => {
        await disconnect(this.wagmiConfig);
      },
      signMessage: async message => signMessage(this.wagmiConfig, {
        message
      }),
      estimateGas: async args => {
        try {
          return await wagmiEstimateGas(this.wagmiConfig, {
            account: args.address,
            to: args.to,
            data: args.data,
            type: 'legacy'
          });
        } catch (error) {
          return 0n;
        }
      },
      sendTransaction: async data => {
        const {
          chainId
        } = getAccount(this.wagmiConfig);
        const txParams = {
          account: data.address,
          to: data.to,
          value: data.value,
          gas: data.gas,
          gasPrice: data.gasPrice,
          data: data.data,
          chainId,
          type: 'legacy'
        };
        await prepareTransactionRequest(this.wagmiConfig, txParams);
        const tx = await wagmiSendTransaction(this.wagmiConfig, txParams);
        await waitForTransactionReceipt(this.wagmiConfig, {
          hash: tx,
          timeout: 25000
        });
        return tx;
      },
      parseUnits,
      formatUnits
    };
    super({
      networkControllerClient,
      connectionControllerClient,
      siweControllerClient: siweConfig,
      defaultChain: getCaipDefaultChain(defaultChain),
      tokens: HelpersUtil.getCaipTokens(tokens),
      _sdkVersion: _sdkVersion ?? `html-wagmi-${ConstantsUtil.VERSION}`,
      ...w3mOptions
    });
    this.hasSyncedConnectedAccount = false;
    this.options = undefined;
    this.options = options;
    this.wagmiConfig = wagmiConfig;
    this.syncRequestedNetworks([...wagmiConfig.chains]);
    this.syncConnectors([...wagmiConfig.connectors]);
    this.initEmailConnectorListeners([...wagmiConfig.connectors]);
    watchConnectors(this.wagmiConfig, {
      onChange: connectors => this.syncConnectors(connectors)
    });
    watchAccount(this.wagmiConfig, {
      onChange: accountData => this.syncAccount({
        ...accountData
      })
    });
  }
  getState() {
    const state = super.getState();
    return {
      ...state,
      selectedNetworkId: NetworkUtil.caipNetworkIdToNumber(state.selectedNetworkId)
    };
  }
  subscribeState(callback) {
    return super.subscribeState(state => callback({
      ...state,
      selectedNetworkId: NetworkUtil.caipNetworkIdToNumber(state.selectedNetworkId)
    }));
  }
  syncRequestedNetworks(chains) {
    const requestedCaipNetworks = chains?.map(chain => ({
      id: `${ConstantsUtil.EIP155}:${chain.id}`,
      name: chain.name,
      imageId: PresetsUtil.EIP155NetworkImageIds[chain.id],
      imageUrl: this.options?.chainImages?.[chain.id]
    }));
    this.setRequestedCaipNetworks(requestedCaipNetworks ?? []);
  }
  async syncAccount({
    address,
    isConnected,
    chainId,
    connector
  }) {
    this.resetAccount();
    this.syncNetwork(address, chainId, isConnected);
    if (isConnected && address && chainId) {
      const caipAddress = `${ConstantsUtil.EIP155}:${chainId}:${address}`;
      this.setIsConnected(isConnected);
      this.setCaipAddress(caipAddress);
      await Promise.all([this.syncProfile(address, chainId), this.syncBalance(address, chainId), this.syncConnectedWalletInfo(connector), this.getApprovedCaipNetworksData()]);
      this.hasSyncedConnectedAccount = true;
    } else if (!isConnected && this.hasSyncedConnectedAccount) {
      this.resetWcConnection();
      this.resetNetwork();
    }
  }
  async syncNetwork(address, chainId, isConnected) {
    const chain = this.wagmiConfig.chains.find(c => c.id === chainId);
    if (chain || chainId) {
      const name = chain?.name ?? chainId?.toString();
      const id = Number(chain?.id ?? chainId);
      const caipChainId = `${ConstantsUtil.EIP155}:${id}`;
      this.setCaipNetwork({
        id: caipChainId,
        name,
        imageId: PresetsUtil.EIP155NetworkImageIds[id],
        imageUrl: this.options?.chainImages?.[id]
      });
      if (isConnected && address && chainId) {
        const caipAddress = `${ConstantsUtil.EIP155}:${id}:${address}`;
        this.setCaipAddress(caipAddress);
        if (chain?.blockExplorers?.default?.url) {
          const url = `${chain.blockExplorers.default.url}/address/${address}`;
          this.setAddressExplorerUrl(url);
        } else {
          this.setAddressExplorerUrl(undefined);
        }
        if (this.hasSyncedConnectedAccount) {
          await this.syncProfile(address, chainId);
          await this.syncBalance(address, chainId);
        }
      }
    }
  }
  async syncProfile(address, chainId) {
    try {
      const {
        name,
        avatar
      } = await this.fetchIdentity({
        address
      });
      this.setProfileName(name);
      this.setProfileImage(avatar);
    } catch {
      if (chainId === mainnet.id) {
        const profileName = await getEnsName(this.wagmiConfig, {
          address,
          chainId
        });
        if (profileName) {
          this.setProfileName(profileName);
          const profileImage = await getEnsAvatar(this.wagmiConfig, {
            name: profileName,
            chainId
          });
          if (profileImage) {
            this.setProfileImage(profileImage);
          }
        }
      } else {
        this.setProfileName(null);
        this.setProfileImage(null);
      }
    }
  }
  async syncBalance(address, chainId) {
    const chain = this.wagmiConfig.chains.find(c => c.id === chainId);
    if (chain) {
      const balance = await getBalance(this.wagmiConfig, {
        address,
        chainId: chain.id,
        token: this.options?.tokens?.[chain.id]?.address
      });
      this.setBalance(balance.formatted, balance.symbol);
      return;
    }
    this.setBalance(undefined, undefined);
  }
  async syncConnectedWalletInfo(connector) {
    if (!connector) {
      throw Error('syncConnectedWalletInfo - connector is undefined');
    }
    if (connector.id === ConstantsUtil.WALLET_CONNECT_CONNECTOR_ID && connector.getProvider) {
      const walletConnectProvider = await connector.getProvider();
      if (walletConnectProvider.session) {
        this.setConnectedWalletInfo({
          ...walletConnectProvider.session.peer.metadata,
          name: walletConnectProvider.session.peer.metadata.name,
          icon: walletConnectProvider.session.peer.metadata.icons?.[0]
        });
      }
    } else {
      this.setConnectedWalletInfo({
        name: connector.name,
        icon: connector.icon
      });
    }
  }
  syncConnectors(connectors) {
    const uniqueIds = new Set();
    const filteredConnectors = connectors.filter(item => !uniqueIds.has(item.id) && uniqueIds.add(item.id));
    const w3mConnectors = [];
    const coinbaseSDKId = ConstantsUtil.COINBASE_SDK_CONNECTOR_ID;
    const coinbaseConnector = filteredConnectors.find(c => c.id === ConstantsUtil.CONNECTOR_RDNS_MAP[ConstantsUtil.COINBASE_CONNECTOR_ID]);
    filteredConnectors.forEach(({
      id,
      name,
      type,
      icon
    }) => {
      const isCoinbaseRepeated = coinbaseConnector && id === coinbaseSDKId;
      const shouldSkip = isCoinbaseRepeated || ConstantsUtil.EMAIL_CONNECTOR_ID === id;
      if (!shouldSkip) {
        w3mConnectors.push({
          id,
          explorerId: PresetsUtil.ConnectorExplorerIds[id],
          imageUrl: this.options?.connectorImages?.[id] ?? icon,
          name: PresetsUtil.ConnectorNamesMap[id] ?? name,
          imageId: PresetsUtil.ConnectorImageIds[id],
          type: PresetsUtil.ConnectorTypesMap[type] ?? 'EXTERNAL',
          info: {
            rdns: id
          }
        });
      }
    });
    this.setConnectors(w3mConnectors);
    this.syncEmailConnector(filteredConnectors);
  }
  async syncEmailConnector(connectors) {
    const emailConnector = connectors.find(({
      id
    }) => id === ConstantsUtil.EMAIL_CONNECTOR_ID);
    if (emailConnector) {
      const provider = await emailConnector.getProvider();
      this.addConnector({
        id: ConstantsUtil.EMAIL_CONNECTOR_ID,
        type: 'EMAIL',
        name: 'Email',
        provider
      });
    }
  }
  async initEmailConnectorListeners(connectors) {
    const emailConnector = connectors.find(({
      id
    }) => id === ConstantsUtil.EMAIL_CONNECTOR_ID);
    if (emailConnector) {
      await this.listenEmailConnector(emailConnector);
      await this.listenModal(emailConnector);
    }
  }
  async listenEmailConnector(connector) {
    if (typeof window !== 'undefined' && connector) {
      super.setLoading(true);
      const provider = await connector.getProvider();
      const isLoginEmailUsed = provider.getLoginEmailUsed();
      super.setLoading(isLoginEmailUsed);
      if (isLoginEmailUsed) {
        this.setIsConnected(false);
      }
      provider.onRpcRequest(request => {
        if (W3mFrameHelpers.checkIfRequestExists(request)) {
          if (!W3mFrameHelpers.checkIfRequestIsAllowed(request)) {
            if (super.isOpen()) {
              if (!super.isTransactionStackEmpty()) {
                super.redirect('ApproveTransaction');
              }
            } else {
              super.open({
                view: 'ApproveTransaction'
              });
            }
          }
        } else {
          super.open();
          const method = W3mFrameHelpers.getRequestMethod(request);
          console.error(W3mFrameRpcConstants.RPC_METHOD_NOT_ALLOWED_MESSAGE, {
            method
          });
          setTimeout(() => {
            this.showErrorMessage(W3mFrameRpcConstants.RPC_METHOD_NOT_ALLOWED_UI_MESSAGE);
          }, 300);
          provider.rejectRpcRequest();
        }
      });
      provider.onRpcResponse(response => {
        const responseType = W3mFrameHelpers.getResponseType(response);
        switch (responseType) {
          case W3mFrameConstants.RPC_RESPONSE_TYPE_ERROR:
            {
              const isModalOpen = super.isOpen();
              if (isModalOpen) {
                if (super.isTransactionStackEmpty()) {
                  super.close();
                } else {
                  super.popTransactionStack(true);
                }
              }
              break;
            }
          case W3mFrameConstants.RPC_RESPONSE_TYPE_TX:
            {
              if (super.isTransactionStackEmpty()) {
                super.close();
              } else {
                super.popTransactionStack();
              }
              break;
            }
          default:
            break;
        }
      });
      provider.onNotConnected(() => {
        const isConnected = this.getIsConnectedState();
        if (!isConnected) {
          this.setIsConnected(false);
          super.setLoading(false);
        }
      });
      provider.onIsConnected(req => {
        this.setIsConnected(true);
        this.setSmartAccountDeployed(Boolean(req.smartAccountDeployed));
        this.setPreferredAccountType(req.preferredAccountType);
        super.setLoading(false);
      });
      provider.onGetSmartAccountEnabledNetworks(networks => {
        this.setSmartAccountEnabledNetworks(networks);
      });
      provider.onSetPreferredAccount(({
        address,
        type
      }) => {
        if (!address) {
          return;
        }
        const chainId = NetworkUtil.caipNetworkIdToNumber(this.getCaipNetwork()?.id);
        this.syncAccount({
          address: address,
          chainId,
          isConnected: true,
          connector
        }).then(() => this.setPreferredAccountType(type));
      });
    }
  }
  async listenModal(connector) {
    const provider = await connector.getProvider();
    this.subscribeState(val => {
      if (!val.open) {
        provider.rejectRpcRequest();
      }
    });
  }
}
