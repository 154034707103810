import { Injectable } from '@angular/core';
import { Networks } from '@crowdswap/constant';

@Injectable()
export class Constants {
  public static DEBRIDGE_ADDRESS: string =
    '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251';
  public static EXECUTION_FEE_ERROR: number = 159753;
  public static REDUCE_MAX_AMOUNT_IN: number = 0.01;
  public static CROSSCHAIN_INITIAL_CHAIN_ID = Networks.MAINNET;
}
