import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {
  NDDClientInfoServiceImpl,
  PrivateSaleService,
  Web3Service,
} from '../../../../services';

@Component({
  selector: 'app-header-private-sale',
  templateUrl: './header-private-sale.component.html',
  styleUrl: './header-private-sale.component.css',
})
export class HeaderPrivateSaleComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public web3Service: Web3Service,
    public privateSaleService: PrivateSaleService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, privateSaleService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
  }
}
