import { BigNumber } from 'ethers';
import { Token } from '@crowdswap/sdk';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  TokensHolder,
} from '@crowdswap/constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, throttleTime } from 'rxjs/operators';
import { asyncScheduler, Subscription } from 'rxjs';
import {
  DeviceType,
  NDDClientInfoServiceImpl,
  PrivateSaleService,
  UtilsService,
  Web3Service,
} from '../../services';
import { environment } from '../../../environments/environment';
import { CurrentNetwork } from '../../model';

export enum PageStatus {
  WALLET_NOT_CONNECTED,
  INVEST,
  SUCCESSFUL_INVESTED,
  DETAILS,
}

export enum PrivateSaleStatus {
  notStarted,
  running,
  finished,
}

@Component({
  template: '',
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  public highPriceImpactPercent = environment.HIGH_PRICE_IMPACT_PERCENT;
  public warningPriceImpactPercent = environment.WARNING_PRICE_IMPACT_PERCENT;

  public currentTime = 1;
  public startTime = 0;
  public finishTime = 0; //it should be less than default currentTime
  public PrivateSaleStatus = PrivateSaleStatus;
  public privateSaleStatus: PrivateSaleStatus = PrivateSaleStatus.notStarted;
  public pageStatus: PageStatus = PageStatus.WALLET_NOT_CONNECTED;

  public startTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };

  public finishTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };

  public setting: {
    baseLogoIconsUrl;
    isMobile: boolean;
  } = {
    baseLogoIconsUrl: environment.BaseLogoIconsUrl,
    isMobile: false,
  };

  public status: {
    chainId;
    supportedNetwork: boolean;
    walletAddress: string;
    isUserWalletConnected: boolean;
    isWrongNetwork: boolean;
    incorrectNetworkMessage: string;
  } = {
    chainId: -1,
    supportedNetwork: false,
    walletAddress: '',
    isUserWalletConnected: false,
    isWrongNetwork: false,
    incorrectNetworkMessage: '',
  };

  protected subscriptionList: Subscription[] = [];

  public get baseLogoIconsUrl() {
    return this.setting.baseLogoIconsUrl;
  }

  public get chainId() {
    return this.status.chainId;
  }

  public set chainId(_chainId: number) {
    this.status.chainId = _chainId;
  }

  public get supportedNetwork() {
    return this.status.supportedNetwork;
  }

  public get walletAddress() {
    return this.status.walletAddress;
  }

  public set walletAddress(_walletAddress: string) {
    this.status.walletAddress = _walletAddress;
  }

  public get isUserWalletConnected() {
    return this.status.isUserWalletConnected;
  }

  public get isWrongNetwork() {
    return this.status.isWrongNetwork;
  }

  public set isWrongNetwork(_isWrongNetwork: boolean) {
    this.status.isWrongNetwork = _isWrongNetwork;
  }

  public get incorrectNetworkMessage() {
    return this.status.incorrectNetworkMessage;
  }

  public set incorrectNetworkMessage(_incorrectNetworkMessage: string) {
    this.status.incorrectNetworkMessage = _incorrectNetworkMessage;
  }

  protected constructor(
    protected web3Service: Web3Service,
    protected privateSaleService: PrivateSaleService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    if (this.clientInfoServiceImpl) {
      this.setting.isMobile =
        [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
          this.clientInfoServiceImpl.getDeviceType()
        ) > -1;
    }
    setInterval(() => {
      this.currentTime = Math.floor(Date.now() / 1000);
      this.checkPreSaleConditions();
    }, 1000);
  }

  public async ngOnInit(
    onCurrentNetworkChange: ((currentNetwork) => void) | undefined = undefined,
    onWalletConnectionChange: ((connection) => void) | undefined = undefined,
    onAccountChange: ((address) => Promise<void>) | undefined = undefined
  ): Promise<void> {
    this.status.isUserWalletConnected = this.web3Service.isConnected() || false;
    this.status.walletAddress = this.web3Service.getWalletAddress() || '';

    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.status.supportedNetwork =
            !!Dexchanges.Crowdswap.networks[currentNetwork.chainId];
          this.status.chainId = currentNetwork.chainId;

          BaseComponent.executeAction(onCurrentNetworkChange, currentNetwork);
        })
    );

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection: boolean) => {
          this.status.isUserWalletConnected = connection;
          BaseComponent.executeAction(onWalletConnectionChange, connection);
        }
      )
    );

    //The selected network in the wallet is not supported by our app
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.status.isWrongNetwork = isWrongNetwork;
          if (this.status.isWrongNetwork) {
            this.status.incorrectNetworkMessage = 'Wrong network';
          }
        }
      )
    );

    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(
        async (address: string) => {
          this.status.walletAddress = address;
          BaseComponent.executeAction(onAccountChange, address);
        }
      )
    );
  }

  public async disconnect() {
    await this.web3Service.clearCachedProvider();
  }

  public async connectWallet() {
    if (this.web3Service.isConnected()) {
      return;
    }
    await this.web3Service.openModal().catch(console.log);
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public updateUrl(el: any) {
    el.target.src =
      'https://raw.githubusercontent.com/Crowdswap/assets/master/icons/00-none.png';
  }

  public scrollTopContentSection(toInvestmentList?: boolean) {
    const contentSection = document.querySelector('.content-section');
    const investmentList = document.getElementById('investmentList');
    const top =
      toInvestmentList && investmentList ? investmentList.offsetTop - 60 : 0;

    if (contentSection) {
      setTimeout(() => {
        contentSection.scrollTo({
          behavior: 'smooth',
          top: top,
        });
      }, 100);
    }
  }

  protected isCoin(token: Token): boolean {
    return TokensHolder.isBaseToken(token.chainId, token.address);
  }

  protected async changeNetworkTo(newChainId: number) {
    if (!this.status.isUserWalletConnected) {
      return;
    }
    if (newChainId !== this.web3Service.getWalletChainId()) {
      await this.web3Service.changeNetwork(newChainId);
    }
  }

  private static executeAction(action: ((param) => void) | undefined, param) {
    if (action && typeof action === 'function') {
      try {
        action(param);
      } catch (err) {
        console.log(err);
      }
    }
  }

  public checkPreSaleConditions() {
    if (this.privateSaleService.poolDetail) {
      this.startTime = this.privateSaleService.poolDetail.startTime;
      this.finishTime = this.privateSaleService.poolDetail.finishTime;
      if (this.startTime > this.currentTime) {
        this.startTimeRemain = this.privateSaleService.getRemainingTime(
          this.startTime,
          this.currentTime
        );
        this.finishTimeRemain = this.privateSaleService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
        this.privateSaleStatus = PrivateSaleStatus.notStarted;
      }
      if (
        this.startTime < this.currentTime &&
        this.finishTime > this.currentTime
      ) {
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0,
        };
        this.finishTimeRemain = UtilsService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
        this.privateSaleStatus = PrivateSaleStatus.running;
      }
      if (this.finishTime < this.currentTime) {
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0,
        };
        this.finishTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0,
        };
        this.privateSaleStatus = PrivateSaleStatus.finished;
      }
    }
  }
}
