var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { property, state } from 'lit/decorators.js';
import { SendController } from '@web3modal/core';
import { createRef, ref } from 'lit/directives/ref.js';
let W3mInputAddress = class W3mInputAddress extends LitElement {
  constructor() {
    super(...arguments);
    this.inputElementRef = createRef();
    this.instructionElementRef = createRef();
    this.instructionHidden = Boolean(this.receiverAddress);
  }
  firstUpdated() {
    if (this.receiverAddress) {
      this.instructionHidden = true;
    }
    this.checkHidden();
  }
  render() {
    return html` <wui-flex
      @click=${this.onBoxClick.bind(this)}
      flexDirection="column"
      justifyContent="center"
      gap="4xs"
      .padding=${['2xl', 'l', 'xl', 'l']}
    >
      <wui-text
        ${ref(this.instructionElementRef)}
        class="instruction"
        color="fg-300"
        variant="medium-400"
      >
        Type or
        <wui-button
          size="sm"
          variant="shade"
          iconLeft="copy"
          @click=${this.onPasteClick.bind(this)}
        >
          <wui-icon size="sm" color="inherit" slot="iconLeft" name="copy"></wui-icon>
          Paste
        </wui-button>
        address
      </wui-text>
      <textarea
        ?disabled=${!this.instructionHidden}
        ${ref(this.inputElementRef)}
        @input=${this.onInputChange.bind(this)}
        @blur=${this.onBlur.bind(this)}
        .value=${this.receiverAddress ?? ''}
        autocomplete="off"
      >
${this.receiverAddress ?? ''}</textarea
      >
    </wui-flex>`;
  }
  async focusInput() {
    if (this.instructionElementRef.value) {
      this.instructionHidden = true;
      await this.toggleInstructionFocus(false);
      this.instructionElementRef.value.style.pointerEvents = 'none';
      this.inputElementRef.value?.focus();
      if (this.inputElementRef.value) {
        this.inputElementRef.value.selectionStart = this.inputElementRef.value.selectionEnd = this.inputElementRef.value.value.length;
      }
    }
  }
  async focusInstruction() {
    if (this.instructionElementRef.value) {
      this.instructionHidden = false;
      await this.toggleInstructionFocus(true);
      this.instructionElementRef.value.style.pointerEvents = 'auto';
      this.inputElementRef.value?.blur();
    }
  }
  async toggleInstructionFocus(focus) {
    if (this.instructionElementRef.value) {
      await this.instructionElementRef.value.animate([{
        opacity: focus ? 0 : 1
      }, {
        opacity: focus ? 1 : 0
      }], {
        duration: 100,
        easing: 'ease',
        fill: 'forwards'
      }).finished;
    }
  }
  onBoxClick() {
    if (!this.receiverAddress && !this.instructionHidden) {
      this.focusInput();
    }
  }
  onBlur() {
    if (!this.receiverAddress && this.instructionHidden) {
      this.focusInstruction();
    }
  }
  checkHidden() {
    if (this.instructionHidden) {
      this.focusInput();
    }
  }
  async onPasteClick() {
    const text = await navigator.clipboard.readText();
    SendController.setReceiverAddress(text);
  }
  onInputChange(e) {
    const element = e.target;
    if (element.value && !this.instructionHidden) {
      this.focusInput();
    }
    SendController.setReceiverAddress(element.value);
  }
};
W3mInputAddress.styles = styles;
__decorate([property()], W3mInputAddress.prototype, "receiverAddress", void 0);
__decorate([state()], W3mInputAddress.prototype, "instructionHidden", void 0);
W3mInputAddress = __decorate([customElement('w3m-input-address')], W3mInputAddress);
export { W3mInputAddress };
