import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Conversion, NetworksById, TokensHolder } from '@crowdswap/constant';
import { ToastrService } from 'ngx-toastr';
import {
  NDDClientInfoServiceImpl,
  PrivateSaleService,
  Web3Service,
} from '../../../../services';
import {
  BaseComponent,
  PageStatus,
  PrivateSaleStatus,
} from '../../base.component';

export enum PoolState {
  PREOPEN,
  THREEHOURS,
  FIFO,
  CLOSE,
  FAILURE,
}

@Component({
  selector: 'app-details-private-sale',
  templateUrl: './details-private-sale.component.html',
  styleUrls: ['./details-private-sale.component.scss'],
})
export class DetailsPrivateSaleComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output()
  changePage = new EventEmitter();
  public _userDetail: any;

  @Input()
  set userDetail(opportunity: any) {
    this._userDetail = opportunity;
  }

  get userDetail() {
    return this._userDetail;
  }

  PageStatus = PageStatus;

  subscriptionList: Subscription[] = [];

  public releasable: string = '0';
  public poolState: any;
  public PoolState = PoolState;
  public nextReleaseTime;
  public nextReleaseAmount;
  public isOwner: boolean = false;
  public buttonLoading: boolean = false;

  constructor(
    public web3Service: Web3Service,
    public privateSaleService: PrivateSaleService,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, privateSaleService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    await super.ngOnInit();

    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.refreshData();
        this.ref.detectChanges();
      })
    );

    await this.refreshData();
    this.ref.detectChanges();

    if (this.web3Service.isConnected()) {
      let owner = await this.privateSaleService.getOwner(
        this.privateSaleService.activePresale
      );
      const isOwner =
        owner === this.web3Service.getWalletAddress() && !this.isWrongNetwork;
      if (isOwner) {
        const poolBalance = await this.privateSaleService.getPoolBalance(
          this.privateSaleService.activePresale
        );
        this.isOwner = poolBalance.gt(0);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public investMore() {
    this.changePage.emit(PageStatus.INVEST);
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass',
    });
  }

  private showSuccessToaster(title, message?) {
    this.toastr.success(title, message, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'successClass',
    });
  }

  public async release() {
    if (
      this.web3Service.getCurrentChainId() !==
      this.privateSaleService.activePresale.chainId
    ) {
      await this.changeNetworkTo(this.privateSaleService.activePresale.chainId);
    }
    try {
      this.buttonLoading = true;
      await this.confirmRelease();
    } catch (e) {
      this.buttonLoading = false;
      console.error(
        e +
          `details-private-sale: release unsuccessfully.now release is = ${this.releasable}`
      );
      console.log(e);
    }
  }

  private async confirmRelease(): Promise<any> {
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = await this.privateSaleService.release(
          this.privateSaleService.activePresale
        );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            console.error(
              e +
                `details-private-sale: release unsuccessfully releaseBalance is  = ${this.releasable}`
            );
            console.log(e);
            this.buttonLoading = false;
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.showSuccessToaster('Success', 'released successfully!');
                await this.setReleaseDetail();
                this.buttonLoading = false;
              }
              if (data?.status === 0) {
                this.showErrorToaster('Error', 'release rejected!');
                return;
              }
            });
        } else {
          this.showErrorToaster('Error', 'release rejected!');
          console.log('release rejected!');
          this.buttonLoading = false;
          return;
        }
      }
    } catch (e) {
      this.buttonLoading = false;
      console.log(e);
      console.error(
        e +
          `details-private-sale: release unsuccessfully. releasedBalance is  = ${this.releasable}`
      );
    }
  }

  public async setReleaseDetail() {
    this.releasable = await this.privateSaleService.claim(
      this.privateSaleService.activePresale,
      this.web3Service.getWalletAddress()
    );
    if (
      this.poolState !== PoolState.FAILURE ||
      this.poolState !== PoolState.PREOPEN
    ) {
      const nextRelease = await this.privateSaleService.getNextRelease(
        this.privateSaleService.activePresale
      );
      this.nextReleaseTime = +(nextRelease[1] * 1000);
      this.nextReleaseAmount = (+Conversion.convertStringFromDecimal(
        nextRelease[0]
      )).toFixed(2);
    }
  }

  private async refreshData() {
    this.poolState = await this.privateSaleService.getPoolState(
      this.privateSaleService.activePresale
    );
    await this.setReleaseDetail();
  }

  async withdraw() {
    try {
      await this.confirmWithdraw();
    } catch (e) {
      console.error(e + `details-private-sale: withdraw unsuccessfully`);
      console.log(e);
    }
  }

  private async confirmWithdraw() {
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = await this.privateSaleService.withdrawWhenFailed(
          this.privateSaleService.activePresale
        );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            console.error(e + `details-private-sale: Withdraw unsuccessfully.`);
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.showSuccessToaster('Success', 'Withdrew successfully!');
                await this.setReleaseDetail();
              }
              if (data?.status === 0) {
                this.showErrorToaster('Error', 'Withdrawal rejected!');
                return;
              }
            });
        } else {
          this.showErrorToaster('Error', 'Withdrawal rejected!');
          console.log('Withdrawal rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
    }
  }

  async withdrawBeneficiaryFunds() {
    if (
      this.web3Service.getCurrentChainId() !==
      this.privateSaleService.activePresale.chainId
    ) {
      await this.changeNetworkTo(this.privateSaleService.activePresale.chainId);
    }
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const withdrawTX =
          await this.privateSaleService.withdrawBeneficiaryFunds(
            this.privateSaleService.activePresale
          );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(withdrawTX)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                // await this.waitingDialogService.close();
                this.showSuccessToaster('Success', 'Withdrew successfully!');
                this.isOwner = false;
              }
              if (data?.status === 0) {
                this.showErrorToaster('Error', 'Withdrawal rejected!');
                return;
              }
            });
        } else {
          this.showErrorToaster('Error', 'Withdrawal rejected!');
          console.log('Withdraw rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
    }
  }
}
