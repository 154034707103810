import { ChainId } from '@crowdswap/sdk';
import { CrowdToken } from '@crowdswap/constant';

export class AmatesToken extends CrowdToken {
  public requiredAmount: string = '0';

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    price?: string,
    balance?: string,
    asset?: string
  ) {
    super(chainId, address, decimals, symbol, name);
    price ? (this.price = price) : (this.price = '0');
    balance ? (this.balance = balance) : (this.balance = '0');
    asset ? (this.value = asset) : (this.value = '0');
  }
}
