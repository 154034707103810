import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FormPrivateSaleComponent } from './views/pages/private-sale/form-private-sale/form-private-sale.component';
import { MainComponent } from './views/pages/main/main.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    component: MainComponent,
  },
  {
    path: 'form',
    component: FormPrivateSaleComponent,
  },

  { path: '**', redirectTo: 'main' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
