var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { UiHelperUtil, customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { AccountController, NetworkController, RouterController, ConvertController, ConstantsUtil } from '@web3modal/core';
import { state } from 'lit/decorators.js';
let W3mConvertPreviewView = class W3mConvertPreviewView extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.detailsOpen = true;
    this.approvalTransaction = ConvertController.state.approvalTransaction;
    this.convertTransaction = ConvertController.state.convertTransaction;
    this.sourceToken = ConvertController.state.sourceToken;
    this.sourceTokenAmount = ConvertController.state.sourceTokenAmount ?? '';
    this.sourceTokenPriceInUSD = ConvertController.state.sourceTokenPriceInUSD;
    this.toToken = ConvertController.state.toToken;
    this.toTokenAmount = ConvertController.state.toTokenAmount ?? '';
    this.toTokenPriceInUSD = ConvertController.state.toTokenPriceInUSD;
    this.caipNetwork = NetworkController.state.caipNetwork;
    this.transactionLoading = ConvertController.state.transactionLoading;
    this.balanceSymbol = AccountController.state.balanceSymbol;
    this.gasPriceInUSD = ConvertController.state.gasPriceInUSD;
    this.priceImpact = ConvertController.state.priceImpact;
    this.maxSlippage = ConvertController.state.maxSlippage;
    this.unsubscribe.push(...[AccountController.subscribeKey('balanceSymbol', newBalanceSymbol => {
      if (this.balanceSymbol !== newBalanceSymbol) {
        RouterController.goBack();
      }
    }), NetworkController.subscribeKey('caipNetwork', newCaipNetwork => {
      if (this.caipNetwork !== newCaipNetwork) {
        this.caipNetwork = newCaipNetwork;
      }
    }), ConvertController.subscribe(newState => {
      this.approvalTransaction = newState.approvalTransaction;
      this.convertTransaction = newState.convertTransaction;
      this.sourceToken = newState.sourceToken;
      this.gasPriceInUSD = newState.gasPriceInUSD;
      this.toToken = newState.toToken;
      this.transactionLoading = newState.transactionLoading;
      this.gasPriceInUSD = newState.gasPriceInUSD;
      this.toTokenPriceInUSD = newState.toTokenPriceInUSD;
      this.sourceTokenAmount = newState.sourceTokenAmount ?? '';
      this.toTokenAmount = newState.toTokenAmount ?? '';
      this.priceImpact = newState.priceImpact;
      this.maxSlippage = newState.maxSlippage;
    })]);
  }
  render() {
    return html`
      <wui-flex flexDirection="column" padding="l" gap="s">${this.templateSwap()}</wui-flex>
    `;
  }
  templateSwap() {
    const sourceTokenText = `${UiHelperUtil.formatNumberToLocalString(parseFloat(this.sourceTokenAmount))} ${this.sourceToken?.symbol}`;
    const toTokenText = `${UiHelperUtil.formatNumberToLocalString(parseFloat(this.toTokenAmount))} ${this.toToken?.symbol}`;
    const sourceTokenValue = parseFloat(this.sourceTokenAmount) * this.sourceTokenPriceInUSD;
    const toTokenValue = parseFloat(this.toTokenAmount) * this.toTokenPriceInUSD - (this.gasPriceInUSD || 0);
    const sentPrice = UiHelperUtil.formatNumberToLocalString(sourceTokenValue);
    const receivePrice = UiHelperUtil.formatNumberToLocalString(toTokenValue);
    return html`
      <wui-flex flexDirection="column" alignItems="center" gap="l">
        <wui-flex class="preview-container" flexDirection="column" alignItems="flex-start" gap="l">
          <wui-flex
            class="preview-token-details-container"
            alignItems="center"
            justifyContent="space-between"
            gap="l"
          >
            <wui-flex flexDirection="column" alignItems="flex-start" gap="4xs">
              <wui-text variant="small-400" color="fg-150">Send</wui-text>
              <wui-text variant="paragraph-400" color="fg-100">$${sentPrice}</wui-text>
            </wui-flex>
            <wui-token-button
              flexDirection="row-reverse"
              text=${sourceTokenText}
              imageSrc=${this.sourceToken?.logoUri}
            >
            </wui-token-button>
          </wui-flex>
          <wui-icon name="recycleHorizontal" color="fg-200" size="md"></wui-icon>
          <wui-flex
            class="preview-token-details-container"
            alignItems="center"
            justifyContent="space-between"
            gap="l"
          >
            <wui-flex flexDirection="column" alignItems="flex-start" gap="4xs">
              <wui-text variant="small-400" color="fg-150">Receive</wui-text>
              <wui-text variant="paragraph-400" color="fg-100">$${receivePrice}</wui-text>
            </wui-flex>
            <wui-token-button
              flexDirection="row-reverse"
              text=${toTokenText}
              imageSrc=${this.toToken?.logoUri}
            >
            </wui-token-button>
          </wui-flex>
        </wui-flex>

        ${this.templateDetails()}

        <wui-flex flexDirection="row" alignItems="center" justifyContent="center" gap="xs">
          <wui-icon size="sm" color="fg-200" name="infoCircle"></wui-icon>
          <wui-text variant="small-400" color="fg-200">Review transaction carefully</wui-text>
        </wui-flex>

        <wui-flex
          class="action-buttons-container"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap="xs"
        >
          <button
            class="cancel-button"
            ?disabled=${this.transactionLoading}
            @click=${this.onCancelTransaction.bind(this)}
          >
            <wui-text variant="paragraph-600" color="fg-200">Cancel</wui-text>
          </button>
          <button
            class="convert-button"
            ?disabled=${this.transactionLoading}
            @click=${this.onSendTransaction.bind(this)}
          >
            ${this.transactionLoading ? html`<wui-loading-spinner color="inverse-100"></wui-loading-spinner>` : html`<wui-text variant="paragraph-600" color="inverse-100">
                  ${this.actionButtonLabel()}
                </wui-text>`}
          </button>
        </wui-flex>
      </wui-flex>
    `;
  }
  templateDetails() {
    const toTokenConvertedAmount = this.sourceTokenPriceInUSD && this.toTokenPriceInUSD ? 1 / this.toTokenPriceInUSD * this.sourceTokenPriceInUSD : 0;
    return html`
      <w3m-convert-details
        detailsOpen=${this.detailsOpen}
        sourceTokenSymbol=${this.sourceToken?.symbol}
        sourceTokenPrice=${this.sourceTokenPriceInUSD}
        toTokenSymbol=${this.toToken?.symbol}
        toTokenConvertedAmount=${toTokenConvertedAmount}
        gasPriceInUSD=${UiHelperUtil.formatNumberToLocalString(this.gasPriceInUSD, 3)}
        .priceImpact=${this.priceImpact}
        slippageRate=${ConstantsUtil.CONVERT_SLIPPAGE_TOLERANCE}
        .maxSlippage=${this.maxSlippage}
      ></w3m-convert-details>
    `;
  }
  actionButtonLabel() {
    if (this.approvalTransaction) {
      return 'Approve';
    }
    return 'Convert';
  }
  onCancelTransaction() {
    RouterController.goBack();
  }
  onSendTransaction() {
    if (this.approvalTransaction) {
      ConvertController.sendTransactionForApproval(this.approvalTransaction);
    } else {
      ConvertController.sendTransactionForConvert(this.convertTransaction);
    }
  }
};
W3mConvertPreviewView.styles = styles;
__decorate([state()], W3mConvertPreviewView.prototype, "detailsOpen", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "approvalTransaction", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "convertTransaction", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "sourceToken", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "sourceTokenAmount", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "sourceTokenPriceInUSD", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "toToken", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "toTokenAmount", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "toTokenPriceInUSD", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "caipNetwork", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "transactionLoading", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "balanceSymbol", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "gasPriceInUSD", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "priceImpact", void 0);
__decorate([state()], W3mConvertPreviewView.prototype, "maxSlippage", void 0);
W3mConvertPreviewView = __decorate([customElement('w3m-convert-preview-view')], W3mConvertPreviewView);
export { W3mConvertPreviewView };
