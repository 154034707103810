import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoggingService } from './log';
import {
  AvalancheService,
  BscService,
  EthereumService,
  INetworkService,
  Networks,
  PolygonService,
  ApexService,
  ArbitrumService,
  ZksyncService,
  OptimismService,
  LineaService,
  BaseService,
} from '@crowdswap/constant';

const baseUrl = environment.apiBaseUrl || '';

@Injectable()
export class NetworksService {
  private ethereumNetworkProvider: INetworkService | undefined;
  private BSCNetworkProvider: INetworkService | undefined;
  private polygonNetworkProvider: INetworkService | undefined;
  private avalancheNetworkProvider: INetworkService | undefined;
  private apexNetworkProvider: INetworkService | undefined;
  private arbitrumNetworkProvider: INetworkService | undefined;
  private zksyncNetworkProvider: INetworkService | undefined;
  private optimismNetworkProvider: INetworkService | undefined;
  private lineaNetworkProvider: INetworkService | undefined;
  private baseNetworkProvider: INetworkService | undefined;

  constructor(private http: HttpClient, private logger: LoggingService) {}
  public async getNetworkRPCUrlById(id: number): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/network/network/${id}`;
      return this.http.get(url).toPromise();
    } catch (err: any) {
      console.error(`Error message: ${err.message}`);
    }
    throw new Error('Unable to get Networks');
  }

  public getNetworkProvider(chainId) {
    switch (chainId.toString()) {
      case Networks.MAINNET.toString():
        return this.getEthereumNetworkProvider();
      case Networks.BSCMAIN.toString():
        return this.getBSCNetworkProvider();
      case Networks.POLYGON_MAINNET.toString():
        return this.getPolygonNetworkProvider();
      case Networks.AVALANCHE.toString():
        return this.getAvalancheNetworkProvider();
      case Networks.APEX.toString():
        return this.getApexNetworkProvider();
      case Networks.ARBITRUM.toString():
        return this.getArbitrumNetworkProvider();
      case Networks.ZKSYNC.toString():
        return this.getZksyncNetworkProvider();
      case Networks.OPTIMISM.toString():
        return this.getOptimismNetworkProvider();
      case Networks.LINEA.toString():
        return this.getLineaNetworkProvider();
      case Networks.BASE.toString():
        return this.getBaseNetworkProvider();
      default:
        throw new Error(`The given chainId ${chainId} is not supported`);
    }
  }

  public getEthereumNetworkProvider() {
    if (this.ethereumNetworkProvider) {
      return this.ethereumNetworkProvider;
    }
    return (this.ethereumNetworkProvider = new EthereumService(
      Networks.MAINNET,
      [environment.ETH_APIKEYS_MAINNET],
      [],
      this.logger
    ));
  }

  public getBSCNetworkProvider() {
    if (this.BSCNetworkProvider) {
      return this.BSCNetworkProvider;
    }
    return (this.BSCNetworkProvider = new BscService(
      Networks.BSCMAIN,
      [environment.BSCMAIN_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getPolygonNetworkProvider() {
    if (this.polygonNetworkProvider) {
      return this.polygonNetworkProvider;
    }
    return (this.polygonNetworkProvider = new PolygonService(
      Networks.POLYGON_MAINNET,
      [environment.POLYGON_MAINNET_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getAvalancheNetworkProvider() {
    if (this.avalancheNetworkProvider) {
      return this.avalancheNetworkProvider;
    }
    return (this.avalancheNetworkProvider = new AvalancheService(
      Networks.AVALANCHE,
      [environment.AVALANCHE_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getApexNetworkProvider() {
    if (this.apexNetworkProvider) {
      return this.apexNetworkProvider;
    }
    return (this.apexNetworkProvider = new ApexService(
      Networks.APEX,
      [environment.APEX_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getArbitrumNetworkProvider() {
    if (this.arbitrumNetworkProvider) {
      return this.arbitrumNetworkProvider;
    }
    return (this.arbitrumNetworkProvider = new ArbitrumService(
      Networks.ARBITRUM,
      [environment.ARBITRUM_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getZksyncNetworkProvider() {
    if (this.zksyncNetworkProvider) {
      return this.zksyncNetworkProvider;
    }
    return (this.zksyncNetworkProvider = new ZksyncService(
      Networks.ZKSYNC,
      [environment.ZKSYNC_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getOptimismNetworkProvider() {
    if (this.optimismNetworkProvider) {
      return this.optimismNetworkProvider;
    }
    return (this.optimismNetworkProvider = new OptimismService(
      Networks.OPTIMISM,
      [environment.OPTIMISM_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getLineaNetworkProvider() {
    if (this.lineaNetworkProvider) {
      return this.lineaNetworkProvider;
    }
    return (this.lineaNetworkProvider = new LineaService(
      Networks.LINEA,
      [environment.LINEA_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getBaseNetworkProvider() {
    if (this.baseNetworkProvider) {
      return this.baseNetworkProvider;
    }
    return (this.baseNetworkProvider = new BaseService(
      Networks.BASE,
      [environment.BASE_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
}
