var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AccountController, ModalController, NetworkController, AssetUtil, RouterController, CoreHelperUtil } from '@web3modal/core';
import { customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import { state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import styles from './styles.js';
import { ConstantsUtil } from '../../utils/ConstantsUtil.js';
import { W3mFrameRpcConstants } from '@web3modal/wallet';
let W3mAccountWalletFeaturesWidget = class W3mAccountWalletFeaturesWidget extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.address = AccountController.state.address;
    this.profileImage = AccountController.state.profileImage;
    this.profileName = AccountController.state.profileName;
    this.smartAccountDeployed = AccountController.state.smartAccountDeployed;
    this.network = NetworkController.state.caipNetwork;
    this.currentTab = AccountController.state.currentTab;
    this.tokenBalance = AccountController.state.tokenBalance;
    this.preferredAccountType = AccountController.state.preferredAccountType;
    this.unsubscribe.push(...[AccountController.subscribe(val => {
      if (val.address) {
        this.address = val.address;
        this.profileImage = val.profileImage;
        this.profileName = val.profileName;
        this.currentTab = val.currentTab;
        this.tokenBalance = val.tokenBalance;
        this.smartAccountDeployed = val.smartAccountDeployed;
        this.preferredAccountType = val.preferredAccountType;
      } else {
        ModalController.close();
      }
    })], NetworkController.subscribe(val => {
      this.network = val.caipNetwork;
    }));
    this.watchConvertValues();
  }
  disconnectedCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
    clearInterval(this.watchTokenBalance);
  }
  firstUpdated() {
    AccountController.fetchTokenBalance();
  }
  render() {
    if (!this.address) {
      throw new Error('w3m-account-view: No account provided');
    }
    const networkImage = AssetUtil.getNetworkImage(this.network);
    return html`<wui-flex
      flexDirection="column"
      .padding=${['0', 'xl', 'm', 'xl']}
      alignItems="center"
      gap="m"
    >
      ${this.activateAccountTemplate()}
      <wui-profile-button
        @click=${this.onProfileButtonClick.bind(this)}
        address=${ifDefined(this.address)}
        networkSrc=${ifDefined(networkImage)}
        icon="chevronBottom"
        avatarSrc=${ifDefined(this.profileImage ? this.profileImage : undefined)}
        ?isprofilename=${Boolean(this.profileName)}
      ></wui-profile-button>
      ${this.tokenBalanceTemplate()}
      <wui-flex gap="s">
        <wui-tooltip-select
          @click=${this.onBuyClick.bind(this)}
          text="Buy"
          icon="card"
        ></wui-tooltip-select>
        <wui-tooltip-select
          @click=${this.onConvertClick.bind(this)}
          text="Convert"
          icon="recycleHorizontal"
        ></wui-tooltip-select>
        <wui-tooltip-select
          @click=${this.onReceiveClick.bind(this)}
          text="Receive"
          icon="arrowBottomCircle"
        ></wui-tooltip-select>
        <wui-tooltip-select
          @click=${this.onSendClick.bind(this)}
          text="Send"
          icon="send"
        ></wui-tooltip-select>
      </wui-flex>

      <wui-tabs
        .onTabChange=${this.onTabChange.bind(this)}
        .activeTab=${this.currentTab}
        localTabWidth="104px"
        .tabs=${ConstantsUtil.ACCOUNT_TABS}
      ></wui-tabs>
      ${this.listContentTemplate()}
    </wui-flex>`;
  }
  watchConvertValues() {
    this.watchTokenBalance = setInterval(() => AccountController.fetchTokenBalance(), 10000);
  }
  listContentTemplate() {
    if (this.currentTab === 0) {
      return html`<w3m-account-tokens-widget></w3m-account-tokens-widget>`;
    }
    if (this.currentTab === 1) {
      return html`<w3m-account-nfts-widget></w3m-account-nfts-widget>`;
    }
    if (this.currentTab === 2) {
      return html`<w3m-account-activity-widget></w3m-account-activity-widget>`;
    }
    return html`<w3m-account-tokens-widget></w3m-account-tokens-widget>`;
  }
  tokenBalanceTemplate() {
    if (this.tokenBalance && this.tokenBalance?.length >= 0) {
      const value = CoreHelperUtil.calculateBalance(this.tokenBalance);
      const {
        dollars = '0',
        pennies = '00'
      } = CoreHelperUtil.formatTokenBalance(value);
      return html`<wui-balance dollars=${dollars} pennies=${pennies}></wui-balance>`;
    }
    return html`<wui-balance dollars="0" pennies="00"></wui-balance>`;
  }
  activateAccountTemplate() {
    const smartAccountEnabled = NetworkController.checkIfSmartAccountEnabled();
    if (!smartAccountEnabled || this.preferredAccountType !== W3mFrameRpcConstants.ACCOUNT_TYPES.EOA || this.smartAccountDeployed) {
      return null;
    }
    return html` <wui-promo
      text=${'Activate your account'}
      @click=${this.onUpdateToSmartAccount.bind(this)}
      data-testid="activate-smart-account-promo"
    ></wui-promo>`;
  }
  onTabChange(index) {
    AccountController.setCurrentTab(index);
  }
  onProfileButtonClick() {
    RouterController.push('AccountSettings');
  }
  onBuyClick() {
    RouterController.push('OnRampProviders');
  }
  onConvertClick() {
    RouterController.push('Convert');
  }
  onReceiveClick() {
    RouterController.push('WalletReceive');
  }
  onSendClick() {
    RouterController.push('WalletSend');
  }
  onUpdateToSmartAccount() {
    RouterController.push('UpgradeToSmartAccount');
  }
};
W3mAccountWalletFeaturesWidget.styles = styles;
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "watchTokenBalance", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "address", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "profileImage", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "profileName", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "smartAccountDeployed", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "network", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "currentTab", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "tokenBalance", void 0);
__decorate([state()], W3mAccountWalletFeaturesWidget.prototype, "preferredAccountType", void 0);
W3mAccountWalletFeaturesWidget = __decorate([customElement('w3m-account-wallet-features-widget')], W3mAccountWalletFeaturesWidget);
export { W3mAccountWalletFeaturesWidget };
