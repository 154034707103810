import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  CrowdToken,
  MainNetworksById,
  Networks,
  NetworksById,
} from '@crowdswap/constant';
import { filter, throttleTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { asyncScheduler } from 'rxjs';
import { CurrentNetwork } from '../../../model';
import {
  NDDClientInfoServiceImpl,
  PrivateSaleService,
  UtilsService,
  Web3Service,
} from '../../../services';
import { BaseComponent } from '../../pages/base.component';

export enum WalletState {
  WalletConnected,
  WalletNotConnected,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  currentChainId: number = 1;
  correctChainId: number = 1; //it uses in case of wrong/unsupported network
  networks = Networks;
  walletState: WalletState = WalletState.WalletNotConnected;
  isMismatchNetwork: boolean = false;

  constructor(
    public web3Service: Web3Service,
    private ref: ChangeDetectorRef,
    public privateSaleService: PrivateSaleService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, privateSaleService, clientInfoServiceImpl);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.web3Service.walletNetworkChangeSubject
      .pipe(
        throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(async (walletChainId: number) => {
        let fromTokenInUrl: CrowdToken | undefined;
        let toTokenInUrl: CrowdToken | undefined;

        if (!this.web3Service.isConnected()) {
          if (fromTokenInUrl && toTokenInUrl) {
            this.currentChainId = fromTokenInUrl.chainId;
            this.web3Service.currentNetworkChangeSubject.next(
              new CurrentNetwork(
                this.currentChainId,
                fromTokenInUrl,
                toTokenInUrl
              )
            );
          } else {
            this.web3Service.currentNetworkChangeSubject.next(
              new CurrentNetwork(this.currentChainId)
            );
            this.isMismatchNetwork = false;
            this.web3Service.mismatchNetworkSubject.next(
              this.isMismatchNetwork
            );
          }
          return;
        }

        this.isMismatchNetwork = false;
        UtilsService._clearQueryString();

        const isWrong_ =
          !environment.ACTIVE_NETWORK.includes(walletChainId.toString()) ||
          (environment.production && !(walletChainId in MainNetworksById)) ||
          (!environment.production && !(walletChainId in NetworksById)) ||
          !this.web3Service.getNetworkName(walletChainId);

        if (isWrong_) {
          this.correctChainId = this.currentChainId;
          this.incorrectNetworkMessage = 'Wrong network';

          this.isWrongNetwork = true;
          this.web3Service.wrongNetworkSubject.next(this.isWrongNetwork);
          return;
        } else {
          this.isWrongNetwork = false;
          this.web3Service.wrongNetworkSubject.next(this.isWrongNetwork);
        }

        this.currentChainId = walletChainId;
        if (
          !this.isWrongNetwork &&
          this.web3Service.getCurrentChainId() != walletChainId
        ) {
          this.web3Service.currentNetworkChangeSubject.next(
            new CurrentNetwork(walletChainId)
          );
        }
      });

    //The network of specified in the url (if exists) is different from the wallet network.
    this.web3Service.mismatchNetworkSubject.subscribe(
      (isMismatchNetwork: boolean) => {
        this.isMismatchNetwork = isMismatchNetwork;
        if (this.isMismatchNetwork) {
          this.incorrectNetworkMessage = 'Mismatch network';
        }
        this.ref.detectChanges();
      }
    );

    await this.onWalletConnectionChange(this.web3Service.isConnected());
    this.web3Service.walletConnectionChangeSubject.subscribe(
      async (connection) => {
        await this.onWalletConnectionChange(connection);
      }
    );
  }

  private async onWalletConnectionChange<T>(connection: T) {
    try {
      this.walletState = this.web3Service.isConnected()
        ? WalletState.WalletConnected
        : WalletState.WalletNotConnected;
      if (this.walletState == WalletState.WalletNotConnected) {
        this.isMismatchNetwork = false;
        this.web3Service.mismatchNetworkSubject.next(this.isMismatchNetwork);
      }

      this.ref.detectChanges();
    } catch (e) {
      console.error(e);
    }
  }
}
