<div class="app-container">
  <div class="app-section bg-FAFBFC xs:bg-fff dark:bg-070707 overflow-hidden">
    <div class="z-10 position-relative">
      <app-header></app-header>
    </div>

    <div class="mt-8">
      <div>
        <router-outlet></router-outlet>
      </div>

      <!--      <app-footer></app-footer>-->
    </div>
  </div>

  <div *ngIf="false" class="zerro-fee-section position-fixed right-0"></div>
</div>
