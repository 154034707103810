// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: 'v1.0.0',
  qa: true,
  apiBaseUrl: '',
  infura: '30133584cbf74667a3b221408d65b35f',
  walletConnectProjectId: '9608b5a3646c2bb6933303348abba577',
  WARNING_PRICE_IMPACT_PERCENT: -5,
  HIGH_PRICE_IMPACT_PERCENT: -10,

  ETH_APIKEYS_MAINNET: 'https://rpc.ankr.com/eth',
  BSCMAIN_PROVIDER_ADDRESS: 'https://bsc-dataseed.binance.org/',
  POLYGON_MAINNET_PROVIDER_ADDRESS: 'https://polygon-rpc.com/',
  AVALANCHE_PROVIDER_ADDRESS: 'https://rpc.ankr.com/avalanche',
  APEX_PROVIDER_ADDRESS: 'https://rpc.theapexchain.org',
  ARBITRUM_PROVIDER_ADDRESS: 'https://rpc.ankr.com/arbitrum',
  ZKSYNC_PROVIDER_ADDRESS: 'https://mainnet.era.zksync.io',
  OPTIMISM_PROVIDER_ADDRESS: 'https://mainnet.optimism.io',
  LINEA_PROVIDER_ADDRESS: 'https://linea-mainnet.public.blastapi.io',
  BASE_PROVIDER_ADDRESS: 'https://mainnet.base.org',

  ACTIVE_NETWORK: ['1', '56', '137', '43114', '42161', '324', '10'],
  API_KEY: '8f2fb789-f240-4ae4-a9d4-fe386f16cc3e',
  PriceUrl: 'https://test-api.crowdswap.org/price',
  PairInfoUrl: 'https://info.crowdswap.org/coingecko',
  NETWORK_SERVICE_BASEURL: 'https://test-api.crowdswap.org/network',
  Opportunity_BASEURL: 'https://test-api.crowdswap.org/opportunity',
  SWAP_BASEURL: 'https://test-api.crowdswap.org/swap',
  BaseLogoIconsUrl: 'https://asset.crowdswap.org/',
};
